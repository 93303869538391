import React from "react";
import AppRoutes from "./AppRoutes";
import 'antd/dist/antd.css';
import './App.css';
import './index.css';

const App = () => {
  return <AppRoutes />;
};

export default App;
