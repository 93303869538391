import React from "react";

function Icon({ station, temperatura, time,weatherTypePT }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            viewBox="0 0 349 134"
        >
            <defs>
                <filter
                    id="gfroujoepa"
                    width="222.5%"
                    height="322.7%"
                    x="-61.2%"
                    y="-106.8%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="dt3v0k4t1c"
                    width="140%"
                    height="304.2%"
                    x="-20%"
                    y="-97.9%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="crc2qr9nde"
                    width="162.8%"
                    height="202.1%"
                    x="-31.4%"
                    y="-49%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="62c0cqsu0g"
                    width="140.6%"
                    height="322.7%"
                    x="-20.3%"
                    y="-106.8%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <text
                    id="xvc6lf19nb"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="18"
                    fontWeight="bold"
                >
                    <tspan x="286" y="104">
                        {time}
                    </tspan>
                </text>
                <text
                    id="mq3lvp552d"
                    fill="#FFF"
                    fontFamily="JosefinSans-Light, Josefin Sans"
                    fontSize="20"
                    fontWeight="300"
                >
                    <tspan x="11" y="71">
                    {weatherTypePT}
                    </tspan>
                </text>
                <text
                    id="hp3bk7fi1f"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="40"
                    fontWeight="bold"
                >
                    <tspan x="11" y="45">
                        {temperatura}
                    </tspan>
                </text>
                <text
                    id="yewcgk05sh"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="18"
                    fontWeight="bold"
                >
                    <tspan x="9.882" y="104">
                        {station}
                    </tspan>
                </text>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(7 8)">
                    <path
                        fill="#2F80ED"
                        d="M20.511 0H314.49c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C334.257 10.793 335 13.38 335 20.511v73.846c0 7.132-.743 9.718-2.137 12.325a14.538 14.538 0 01-6.048 6.049c-2.608 1.394-5.194 2.137-12.326 2.137H20.51c-7.132 0-9.718-.743-12.326-2.137a14.538 14.538 0 01-6.048-6.049C.743 104.075 0 101.49 0 94.357V20.51c0-7.132.743-9.718 2.137-12.326a14.538 14.538 0 016.048-6.048C10.793.743 13.38 0 20.511 0z"
                    ></path>
                    <path
                        fill="#FFF"
                        d="M314.489 0c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C334.257 10.793 335 13.38 335 20.511V33H137.997C124.192 33 113 21.809 113 7.993V0h201.489z"
                    ></path>
                    <path
                        fill="#56CCF2"
                        d="M317.577 80.022c.937 0 1.844.052 2.72.153H335v17.27c0 3.562-.749 6.685-2.142 9.266a14.674 14.674 0 01-6.014 6.014c-2.582 1.394-5.705 2.143-9.267 2.143H17.423c-3.562 0-6.685-.749-9.267-2.143a14.674 14.674 0 01-6.014-6.014C.75 104.131 0 101.007 0 97.445l.004-.424H0V80.177h14.704a23.524 23.524 0 012.719-.154h300.154z"
                    ></path>
                    <path
                        fill="#D0F4FC"
                        d="M322.87 13c4.56 0 8.766 1.526 12.131 4.095v31.809A19.923 19.923 0 01322.869 53H180.006C168.957 53 160 44.048 160 33c0-11.046 8.96-20 20.006-20H322.87z"
                    ></path>
                    <path
                        fill="#FFF"
                        d="M323.006 33c4.5 0 8.653 1.487 11.994 3.996V69a19.9 19.9 0 01-11.994 4h-97.012C214.952 73 206 64.048 206 53c0-11.046 8.963-20 19.994-20h97.012z"
                    ></path>
                    <circle cx="279" cy="37" r="30" fill="#FCE765"></circle>
                </g>
                <g fill="#FFF" transform="translate(7 8)">
                    <use filter="url(#gfroujoepa)" xlinkHref="#xvc6lf19nb"></use>
                    <use xlinkHref="#xvc6lf19nb"></use>
                </g>
                <g fill="#FFF" transform="translate(7 8)">
                    <use filter="url(#dt3v0k4t1c)" xlinkHref="#mq3lvp552d"></use>
                    <use xlinkHref="#mq3lvp552d"></use>
                </g>
                <g fill="#FFF" transform="translate(7 8)">
                    <use filter="url(#crc2qr9nde)" xlinkHref="#hp3bk7fi1f"></use>
                    <use xlinkHref="#hp3bk7fi1f"></use>
                </g>
                <g fill="#FFF" transform="translate(7 8)">
                    <use filter="url(#62c0cqsu0g)" xlinkHref="#yewcgk05sh"></use>
                    <use xlinkHref="#yewcgk05sh"></use>
                </g>
            </g>
        </svg>
    );
}

export default Icon;
