import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React, { useContext } from "react";
import { AuthContext } from "../../../context/auth";
import "./style.css";
import pinIcon from "../../../assets/icons/pin.svg";

const _Map = ({ google }) => {

    const { environment } = useContext(AuthContext);

    const _center = { lat: 38.660020319406584, lng: -9.07930074276946 }

    return (
        <>
            <span>
                <img src={pinIcon} style={{ width: "1.5em" }} />
                {environment?.name}
            </span>
            <div className='occurrences_map'>
                <Map
                    initialCenter={_center}
                    center={_center}
                    google={google}
                    zoom={13}
                    disableDefaultUI={true}
                    className="occurrences_map"
                    style={{ borderRadius: "16px" }}
                    fullscreenControl={false}
                    zoomControl={false}
                    streetViewControl={false}
                    mapTypeControl={false}>
                    <Marker position={_center} name={'Barreiro, Portugal'} />
                </Map>
            </div>
        </>
    )
}

export default GoogleApiWrapper({
    apiKey: (`${process.env.REACT_APP_GOOGLE_MAPS_TOKEN}`)
})(_Map)