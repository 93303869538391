import React from "react";

function Icon({station, temperatura, time,weatherTypePT}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            viewBox="0 0 349 135"
        >
            <defs>
                <filter
                    id="gelnec71wa"
                    width="222.5%"
                    height="322.7%"
                    x="-61.2%"
                    y="-106.8%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="9qlrj0kwxc"
                    width="128.8%"
                    height="304.2%"
                    x="-14.4%"
                    y="-97.9%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="r76fmg2dye"
                    width="162.8%"
                    height="202.1%"
                    x="-31.4%"
                    y="-49%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="7axnh2sxrg"
                    width="140.6%"
                    height="322.7%"
                    x="-20.3%"
                    y="-106.8%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <text
                    id="0gp5n7v4ab"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="18"
                    fontWeight="bold"
                >
                    <tspan x="286" y="104">
                        {time}
                    </tspan>
                </text>
                <text
                    id="4vqg5kzf8d"
                    fill="#FFF"
                    fontFamily="JosefinSans-Light, Josefin Sans"
                    fontSize="20"
                    fontWeight="300"
                >
                    <tspan x="10" y="69">
                    {weatherTypePT}
                    </tspan>
                </text>
                <text
                    id="d9w2w88eof"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="40"
                    fontWeight="bold"
                >
                    <tspan x="10" y="44">
                        {temperatura}
                    </tspan>
                </text>
                <text
                    id="lc1690lyxh"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="18"
                    fontWeight="bold"
                >
                    <tspan x="9.882" y="104">
                        {station}
                    </tspan>
                </text>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#083A5E"
                    d="M27.511 9H321.49c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 19.793 342 22.38 342 29.511v73.846c0 7.132-.743 9.718-2.137 12.325a14.538 14.538 0 01-6.048 6.049c-2.608 1.394-5.194 2.137-12.326 2.137H27.51c-7.132 0-9.718-.743-12.326-2.137a14.538 14.538 0 01-6.048-6.049C7.743 113.075 7 110.49 7 103.357V29.51c0-7.132.743-9.718 2.137-12.326a14.538 14.538 0 016.048-6.048C17.793 9.743 20.38 9 27.511 9z"
                ></path>
                <path
                    fill="#0F506C"
                    d="M321.489 9c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 19.793 342 22.38 342 29.511V42H144.997C131.192 42 120 30.809 120 16.993V9h201.489z"
                ></path>
                <path
                    fill="#EBD790"
                    d="M324.577 89.022c.937 0 1.844.052 2.72.153H342v17.27c0 3.562-.749 6.685-2.142 9.266a14.674 14.674 0 01-6.014 6.014c-2.582 1.394-5.705 2.143-9.267 2.143H24.423c-3.562 0-6.685-.749-9.267-2.143a14.674 14.674 0 01-6.014-6.014C7.75 113.131 7 110.007 7 106.445l.004-.424H7V89.177h14.704a23.524 23.524 0 012.719-.154h300.154z"
                ></path>
                <path
                    fill="#146C7E"
                    d="M327.871 22a19.945 19.945 0 0114.13 5.841V56.16A19.943 19.943 0 01327.87 62H187.005C175.956 62 167 53.048 167 42c0-11.046 8.954-20 20.005-20H327.87z"
                ></path>
                <path
                    fill="#0F506C"
                    d="M330.006 42c4.5 0 8.653 1.487 11.994 3.996V78a19.9 19.9 0 01-11.994 4h-97.012C221.952 82 213 73.048 213 62c0-11.046 8.963-20 19.994-20h97.012z"
                ></path>
                <path
                    fill="#EBD790"
                    d="M297.51 18C308.412 22.927 316 33.885 316 46.611 316 63.947 301.924 78 284.56 78c-12.664 0-23.58-7.476-28.56-18.25a28.484 28.484 0 0011.094 2.248c17.364 0 31.44-15.707 31.44-35.082 0-2.906-.317-5.73-.914-8.43z"
                ></path>
                <g fill="#FFF" transform="translate(7 9)">
                    <use filter="url(#gelnec71wa)" xlinkHref="#0gp5n7v4ab"></use>
                    <use xlinkHref="#0gp5n7v4ab"></use>
                </g>
                <g fill="#FFF" transform="translate(7 9)">
                    <use filter="url(#9qlrj0kwxc)" xlinkHref="#4vqg5kzf8d"></use>
                    <use xlinkHref="#4vqg5kzf8d"></use>
                </g>
                <g fill="#FFF" transform="translate(7 9)">
                    <use filter="url(#r76fmg2dye)" xlinkHref="#d9w2w88eof"></use>
                    <use xlinkHref="#d9w2w88eof"></use>
                </g>
                <g fill="#FFF" transform="translate(7 9)">
                    <use filter="url(#7axnh2sxrg)" xlinkHref="#lc1690lyxh"></use>
                    <use xlinkHref="#lc1690lyxh"></use>
                </g>
            </g>
        </svg>
    );
}

export default Icon;
