import { Descriptions, Divider } from "antd"

const DetailsStations = ({ data }) => {
    return (
        <>
            <Descriptions size="small" title="Estação" style={{ maxWidth: '250px' }}>
                <Descriptions.Item key="ID" span={3} label="ID">{data.id}</Descriptions.Item>
                <Descriptions.Item key="Nome" span={3} label="Nome">{data.name}</Descriptions.Item>
                <Descriptions.Item key="Modelo" span={3} label="Modelo">{data.model}</Descriptions.Item>
                <Descriptions.Item key="Cidade" span={3} label="Cidade">{data.city}</Descriptions.Item>
            </Descriptions>

            {data.connectors.map(i => <>
                <Divider></Divider>
                <Descriptions size="small" title={"Conector " + i.connectorid} style={{ maxWidth: '250px' }}>
                    <Descriptions.Item key={i.maxpower} span={3} label="Potência máxima">{i.maxpower/1000 + " kW"}</Descriptions.Item>
                    <Descriptions.Item key={i.status} span={3} label="Estado">{i.status}</Descriptions.Item>
                </Descriptions>
            </>)}

        </>
    )
}
export default DetailsStations