import { Col, Row, Tabs } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import Loading from "../../../components/loading";
import WidgetError from "../../../components/error";
import LoadingTime from "../widget/loadingTime";
import SuppliedEnergy from "../widget/suppliedEnergy";
import TotalLoads from "../widget/totalLoads";
import "./style.css";

const TaxRate = ({ dateStart, dateEnd }) => {
    const { TabPane } = Tabs;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [series, setSeries] = useState([]);
    const [seriesAll, setSeriesAll] = useState([]);


    const fetchSessions = () => {
        setLoading(true);
        setError(undefined);
        api
            .post("/chargers/sessions/find/date", {
                dateStart: dateStart ? moment(dateStart).utc() : undefined,
                dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
                rowPerPage: 1000,
            })
            .then((response) => {
                setSeriesAll(response.data.content)
                console.log(response.data.content)
            })
            .catch((error) => {
                setError(error?.response?.data?.message || "unknown error");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchSeries = () => {
        setLoading(true);
        setError(undefined);
        api
            .post("/chargers/sessions/group/type", {
                dateStart: dateStart ? moment(dateStart).utc() : undefined,
                dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
            })
            .then((response) => {
                let { data } = response;
                setSeries(data);
            })
            .catch((error) => {
                setError(error?.response?.data?.message || "unknown error");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchSeries()
        fetchSessions()
    }, [dateStart, dateEnd])

    function milisecondToTime(milissegundo) {
        if (milissegundo !== undefined && milissegundo != null) {
            milissegundo = milissegundo * 60000;
            var ms = milissegundo % 1000;
            milissegundo = (milissegundo - ms) / 1000;
            var secs = milissegundo % 60;
            milissegundo = (milissegundo - secs) / 60;
            var mins = milissegundo % 60;
            var hrs = (milissegundo - mins) / 60;
            return hrs + ' h  ' + mins + 'm'
        }
        return 0;
    }

    function contarIDsDistintos(listaDeSecoes) {
        const idsDistintos = new Set();

        listaDeSecoes.forEach((secao) => {
            secao.sessionIds.forEach((id) => {
                idsDistintos.add(id);
            });
        });

        return idsDistintos.size;
    }

    const handleValues = (typeHour) => {
        if (series != null && series.length > 0) {
            try {
                switch (typeHour) {
                    case "pointHour":
                        return series.find(s => s.type === "TIP");
                    case "fullHours":
                        return series.find(s => s.type === "FULL");
                    case "emptyHours":
                        return series.find(s => s.type === "EMPTY");
                    case "all":
                        return {
                            energy: seriesAll.map((s) => s.chargedenergy).reduce((ac, at) => ac + at, 0),
                            minute: seriesAll.map((s) => s.duration).reduce((ac, at) => ac + at, 0),
                            session: contarIDsDistintos(series),
                            type: "ALL"
                        }
                    default:
                        return null;
                }
            }
            catch (e) {
                return { minute: 0, session: 0, energy: 0 }
            }
        }
        return { minute: 0, session: 0, energy: 0 }
    }

    const generateWidgets = (typeHour) => {
        let value = handleValues(typeHour)
        return <div style={{ marginTop: "2em" }}>
            <Row gutter={12}>
                <Col xs={24} md={12} lg={8}>
                    <SuppliedEnergy value={value?.energy} />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <LoadingTime value={typeHour === 'all' ? milisecondToTime(value?.minute > 0 ? value?.minute / 60000 : 0) : milisecondToTime(value?.minute)} />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <TotalLoads value={value?.session} />
                </Col>
            </Row>
        </div>;
    }

    return (
        <div className="eletric_mobility_tax">
            <span>
                Ciclo diário tarifa tri-horária
            </span>
            {loading ? (
                <Loading />
            ) : error ? (
                <WidgetError
                    message={`${error}`}
                    refresh={() => fetchSeries()}
                />
            ) : <div className="eletric_mobility_tax_content">
                <Tabs defaultActiveKey="1" tabPosition="left">
                    <TabPane tab="Todos" key="1">
                        {generateWidgets('all')}
                    </TabPane>
                    <TabPane tab="Hora de Ponta" key="2">
                        {generateWidgets('pointHour')}
                    </TabPane>
                    <TabPane tab="Horas Cheias" key="3">
                        {generateWidgets('fullHours')}
                    </TabPane>
                    <TabPane tab="Horas de Vazio" key="4">
                        {generateWidgets('emptyHours')}
                    </TabPane>
                </Tabs>
            </div>}
        </div>
    );
}

export default TaxRate;