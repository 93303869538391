import { Button, Col, Form, Input } from "antd";
import { useState } from "react";
import api from "../../services/api";

const UpdatePassword = ({ user }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState();

  const handleOnFinish = () => {
    setMsg(null);
    api
      .patch(`/oauth/users/alterpassword${"/" + user?.id}`, null, {
        params: {
          newPassword: form.getFieldValue("password"),
        },
      })
      .then((response) => {
        let _msg = {
          color: "green",
          text: "Senha alterada com sucesso!",
        };
        setMsg(_msg);
        form.resetFields();
      })
      .catch((error) => {
        let _msg = {
          color: "red",
          text: error?.response?.data?.message || "unknown error",
        };
        setMsg(_msg);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Form
        name="updatePassword"
        onFinish={handleOnFinish}
        form={form}
        layout="vertical"
        initialValues={{ remember: false }}
        autoComplete="off"
      >
        <Col xs={24}>
          <Form.Item
            label="palavra-passe"
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: "O campo Senha é obrigatório!",
                whitespace: false,
              },
            ]}
          >
            <Input.Password placeholder="Digite aqui a senha" />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            label="Confirmação de palavra-passe"
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "O campo 'Confirmação de senha' é obrigatório!",
                whitespace: false,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value)
                    return Promise.resolve();
                  return Promise.reject(
                    "Senha e senha de confirmação não confere"
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirmação de senha" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              Enviar
            </Button>
            {msg && (
              <span style={{ color: msg?.color, marginLeft: 20 }}>
                {msg?.text}
              </span>
            )}
          </Form.Item>
        </Col>
      </Form>
    </>
  );
};

export default UpdatePassword;
