import { Col, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import syncIcon from "../../../assets/icons/sync.svg";
import ErrorWidget from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import "./style.css";

const Table = ({ dateStart, dateEnd }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [rowPerPage, setRowPerPage] = useState(5);
  const [pagination, setPagination] = useState({});

  const getToken = () => {
    let credentials = JSON.parse(localStorage.getItem(`credentials-${process.env.PUBLIC_URL}`));
    return `${credentials.token_type} ${credentials.access_token}`;
  };

  const refreshTickets = () => {
    setLoading(true);
    setError(undefined);
    api
      .post("/helpdesk/ticket/search", {
        startTo: dateStart ? moment(dateStart).utc() : undefined,
        endTo: dateEnd ? moment(dateEnd).utc() : undefined,
        rowPerPage: rowPerPage,
      })
      .then((response) => {
        let { data } = response;
        setPagination(data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(refreshTickets, [dateStart, dateEnd, rowPerPage]);

  return (
    <Row style={{ marginTop: "1em" }}>
      <Col xs={24}>
        <span>Ocorrências</span>
      </Col>
      {error && <ErrorWidget message={error} refresh={refreshTickets} />}
      {!error && (
        <div className="ticket_content" style={{ overflowX: "auto" }}>
          <table>
            <tr>
              <th>Estado</th>
              <th>Ocorrência</th>
              <th>Hora</th>
              <th>Categoria</th>
              <th>Tempo de resolução</th>
            </tr>
            {!loading && pagination?.empty && (
              <tr>
                <td colSpan={5}>Sem dados</td>
              </tr>
            )}
            {pagination?.content?.map((element) => (
              <tr>
                <td>
                  <div
                    title={element?.status?.description}
                    class="circle"
                    style={{ backgroundColor: element.status.color }}
                  ></div>
                </td>
                <td>
                  {element?.category?.icon ? (
                    <img
                      title={element?.category?.name}
                      alt=""
                      src={`${process.env.REACT_APP_API_URL}/storage/render/${element?.category?.icon?.id}`}
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {moment
                    .utc(element.creationDate)
                    .local()
                    .format("D/M/Y HH:mm")}
                </td>
                <td>{element?.category?.name || "-"}</td>
                <td>{element?.timeResolution?.description || "-"}</td>
              </tr>
            ))}
            {loading && (
              <tr>
                <td colSpan={5}>
                  <Loading />
                </td>
              </tr>
            )}
          </table>
          {!loading && !pagination?.last ? (
            <div
              class="btn-carregarmais"
              style={{ cursor: "pointer", marginTop: "1em" }}
              onClick={() =>
                setRowPerPage(parseInt(rowPerPage + rowPerPage * 0.5))
              }
            >
              <img src={syncIcon}></img>
              <br />
              <span>Carregar mais</span>
            </div>
          ) : (
            <br />
          )}
        </div>
      )}
    </Row>
  );
};

export default Table;
