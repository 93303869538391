import { Button, Result } from "antd";
import React from "react";
import "./style.css";

const Error = (props) => {

    let { message, refresh } = props;

    return (
        <Result
            {...props}
            className="error_content"
            status='error'
            title="Atualmente indisponível"
            subTitle={message}
            extra={refresh ? <Button type="primary" onClick={refresh}>Tentar novamente</Button> : undefined}
        />
    );
}

export default Error;