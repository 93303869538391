import { Input } from 'antd'
import { GoogleApiWrapper, Map, Polygon } from 'google-maps-react'
import React, { useEffect, useState } from "react"
import Loading from "../../../components/loading"
import api from '../../../services/api'
import "../style.css"

const Maps = ({ google, localSelected, handleSearch, section, setSection, setPolygon }) => {

    const [loading, setLoading] = useState(false)
    const [loadingSearch, setLoadingSearch] = useState(false)
    const [center, setCenter] = useState()
    const [shape, setShape] = useState([])
    const [shapeSub, setShapeSub] = useState([])
    const [zoom, setZoom] = useState(13)
    const [lastPolygon, setLastPolygon] = useState()

    const onPolygonClick = async (polygonProps, polygon, event) => {
        const clickLocation = event.latLng
        const isWithinPolygon = window.google.maps.geometry.poly.containsLocation(
            clickLocation,
            polygon
        )
        if (isWithinPolygon) {
            if (lastPolygon) {
                let _polygon = lastPolygon
                _polygon.setOptions({
                    strokeColor: '#A9A9A9',
                    strokeOpacity: 0.4,
                    strokeWeight: 1,
                    fillOpacity: 0
                })
                setLastPolygon(_polygon)
                setLastPolygon(polygon)
            }
            else
                setLastPolygon(polygon)
            polygon.setOptions({
                strokeColor: '#6BACFF',
                strokeOpacity: 1,
                strokeWeight: 3,
                fillOpacity: 0
            })
            let { data } = await api.get(`/geoservices/features/coordenates`, { params: { lat: clickLocation.lat(), lng: clickLocation.lng() } })
            if (data.length > 0) {
                setPolygon({ bgri: data[0].bgri })
                setSection(data[0].section + " - " + data[0].bgri)
                setShapeSub([])
            }
        }
    }

    const fecthLocations = async () => {
        try {
            setLoading(true)
            let { data } = await api.get(`/geoservices/features/polygons`, {
                params: {
                    local: localSelected.local ? localSelected.local : undefined,
                    bgri: localSelected.bgri ? localSelected.bgri : undefined,
                    search: localSelected.search ? localSelected.search : undefined,
                }
            })
            if (localSelected && (localSelected.bgri || localSelected.search))
                setShapeSub(data || [])
            else {
                setShape(data || [])
                setShapeSub([])
            }
            if (data && data[0].length > 0) {
                setCenter({
                    lat: parseFloat(data[0][0].lat),
                    lng: parseFloat(data[0][0].lng)
                })
                if (localSelected && localSelected.bgri)
                    setZoom(16)
                else
                    setZoom(13)
            }

        } finally {
            setLoading(false)
        }
    }

    const onSearch = async (event) => {
        handleSearch(event)
    }

    const style = {
        width: '99%',
        maxHeight: '35em',
        borderRadius: "13px",
        backgroundColor: "#fff",
        marginBottom:"20px"
    }

    useEffect(() => {
        if (localSelected && (localSelected.local || localSelected.search))
            fecthLocations()
    }, [localSelected])


    return (
        <>
            <span >
                Mapa geral
            </span>
            <div className="map-content">
                {
                    loading ?
                        <Loading size={"20em"} /> :
                        <>
                            <div className="search-maps">
                                {section &&
                                    <div className='sectionName'>
                                        <span style={{fontSize:"1.2em"}} >{section}</span>
                                    </div>}
                                <div style={{ marginTop: "5px" }}>
                                    <Input loading={loadingSearch} placeholder="Pesquisar por cidade" onPressEnter={onSearch} />
                                </div>
                            </div>
                            <Map
                                loaded={loading}
                                google={google}
                                zoom={zoom}
                                initialCenter={center}
                                center={center}
                                style={style}
                                streetViewControl={false}
                                fullscreenControl={false}
                                zoomControl={false}
                            >

                                {(shape || []).map((element) =>
                                    <Polygon
                                        onClick={onPolygonClick}
                                        path={element}
                                        options={{
                                            strokeColor: '#A9A9A9',
                                            strokeOpacity: 0.4,
                                            strokeWeight: 1,
                                            fillOpacity: 0
                                        }}
                                    >
                                    </Polygon>)}
                                {(shapeSub || []).map((element) =>
                                    <Polygon
                                        onClick={onPolygonClick}
                                        path={element}
                                        options={{
                                            strokeColor: '#6BACFF',
                                            strokeOpacity: 1,
                                            strokeWeight: 3,
                                            fillOpacity: 0
                                        }}
                                    >
                                    </Polygon>)}
                            </Map>
                        </>
                }
            </div>
        </>
    )
}

export default GoogleApiWrapper((props) => ({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_TOKEN,
}
))(Maps)