import { Col, Row } from "antd";
import React from "react";
import Loading from "../../../../../components/loading";
import "./style.css";

const Widget = ({ icon, title, value, loading, error, reload }) => {
  return (
    <div className="hydrogenWidgetContent">
      <Row>
        <Col xs={24} md={2} className="icon">
          <img
            src={require(`../../../../../assets/icons/hydrogen/${icon}`)}
            alt=""
          />
        </Col>
        <Col xs={24} md={10} className="title">
          {title}
        </Col>
        <Col xs={24} md={10} className="value">
          {loading ? (
            <Loading size="0em" direction="right" />
          ) : error ? (
            <span
              style={{ color: "red", fontWeight: "normal", cursor: 'pointer' }}
              title={error}
              onClick={reload}
            >
              {`error: ${error.substring(0, 15)}`}{" "}
            </span>
          ) : (
            value
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Widget;
