import { Col, Row } from "antd";
import React from "react";
import Widget from "./widget";
import "./style.css";

const EnergyConsumption = () => {

    return (
        <>
            <span>
                Consumo de energia
            </span>
            <div className="buildingsEnergyConsumptionsContent">
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={6}>
                        <Widget title="Energia global do edifício" value="4 kWh" />
                    </Col>
                    <Col xs={24} md={6}>
                        <Widget title="Custo de energia global do edifício" value="2,5€" />
                    </Col>
                    <Col xs={24} md={6}>
                        <Widget title="Energia do Piso 3" value="5 kWh" />
                    </Col>
                    <Col xs={24} md={6}>
                        <Widget title="Custo de energia Piso 3" value="4,5€" />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default EnergyConsumption;