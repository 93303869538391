import React from "react";
import "./style.css";

const TotalLoads = ({value}) => {

    return (
        <div className="eletricMobilityWidget totalLoads">
            <h2>{value>0?value:0}</h2>
            <span>
                Carregamentos no total
            </span>
        </div>
    );
}

export default TotalLoads;