import { Col, Row } from "antd";
import { useContext } from "react";
import Menu from "../../../components/menu";
import { MenuContext } from "../../../context/menu";
import Info from "./info";
import Map from "./map";

const SolarDashboard = () => {

    const { data } = useContext(MenuContext);

    return (
        <>
            <Row style={{ marginBottom: 16 }} gutter={16}>
                <Col xs={24} lg={12}>
                    <Map title="Localização dos inversores e bateria" />
                </Col>
                <Col xs={24} lg={12}>
                    <Info />
                </Col>
            </Row>
        </>
    );
}

export default SolarDashboard;