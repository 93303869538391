import { Col, Row } from "antd";
import { MenuContext } from "../../context/menu.js";
import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import Menu from "../../components/menu/index.js";
import { AuthContext } from "../../context/auth";
import api from "../../services/api";
import Graphic from "./graphic";
import Map from "./map";
import Table from "./table";
import Legend from "./table/legend";
import Widget from "./widget";

const Occurrences = () => {

    const { data } = useContext(MenuContext);
    const [dateStart, setDateStart] = useState(data.start);
    const [dateEnd, setDateEnd] = useState(data.stop);
    const { environment } = useContext(AuthContext);
    const [resolutionTickets, setResolutionTickets] = useState(0);
    const [closedTickets, setClosedTickets] = useState(0);
    const [openTickets, setOpenTickets] = useState(0);
    const [avgDuration, setAvgDuration] = useState();

    const onClickedMenu = (data) => {
        setDateStart(data.start);
        setDateEnd(data.end);
    }

    const countOpenTickets = () => {
        api.get("/helpdesk/ticket/count/status/_0_registered,_1_overdue,_2_forwarded,_3_in_progress,_4_under_review", {
            params: {
                dateStart: dateStart ? moment(dateStart).utc().toISOString() : undefined,
                dateEnd: dateEnd ? moment(dateEnd).utc().toISOString() : undefined,
            }
        }).then((response) => {
            setOpenTickets(response.data);
        })
    }

    const countResolutionTickets = () => {
        api.get("/helpdesk/ticket/count/status/_0_in_triage", {
            params: {
                dateStart: dateStart ? moment(dateStart).utc().toISOString() : undefined,
                dateEnd: dateEnd ? moment(dateEnd).utc().toISOString() : undefined,
            }
        }).then((response) => {
            setResolutionTickets(response.data);
        })
    }

    const countClosedTickets = () => {
        api.get("/helpdesk/ticket/count/status/_5_complete,_6_canceled", {
            params: {
                dateStart: dateStart ? moment(dateStart).utc().toISOString() : undefined,
                dateEnd: dateEnd ? moment(dateEnd).utc().toISOString() : undefined,
            }
        }).then((response) => {
            setClosedTickets(response.data);
        });
    }

    const calcAvgDuration = () => {
        api.get("/helpdesk/ticket/calc/duration/avg", {
            params: {
                dateStart: dateStart ? moment(dateStart).utc().toISOString() : undefined,
                dateEnd: dateEnd ? moment(dateEnd).utc().toISOString() : undefined,
            }
        }).then((response) => {
            setAvgDuration(response.data);
        })
    }

    useEffect(() => {
        countResolutionTickets();
        countClosedTickets();
        countOpenTickets();
        calcAvgDuration();
    }, [dateStart, dateEnd]);

    return (
        <>
            <Row gutter={16} style={{ marginBottom: 16 }}>
                <Col xs={24}>
                    <Menu onClicked={onClickedMenu} />
                </Col>
                <Col xs={24} md={24} lg={10}>
                    <Map
                        environment={environment}
                        dateStart={dateStart}
                        dateEnd={dateEnd} />
                    <Row gutter={16}>
                        <Col xs={24} md={6}>
                            <Widget value={openTickets} title="Ocorrências em aberto" />
                        </Col>
                        <Col xs={24} md={6}>
                            <Widget value={closedTickets} title="Ocorrências fechadas" />
                        </Col>
                        <Col xs={24} md={6}>
                            <Widget value={resolutionTickets} title="Ocorrências em resolução" />
                        </Col>
                        <Col xs={24} md={6}>
                            <Widget h2Props={{ style: { fontSize: "100%" } }} value={avgDuration?.duration?.description || '-'} title="Tempo médio de resolução" />
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} lg={14}>
                    <Graphic dateStart={dateStart} dateEnd={dateEnd} />
                    <Table dateStart={dateStart} dateEnd={dateEnd} />
                    <Legend />
                </Col>
            </Row>
        </>
    );
}

export default Occurrences;