import { Col, Row } from "antd";
import React from "react";
import "./style.css";

const Widget = ({ icon, title, value }) => {


    return (
        <div className="buildingsWaterConsumptionWidget">
            <Row>
                <Col xs={24} md={3} className="icon">
                    <img src={require(`../../../../../assets/icons/buildings/${icon}`)} />
                </Col>
                <Col xs={24} md={16} className="title">
                    {title}
                </Col>
                <Col xs={24} md={5} className="value">
                    {value}
                </Col>
            </Row>
        </div>
    );
}

export default Widget;