import { Col, Row } from "antd";
import React from "react";
import Menu from "../../../components/menu";
import EnergyConsumption from "./energyConsumption";
import ThermalConsumption from "./thermalConsumption";
import WaterConsumption from "./waterConsumption";
import Widget from "./widget";

const Buildings = () => {
    return (
        <>
            <Menu />
            <Row gutter={16}>
                <Col xs={24} lg={12}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <Widget
                                title={"Temperatura Lado Norte"}
                                icon={"temperatura_norte.svg"}
                                internal={"23º"}
                                outside={"8º"} />
                        </Col>
                        <Col xs={24} md={12}>
                            <Widget
                                title={"Temperatura Lado Sul"}
                                icon={"temperatura_sul.svg"}
                                internal={"22º"}
                                outside={"8º"} />
                        </Col>
                        <Col xs={24} md={12}>
                            <Widget
                                title={"Humidade Lado Norte"}
                                icon={"humidade_norte.svg"}
                                internal={"2%"}
                                outside={"8%"} />
                        </Col>
                        <Col xs={24} md={12}>
                            <Widget
                                title={"Humidade Lado Sul"}
                                icon={"humidade_sul.svg"}
                                internal={"2%"}
                                outside={"7%"} />
                        </Col>
                        <Col xs={24}>
                            <EnergyConsumption />
                        </Col>
                        <Col xs={24}>
                            <ThermalConsumption />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} lg={12}>
                    <WaterConsumption />
                </Col>
            </Row>
        </>
    );
}

export default Buildings;