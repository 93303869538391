import { Space } from "antd";
import React, { useState } from "react";
import "./style.css";

const Menu = ({ values = [], onClick = (item) => { console.log(`clicked ${item}`) } }) => {

  const [valueSelected, setValueSelected] = useState(values[0]);

  return (
    <>
      <div id="menuShopping" style={{ textAlign: "right" }}>
        <Space align="start" direction="horizontal" size={12} wrap>
          {values.map((ele) =>
            <a
              className={valueSelected === ele ? "selected" : ""}
              onClick={() => {
                onClick(ele);
                setValueSelected(ele);
              }}
            >
              {ele}
            </a>)
          }
        </Space>
      </div>
    </>
  );
};

export default Menu;
