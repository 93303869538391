import { Col, Row } from "antd";
import React, { useContext, useState } from "react";
import Menu from "../../components/menu/index.js";
import { AuthContext } from "../../context/auth";
import { MenuContext } from "../../context/menu.js";
import WidgetEletricMobility from "../electricMobility/widget";
import ParkingWidget from "../parking/widget";
import Co2 from "./co2";
import Covid from "./covid";
import LightFixture from "./light_fixture";
import Maps from "./maps";
import Meteorology from "./meteorology";
import SocialNetworks from "./social_networks";
import Ticket from "./ticket";

const Home = () => {
  const { hasAccess } = useContext(AuthContext);
  const { data } = useContext(MenuContext);
  const [dateStart, setDateStart] = useState(data.start);
  const [dateEnd, setDateEnd] = useState(data.stop);

  const onClickedMenu = (data) => {
    let { start, end } = data;
    setDateStart(start);
    setDateEnd(end);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Menu onClicked={onClickedMenu} />
        </Col>
        <Col xs={24} lg={hasAccess('meteorology') || hasAccess('social') ? 18 : 24}>
          <Col xs={24}>
            <Maps dateStart={dateStart} dateEnd={dateEnd} ></Maps>
          </Col>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={hasAccess('ticket') ? 12 : 24}>
              {hasAccess('covid') &&
                <Covid />
              }
              {
                (hasAccess('light_fixture')) &&
                <LightFixture dateStart={dateStart} dateEnd={dateEnd} />
              }
            </Col>
            {hasAccess('ticket') &&
              <Col xs={24} md={hasAccess('covid') ? 12 : 24} style={{ marginTop: "1em" }}>
                <Ticket dateStart={dateStart} dateEnd={dateEnd} />
              </Col>
            }
            {
              <>
                {hasAccess('parking') &&
                  <Col xs={24} md={hasAccess('chargers') ? 12 : 24}>
                    <ParkingWidget />
                  </Col>
                }
                {hasAccess('chargers') &&
                  <Col xs={24} md={hasAccess('parking') ? 12 : 24}>
                    <WidgetEletricMobility dateStart={dateStart} dateEnd={dateEnd} />
                  </Col>
                }
              </>
            }
          </Row>
        </Col>
        {(hasAccess('meteorology') || hasAccess('social')) &&
          <Col xs={24} lg={6}>
            <Row>
              {hasAccess('meteorology') &&
                <Col xs={24} md={12} lg={24}>
                  <Meteorology />
                </Col>
              }
              {hasAccess('social') &&
                <Col xs={24} md={12} lg={24}>
                  <SocialNetworks />
                  <div style={{ marginTop: "15px" }}>
                    <Co2 dateStart={dateStart} dateEnd={dateEnd} />
                  </div>
                </Col>
              }
            </Row>
          </Col>
        }
      </Row>
    </>
  );
};

export default Home;
