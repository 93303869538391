import React from "react";
import "./style.css";
import Widget from "./widget";

const WaterConsumption = () => {
    return (
        <>
            <span>
                Consumo da água
            </span>
            <div className="buildingsWaterConsumptionContent">
                <Widget icon={"agua_potavel.svg"} title={"Água potável do Global do Edificio (m3)"} value={"16 m³"} />
                <Widget icon={"agua_global.svg"} title={"Custo água potável Global do Edifico (€)"} value={"2,35 €"} />
                <Widget icon={"agua_bruta.svg"} title={"Água bruta do Global do Edificio (m3)"} value={"67 m³"} />
                <Widget icon={"agua_potavel_piso_3.svg"} title={"Água potável do Piso 3 (m3)"} value={"45 m³"} />
                <Widget icon={"custo_agua_piso_3.svg"} title={"Custo água potável Piso 3 (€)"} value={"4,5 €"} />
                <Widget icon={"agua_bruta_piso_3.svg"} title={"Água bruta do Piso 3 (m3)"} value={"50 m³"} />
            </div>
        </>
    );
}

export default WaterConsumption;