
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons"
import { Col, Row } from "antd"
import React, { useEffect, useState } from "react"
import Page from "./pagination/Page"

const Filter = ({ locais, setLocais, localSelected, setLocalSelected, anos, anoSelected, setAnoSelected, setSection }) => {

  const [selected, setSelected] = useState()
  const [loading, setLoading] = useState(false)


  function onSelect(value) {
    setLocalSelected(value)
    setSection(value.name ? value.name : (locais.find(i => i.id === value.local).name || ""))

  }


  const onClickTree = async (local, page = 0) => {
    let _local = await locais.find(i => i.id === local)
    setLocais(locais.map(i => ({ ...i, open: local === i.id ? !_local.open : false })))
  }

  useEffect(() => {
    if (anos.length > 0) {
      setAnoSelected(anos[0])
    }

  }, [anos])

  useEffect(() => {
    if (localSelected && (localSelected.local || localSelected.search)) {
      setSelected([localSelected.children ? localSelected.children : localSelected.local])
    }
  }, [localSelected])

  useEffect(() => {
    setLoading(true)
  }, [locais])


  return (
    <Row>
      <span>
        Filtros
      </span>
      <div className="tree-content" >
        <Col style={{ overflow: "auto" }}>
          <ul>
            {(locais || []).map(i =>
              <li  >{i.open ? <MinusSquareOutlined style={i.open ? { color: "#1890ff" } : { color: '' }} onClick={() => onClickTree(i.id)} /> :
                <PlusSquareOutlined onClick={() => onClickTree(i.id)} />}
                <span onClick={() => onSelect({ local: i.id, bgri: undefined })} className="item" style={i.open ? { color: "#1890ff" } : { color: '' }} > {i.name}</span>
                {loading && i.open &&
                  <ul style={{ marginLeft: '20px' }}>
                    <Page local={i} onSelect={onSelect} />
                  </ul>
                }
              </li>)}
          </ul>
        </Col>
      </div>
    </Row >
  )
}

export default Filter
