import moment from "moment-timezone";
import React, { createContext, useState } from "react";

export const defaultStart = moment().utc().set({ h: 0, m: 0, s: 0 });

export const defaultStop = moment().utc().set({ h: 23, m: 59, s: 59 });

export const MenuContext = createContext();

export const MenuProvider = ({ children }) => {

  const [valueSelected, setValueSelected] = useState("now");
  const [data, setData] = useState({ start: defaultStart, end: defaultStop });

  return (
    <MenuContext.Provider
      value={{
        valueSelected: valueSelected,
        setValueSelected: setValueSelected,
        data: data,
        setData: setData
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
