import { Layout, Tooltip } from "antd";
import { Footer } from "antd/lib/layout/layout";
import React, { useContext, useState } from "react";
import logoutIcon from "../assets/icons/logout.svg";
import { AuthContext } from "../context/auth";
import ContentRoutes from "./content";
import HeaderContent from "./header";
import Menu from "./menu";
import "./style.css";
import footer from "../assets/images/footer_cteck.png";
import { useKeycloak } from "@react-keycloak/web";
import { Link, useLocation } from "react-router-dom";

const ContentPage = () => {

  const { logout, hasAccess } = useContext(AuthContext);
  const { Header, Sider, Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const { initialized, keycloak } = useKeycloak();
  let cteck = hasAccess("ctec");
  const contacts = useLocation().pathname.includes("contacts");

  return (
    <Layout>
      <Sider
        trigger={
          <div style={{textAlign: "center" }} onClick={() => logout()}>
            <img
              style={{ width: "20px", marginRight: "5px" }}
              src={logoutIcon}
              alt=""
            />
            <span>Logout</span>
          </div>
        }
        width={270}
        collapsed={collapsed}
        collapsedWidth="50"
        collapsible
        breakpoint="md"
      >
        <div style={{minHeight:"95%",}}><Menu collapsed={collapsed} setCollapsed={setCollapsed} /></div>
        {cteck && <div style={{marginLeft:"50px"}}><Tooltip placement="right" title={"Contacts"} className="tooltip"><Link to="/ctec/contacts" style={{color:contacts ? "#1890FF" : "#262626"}}>Contacts</Link></Tooltip></div>}
      </Sider>

      <Layout className="layout">
        <Header className="layout-background" style={{ padding: 0 }}>
          <HeaderContent />
        </Header>

        <Content>
          <div className="content-container">
            <ContentRoutes />
            <Footer>Bright {cteck && "by NOS"} ©2023  {cteck && <img src={footer} style={{ float:"right", width: "450px" , maxWidth:"65%"}}/>} </Footer>
            <form
              id="post-logout"
              name="post-logout"
              action={`${process.env.REACT_APP_KEYCLOAK_URL}realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout`}
              method="post">
              <input type="hidden" name="client_id" value={keycloak.clientId} />
              <input type="hidden" name="refresh_token" id="refresh_token" value={keycloak.refreshToken} />
            </form>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ContentPage;
