import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import syncIcon from "../../../assets/icons/sync.svg";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import "./style.css";

const ListShippers = ({ dateStart, dateEnd }) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [rowPerPage, setRowPerPage] = useState(5);
    const [pagination, setPagination] = useState({});

    const fetchSessions = () => {
        setLoading(true);
        setError(undefined);
        api
            .post("/chargers/sessions/find/date", {
                dateStart: dateStart ? moment(dateStart).utc() : undefined,
                dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
                rowPerPage: rowPerPage,
            })
            .then((response) => {
                let { data } = response;
                setPagination(data);
            })
            .catch((error) => {
                setError(error?.response?.data?.message || "unknown error");
            })
            .finally(() => {
                setLoading(false);
            });
    };


    function milisecondToTime(milissegundo) {
        if (milissegundo !== undefined && milissegundo != null) {
            var ms = milissegundo % 1000;
            milissegundo = (milissegundo - ms) / 1000;
            var secs = milissegundo % 60;
            milissegundo = (milissegundo - secs) / 60;
            var mins = milissegundo % 60;
            var hrs = (milissegundo - mins) / 60;
            return hrs + ' h  ' + mins + 'm'
        }
        return 0;
    }

    function format(value, decimal) {
        return Number(value).toLocaleString(
            undefined,
            { minimumFractionDigits: decimal }
        );
    }

    useEffect(fetchSessions, [dateStart, dateEnd, rowPerPage]);

    return (
        <div style={{ marginTop: "1em" }}>
            <span>
                Resultado da pesquisa
            </span>
            {error ? <WidgetError
                message={`${error}`}
                refresh={() => fetchSessions()}
            /> : <div className="electric_mobility_list_shippers">
                <table>
                    <tr>
                        <th>
                            Modelo
                        </th>
                        <th>
                            Estado
                        </th>
                        <th>
                            Localização
                        </th>
                        <th>
                            Duração
                        </th>
                        <th>
                            Energia
                        </th>
                    </tr>
                    {!loading && pagination?.empty && (
                        <tr>
                            <td colSpan={5} style={{ textAlign: "center " }}>Sem dados</td>
                        </tr>
                    )}
                    {pagination?.content?.map((session) => (
                        <tr>
                            <td>{session.stationname}</td>
                            <td>{!session.endCharge ?
                                <span className="electric_status active">
                                    Activo
                                </span> :
                                <span className="electric_status finished">
                                    Finalizado
                                </span>}</td>
                            <td>{moment.utc(session.startCharge).local().format('D/M/Y HH:mm')}</td>
                            <td>{milisecondToTime(session.duration)}</td>
                            <td>{`${format(session.chargedenergy / 1000, 2)} kWh`}</td>
                        </tr>
                    ))}
                    {loading && (
                        <tr>
                            <td colSpan={5}>
                                <Loading />
                            </td>
                        </tr>
                    )}
                    {!loading && !pagination?.last &&
                        <tr>
                            <td colSpan={5}>
                                <div
                                    class="btn-carregarmais"
                                    style={{ cursor: "pointer", marginTop: "1em", textAlign: "center" }}
                                    onClick={() =>
                                        setRowPerPage(parseInt(rowPerPage + rowPerPage * 0.5))
                                    }
                                >
                                    <img src={syncIcon} alt=""></img>
                                    <br />
                                    <span>Carregar mais</span>
                                    <br></br>
                                </div>
                            </td>
                        </tr>
                    }
                </table>
            </div>}
        </div>
    );
}

export default ListShippers;