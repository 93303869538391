import React, { useState } from "react"
import "../style.css";
import AgricultureModel from "../../../assets/icons/BuildingAgricultureComplex.svg"
import EnergyModel from "../../../assets/icons/BuildingEnergyConsumptionComplex.svg"
import MobilityModel from "../../../assets/icons/IntelligentMobilityComplex.svg"
import ConfortModel from "../../../assets/icons/UrbanHumanConfortComplex.svg"
import { Link } from "react-router-dom";
import _Menu from "../../../templeate/menu"
 

const BuildingAgriculture = () => {

    const [selected, setSelected] = useState("Agriculture");
    const [overBuilding, setOverBuilding] = useState(false);
    const imageSize = "20%"
    const imageOpacity = "opacity(30%)"

    return(
        <div className="cteck-home">
            <div className="cteck-content" style={{ fontSize: "1.0em", color: "black", padding: "8px" }}>
                <div style={{fontSize:"1.5em", color:"black", textAlign:"center", fontWeight:"bold", padding: "10px"}}>MODELS</div>
                <div style={{display:"flex", fontSize: "1.1em", color: "#000", marginBottom:"10%", gap:"5%", justifyContent:"space-around", marginLeft:"3vw"}} >
                    <Link to="/ctec/building" style={{flex:"1 1 0px"}}>
                        <img 
                            src={EnergyModel} 
                            alt="Building Energy Consumption" 
                            onClick={() =>setSelected("Energy")}
                            style={{filter:selected=="Energy"?"opacity(100%)":imageOpacity}}
                        />
                        <p style={{textAlign:"center", filter:selected=="Energy"?"opacity(100%)":imageOpacity}}>Building Energy Consumption</p>
                    </Link>
                    <Link to="/ctec/urbanhumanconfort"style={{flex:"1 1 0px"}}>
                        <img 
                            src={ConfortModel} 
                            alt="Urban Human Confort" 
                            onClick={() => setSelected("Confort")}
                            style={{filter:selected=="Confort"?"opacity(100%)":imageOpacity}}
                        />
                        <p style={{textAlign:"center", filter:selected=="Confort"?"opacity(100%)":imageOpacity}}>Urban Human Confort</p>
                    </Link>
                    <Link to="/ctec/agriculture"style={{flex:"1 1 0px"}}>
                        <img 
                            src={AgricultureModel} 
                            alt="Building Agriculture" 
                            onClick={() => setSelected("Agriculture")}
                            style={{filter:selected=="Agriculture"?"opacity(100%)":imageOpacity}}
                        />
                        <p style={{textAlign:"center", filter:selected=="Agriculture"?"opacity(100%)":imageOpacity}}>Building Agriculture</p>
                    </Link>
                    <Link to="/ctec/mobility"style={{flex:"1 1 0px"}}>
                        <img 
                            src={MobilityModel} 
                            alt="Intelligent Mobility" 
                            onClick={() => setSelected("Mobility")}
                            style={{filter:selected=="Mobility"?"opacity(100%)":imageOpacity}}
                        />
                        <p style={{textAlign:"center", filter:selected=="Mobility"?"opacity(100%)":imageOpacity}}>Intelligent Mobility</p>
                    </Link>
                </div>
                <div>
                    <p>Develop a <b>Building Integrated Agriculture</b> simulation model, that quantify the potential for local food production making use of building integrated agriculture (BIA) in balconies or rooftops, defining the hydroponic technology components that are to be used and resulting on a <b>Building Integrated Agriculture Planner (BIASP)</b>.</p><br />
                </div>
            </div>
        </div>
    )
}

export default BuildingAgriculture