import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import api from "../../../../../services/api";
import "./style.css";
import Widget from "./widget";
import { Col, Row, Select } from 'antd';
import filterIcon from "../../../../../assets/icons/filter.svg";

const Statistic = ({ dateStart, dateEnd }) => {

  const { Option } = Select;

  const [totalPeoplesLoading, setTotalPeoplesLoading] = useState(false);
  const [totalPeoplesError, setTotalPeoplesError] = useState();
  const [totalPeoples, setTotalPeoples] = useState(0);

  const fetchTotalPeoples = () => {
    setTotalPeoplesLoading(true);
    setTotalPeoplesError(undefined);
    api.post("/building/statistics/total/peoples", dateComparative)
      .then((response) => {
        setTotalPeoples(response.data);
      })
      .catch((error) => {
        setTotalPeoplesError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setTotalPeoplesLoading(false);
      });
  };

  const [totalPeoplesLoadingAnalyse, setTotalPeoplesLoadingAnalyse] = useState(false);
  const [totalPeoplesErroAnalyser, setTotalPeoplesErrorAnalyse] = useState();
  const [totalPeoplesAnalyse, setTotalPeoplesAnalyse] = useState(0);

  const fetchTotalPeoplesAnalyse = () => {
    setTotalPeoplesLoading(true);
    setTotalPeoplesError(undefined);
    api.post("/building/statistics/total/peoples", datesAnalyse)
      .then((response) => {
        setTotalPeoplesAnalyse(response.data);
      })
      .catch((error) => {
        setTotalPeoplesErrorAnalyse(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setTotalPeoplesLoadingAnalyse(false);
      });
  };

  const [totalSalesLoading, setTotalSalesLoading] = useState(false);
  const [totalSalesError, setTotalSalesError] = useState();
  const [totalSales, setTotalSales] = useState(0);

  const fetchTotalSales = () => {
    setTotalSalesLoading(true);
    setTotalSalesError(undefined);
    api
      .post("/building/statistics/total/sales", dateComparative)
      .then((response) => {
        setTotalSales(response.data);
      })
      .catch((error) => {
        setTotalSalesError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setTotalSalesLoading(false);
      });
  };

  const [totalSalesLoadingAnalyse, setTotalSalesLoadingAnalyse] = useState(false);
  const [totalSalesErrorAnalyse, setTotalSalesErrorAnalyse] = useState();
  const [totalSalesAnalyse, setTotalSalesAnalyse] = useState(0);

  const fetchTotalSalesAnalyse = () => {
    setTotalSalesLoadingAnalyse(true);
    setTotalSalesErrorAnalyse(undefined);
    api
      .post("/building/statistics/total/sales", datesAnalyse)
      .then((response) => {
        setTotalSalesAnalyse(response.data);
      })
      .catch((error) => {
        setTotalSalesErrorAnalyse(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setTotalSalesLoadingAnalyse(false);
      });
  };

  const [totalSalesSqmLoading, setTotalSalesSqmLoading] = useState(false);
  const [totalSalesSqmError, setTotalSalesSqmError] = useState();
  const [totalSalesSqm, setTotalSalesSqm] = useState(0);

  const fetchTotalSalesSqm = () => {
    setTotalSalesSqmLoading(true);
    setTotalSalesSqmError(undefined);
    api
      .post("/building/statistics/total/sales/sqm", dateComparative)
      .then((response) => {
        setTotalSalesSqm(response.data);
      })
      .catch((error) => {
        setTotalSalesSqmError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setTotalSalesSqmLoading(false);
      });
  };

  const [totalSalesSqmLoadingAnalyse, setTotalSalesSqmLoadingAnalyse] = useState(false);
  const [totalSalesSqmErrorAnalyse, setTotalSalesSqmErrorAnalyse] = useState();
  const [totalSalesSqmAnalyse, setTotalSalesSqmAnalyse] = useState(0);

  const fetchTotalSalesSqmAnalyse = () => {
    setTotalSalesSqmLoadingAnalyse(true);
    setTotalSalesSqmErrorAnalyse(undefined);
    api
      .post("/building/statistics/total/sales/sqm", datesAnalyse)
      .then((response) => {
        setTotalSalesSqmAnalyse(response.data);
      })
      .catch((error) => {
        setTotalSalesSqmErrorAnalyse(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setTotalSalesSqmLoadingAnalyse(false);
      });
  };

  const [totalInvoicedLoading, setTotalInvoicedLoading] = useState(false);
  const [totalInvoicedError, setTotalInvoicedError] = useState();
  const [totalInvoiced, setTotalInvoiced] = useState(0);

  const fetchTotalInvoiced = () => {
    setTotalInvoicedLoading(true);
    setTotalInvoicedError(undefined);
    api
      .post("/building/statistics/total/invoiced", dateComparative)
      .then((response) => {
        setTotalInvoiced(response.data);
      })
      .catch((error) => {
        setTotalInvoicedError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setTotalInvoicedLoading(false);
      });
  };

  const [totalInvoicedLoadingAnalyse, setTotalInvoicedLoadingAnalyse] = useState(false);
  const [totalInvoicedErrorAnalyse, setTotalInvoicedErrorAnalyse] = useState();
  const [totalInvoicedAnalyse, setTotalInvoicedAnalyse] = useState(0);

  const fetchTotalInvoicedAnalyse = () => {
    setTotalInvoicedLoadingAnalyse(true);
    setTotalInvoicedErrorAnalyse(undefined);
    api
      .post("/building/statistics/total/invoiced", datesAnalyse)
      .then((response) => {
        setTotalInvoicedAnalyse(response.data);
      })
      .catch((error) => {
        setTotalInvoicedErrorAnalyse(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setTotalInvoicedLoadingAnalyse(false);
      });
  };

  const [totalExtraContractualDiscountsLoading, setTotalExtraContractualDiscountsLoading] = useState(false);
  const [totalExtraContractualDiscountsError, setTotalExtraContractualDiscountsError] = useState();
  const [totalExtraContractualDiscounts, setTotalExtraContractualDiscounts] = useState(0);

  const fetchTotalExtraContractualDiscounts = () => {
    setTotalExtraContractualDiscountsLoading(true);
    setTotalExtraContractualDiscountsError(undefined);
    api
      .post("/building/statistics/total/discounts/extra/contractual", dateComparative)
      .then((response) => {
        setTotalExtraContractualDiscounts(response.data);
      })
      .catch((error) => {
        setTotalExtraContractualDiscountsError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setTotalExtraContractualDiscountsLoading(false);
      });
  };

  const [totalExtraContractualDiscountsLoadingAnalyse, setTotalExtraContractualDiscountsLoadingAnalyse] = useState(false);
  const [totalExtraContractualDiscountsErrorAnalyse, setTotalExtraContractualDiscountsErrorAnalyse] = useState();
  const [totalExtraContractualDiscountsAnalyse, setTotalExtraContractualDiscountsAnalyse] = useState(0);

  const fetchTotalExtraContractualDiscountsAnalyse = () => {
    setTotalExtraContractualDiscountsLoadingAnalyse(true);
    setTotalExtraContractualDiscountsErrorAnalyse(undefined);
    api
      .post("/building/statistics/total/discounts/extra/contractual", datesAnalyse)
      .then((response) => {
        setTotalExtraContractualDiscountsAnalyse(response.data);
      })
      .catch((error) => {
        setTotalExtraContractualDiscountsErrorAnalyse(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setTotalExtraContractualDiscountsLoadingAnalyse(false);
      });
  };

  const [totalContractualDiscountsLoading, setTotalContractualDiscountsLoading] = useState(false);
  const [totalContractualDiscountsError, setTotalContractualDiscountsError] = useState();
  const [totalContractualDiscounts, setTotalContractualDiscounts] = useState(0);

  const fetchTotalContractualDiscounts = () => {
    setTotalContractualDiscountsLoading(true);
    setTotalContractualDiscountsError(undefined);
    api
      .post("/building/statistics/total/discounts/contractual", dateComparative)
      .then((response) => {
        setTotalContractualDiscounts(response.data);
      })
      .catch((error) => {
        setTotalContractualDiscountsError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setTotalContractualDiscountsLoading(false);
      });
  };

  const [totalContractualDiscountsLoadingAnalyse, setTotalContractualDiscountsLoadingAnalyse] = useState(false);
  const [totalContractualDiscountsErrorAnalyse, setTotalContractualDiscountsErrorAnalyse] = useState();
  const [totalContractualDiscountsAnalyse, setTotalContractualDiscountsAnalyse] = useState(0);

  const fetchTotalContractualDiscountsAnalyse = () => {
    setTotalContractualDiscountsLoadingAnalyse(true);
    setTotalContractualDiscountsErrorAnalyse(undefined);
    api
      .post("/building/statistics/total/discounts/contractual", datesAnalyse)
      .then((response) => {
        setTotalContractualDiscountsAnalyse(response.data);
      })
      .catch((error) => {
        setTotalContractualDiscountsErrorAnalyse(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setTotalContractualDiscountsLoadingAnalyse(false);
      });
  };

  const [percentageCffortRateLoading, setPercentageCffortRateLoading] = useState(false);
  const [percentageCffortRateError, setPercentageCffortRateError] = useState();
  const [percentageCffortRate, setPercentageCffortRate] = useState(0);

  const fetchPercentageCffortRate = () => {
    setPercentageCffortRateLoading(true);
    setPercentageCffortRateError(undefined);
    api
      .post("/building/statistics/percentage/cffortRate", dateComparative)
      .then((response) => {
        setPercentageCffortRate(response.data);
      })
      .catch((error) => {
        setPercentageCffortRateError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setPercentageCffortRateLoading(false);
      });
  };

  const [percentageCffortRateLoadingAnalyse, setPercentageCffortRateLoadingAnalyse] = useState(false);
  const [percentageCffortRateErrorAnalyse, setPercentageCffortRateErrorAnalyse] = useState();
  const [percentageCffortRateAnalyse, setPercentageCffortRateAnalyse] = useState(0);

  const fetchPercentageCffortRateAnalyse = () => {
    setPercentageCffortRateLoadingAnalyse(true);
    setPercentageCffortRateErrorAnalyse(undefined);
    api
      .post("/building/statistics/percentage/cffortRate", datesAnalyse)
      .then((response) => {
        setPercentageCffortRateAnalyse(response.data);
      })
      .catch((error) => {
        setPercentageCffortRateErrorAnalyse(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setPercentageCffortRateLoadingAnalyse(false);
      });
  };

  const [dates, setDates] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [yearAnalyzeSelected, setYearAnalyzeSelected] = useState(moment().add(-1, 'month').year());
  const [monthAnalyzeSelected, setMonthAnalyzeSelected] = useState(moment().add(-1, 'month').format('MM'));
  const [yearComparativeSelected, setYearComparativeSelected] = useState(moment().add(-1, 'month').add(-1, 'year').year());
  const [monthComparativeSelected, setMonthComparativeSelected] = useState(moment().add(-1, 'month').add(-1, 'year').format('MM'));

  const [datesAnalyse, setDatesAnalyse] = useState([]);
  const [dateComparative, setDatesComparative] = useState([]);

  const convertDate = (dates) => {
    return dates.map((_date) => moment.utc(_date));
  }

  const onChangeParams = () => {
    //analyse params
    if (monthAnalyzeSelected == "all" && yearAnalyzeSelected == "all") {
      setDatesAnalyse(dates);
    } else if (monthAnalyzeSelected == "all") {
      let _dates = [];
      months.forEach((month) => {
        _dates.push(`${yearAnalyzeSelected}/${month}/02`);
      });
      setDatesAnalyse(convertDate(_dates));
    } else if (yearAnalyzeSelected == "all") {
      let _dates = [];
      years.forEach((_year) => {
        _dates.push(`${_year}/${monthAnalyzeSelected}/02`);
      });
      setDatesAnalyse(convertDate(_dates));
    } else {
      setDatesAnalyse(convertDate([`${yearAnalyzeSelected}/${monthAnalyzeSelected}/02`]));
    }

    //comparative params
    if (monthComparativeSelected == "all" && yearComparativeSelected == "all") {
      setDatesComparative(dates);
    } else if (monthComparativeSelected == "all") {
      let _dates = [];
      months.forEach((month) => {
        _dates.push(`${yearComparativeSelected}/${month}/02`);
      });
      setDatesComparative(convertDate(_dates));
    } else if (yearComparativeSelected == "all") {
      let _dates = [];
      years.forEach((_year) => {
        _dates.push(`${_year}/${monthComparativeSelected}/02`);
      });
      setDatesComparative(convertDate(_dates));
    } else {
      setDatesComparative(convertDate([`${yearComparativeSelected}/${monthComparativeSelected}/02`]));
    }
  }

  useEffect(onChangeParams, [yearAnalyzeSelected, monthAnalyzeSelected, yearComparativeSelected, monthComparativeSelected]);

  const fetchDates = () => api.get("/building/statistics/find/dates")
    .then((response) => {
      let { data } = response;
      if (Array.isArray(data)) {
        let _years = [];
        let _months = [];
        data.forEach(element => {
          let _moment = moment.utc(element.date);
          _years.push(_moment.year());
          _months.push(_moment.format('MM'));
        });
        setYears([...new Set(_years)].sort());
        setMonths([...new Set(_months)].sort());
        setDates(response.data.map((ele) => ele.date));
      }
    });

  useEffect(fetchDates, []);

  useEffect(() => {
    fetchTotalPeoples();
    fetchTotalSales();
    fetchTotalSalesSqm();
    fetchTotalInvoiced();
    fetchTotalExtraContractualDiscounts();
    fetchTotalContractualDiscounts();
    fetchPercentageCffortRate();
  }, [dateComparative, dates]);

  useEffect(() => {
    fetchTotalPeoplesAnalyse();
    fetchTotalSalesAnalyse();
    fetchTotalSalesSqmAnalyse();
    fetchTotalInvoicedAnalyse();
    fetchTotalExtraContractualDiscountsAnalyse();
    fetchTotalContractualDiscountsAnalyse();
    fetchPercentageCffortRateAnalyse();
  }, [datesAnalyse, dates]);

  function format(value, decimal) {
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: decimal,
    });
  }

  return (
    <>
      <div className="filters">
        <img src={filterIcon} /> Filtros
      </div>

      <div className="finalcialFilters">
        <Row>
          <Col xs={24} md={12}>
            <div>
              <span>
                Período em análise:
              </span>

              <Select value={monthAnalyzeSelected} bordered={false} onChange={(newValue) => setMonthAnalyzeSelected(newValue)}>
                <Option value="all" title="Todos">-</Option>
                {months.map((month) => <Option value={month}>{month}</Option>)}
              </Select>
              /
              <Select value={yearAnalyzeSelected} bordered={false} onChange={(newValue) => setYearAnalyzeSelected(newValue)}>
                <Option value="all" title="Todos">-</Option>
                {years.map((year) => <Option value={year}>{year}</Option>)}
              </Select>
            </div>
          </Col>

          <Col xs={24} md={12}>
            <div style={{ textAlign: "right" }}>
              <span>
                Período comparativo:
              </span>

              <Select defaultValue={monthComparativeSelected} bordered={false} onChange={(newValue) => setMonthComparativeSelected(newValue)}>
                <Option value="all" title="Todos">-</Option>
                {months.map((month) => <Option value={month}>{month}</Option>)}
              </Select>
              /
              <Select defaultValue={yearComparativeSelected} bordered={false} onChange={(newValue) => setYearComparativeSelected(newValue)}>
                <Option value="all" title="Todos">-</Option>
                {years.map((year) => <Option value={year}>{year}</Option>)}
              </Select>
            </div>
          </Col>
        </Row>
      </div>

      <span>Informação Geral</span>

      <div>
        <Widget
          icon="trafego.svg"
          title="Tráfego / Número de Visitantes"
          valueComparative={`${format(totalPeoples.value)}`}
          loadingComparative={totalPeoplesLoading}
          errorComparative={totalPeoplesError}
          reloadComparative={fetchTotalPeoples}

          valueAnalyse={`${format(totalPeoplesAnalyse.value)}`}
          loadingAnalyse={totalPeoplesLoadingAnalyse}
          errorComparativeAnalyse={totalPeoplesErroAnalyser}
          reloadComparativeAnalyse={fetchTotalPeoplesAnalyse}
        />

        <Widget
          icon="vendas.svg"
          title="Vendas"
          valueComparative={`${format(totalSales.value)}€`}
          loadingComparative={totalSalesLoading}
          errorComparative={totalSalesError}
          reloadComparative={fetchTotalSales}

          valueAnalyse={`${format(totalSalesAnalyse.value)}€`}
          loadingAnalyse={totalSalesLoadingAnalyse}
          errorAnalyse={totalSalesErrorAnalyse}
          reloadAnalyse={fetchTotalSalesAnalyse}
        />

        <Widget
          icon="vendas_por_km2.svg"
          title="Vendas por m2"
          valueComparative={`${format(totalSalesSqm.value)}€`}
          loadingComparative={totalSalesSqmLoading}
          errorComparative={totalSalesSqmError}
          reloadComparative={fetchTotalSalesSqm}

          valueAnalyse={`${format(totalSalesSqmAnalyse.value)}€`}
          loadingAnalyse={totalSalesSqmLoadingAnalyse}
          errorAnalyse={totalSalesSqmErrorAnalyse}
          reloadAnalyse={fetchTotalSalesSqmAnalyse}
        />

        <Widget
          icon="rendas.svg"
          title="Rendas"
          valueComparative={`${format(totalInvoiced.value)}€`}
          loadingComparative={totalInvoicedLoading}
          errorComparative={totalInvoicedError}
          reloadComparative={fetchTotalInvoiced}

          valueAnalyse={`${format(totalInvoicedAnalyse.value)}€`}
          loadingAnalyse={totalInvoicedLoadingAnalyse}
          errorAnalyse={totalInvoicedErrorAnalyse}
          reloadAnalysee={fetchTotalInvoicedAnalyse}
        />

        <Widget
          icon="descontos.svg"
          title="Descontos Extracontratuais"
          valueComparative={`${format(totalExtraContractualDiscounts.value)}€`}
          loadingComparative={totalExtraContractualDiscountsLoading}
          errorComparative={totalExtraContractualDiscountsError}
          reloadComparative={fetchTotalExtraContractualDiscounts}

          valueAnalyse={`${format(totalExtraContractualDiscountsAnalyse.value)}€`}
          loadingAnalyse={totalExtraContractualDiscountsLoadingAnalyse}
          errorAnalyse={totalExtraContractualDiscountsErrorAnalyse}
          reloadAnalyse={fetchTotalExtraContractualDiscountsAnalyse}
        />

        <Widget
          icon="descontos_contratuais.svg"
          title="Descontos Contratuais"
          valueComparative={`${format(totalContractualDiscounts.value)}€`}
          loadingComparative={totalContractualDiscountsLoading}
          errorComparative={totalContractualDiscountsError}
          reloadComparative={fetchTotalContractualDiscounts}

          valueAnalyse={`${format(totalContractualDiscountsAnalyse.value)}€`}
          loadingAnalyse={totalContractualDiscountsLoadingAnalyse}
          errorAnalyse={totalContractualDiscountsErrorAnalyse}
          reloadAnalyse={fetchTotalContractualDiscountsAnalyse}
        />

        <Widget
          icon="taxa_esforco.svg"
          title="Taxa de Esforço"
          valueComparative={`${format(percentageCffortRate.value)}%`}
          loadingComparative={percentageCffortRateLoading}
          errorComparative={percentageCffortRateError}
          reloadComparative={fetchPercentageCffortRate}

          valueAnalyse={`${format(percentageCffortRateAnalyse.value)}%`}
          loadingAnalyse={percentageCffortRateLoadingAnalyse}
          errorAnalyse={percentageCffortRateErrorAnalyse}
          reloadAnalyse={fetchPercentageCffortRateAnalyse}
        />
      </div>
    </>
  );
};

export default Statistic;
