import { Col, Row } from "antd";
import React from "react";
import "./style.css";

const Childen = ({ icon, description, value }) => {

    return (
        <div className="buildingsStatisticPanelChildenContent">
            <Row>
                <Col xs={3}>
                    <img src={require(`../../../../../assets/icons/buildings/statistics/${icon}`)}></img>
                </Col>
                <Col xs={13}>
                    <div className="description'">
                        {description}
                    </div>
                </Col>
                <Col xs={8}>
                    <div className="value">
                        {value}
                    </div>
                </Col>
            </Row>
        </div>
    );

}

export default Childen;