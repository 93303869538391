import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import pinIcon from "../../../assets/icons/pin.svg";
import syncIcon from "../../../assets/icons/sync.svg";
import Loading from "../../../components/loading";
import api from "../../../services/api";

const Table = ({ environment, dateStart, dateEnd }) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [rowPerPage, setRowPerPage] = useState(5);
    const [pagination, setPagination] = useState({});

    const getToken = () => {
        let credentials = JSON.parse(localStorage.getItem(`credentials-${process.env.PUBLIC_URL}`));
        return `${credentials.token_type} ${credentials.access_token}`;
    };

    const refreshTickets = () => {
        setLoading(true);
        setError(undefined);
        api.post("/helpdesk/ticket/search", {
            startTo: dateStart ? moment(dateStart).utc() : undefined,
            endTo: dateEnd ? moment(dateEnd).utc() : undefined,
            rowPerPage: rowPerPage,
        }).then((response) => {
            let { data } = response;
            setPagination(data);
        })
            .catch((error) => {
                setError(error?.response?.data?.message || "unknown error");
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(refreshTickets, [dateStart, dateEnd, rowPerPage]);

    return (
        <>
            <div className="occurrentesTitle">
                <span>
                    Ocorrências
                </span>
            </div>

            <div className="occurrencesListContent">
                <table>
                    <tr>
                        <th>Código</th>
                        <th>Estado</th>
                        <th>Categoria</th>
                        <th>Nome</th>
                        <th>Hora</th>
                        <th>Morada</th>
                        <th>Classificação de urgência</th>
                        <th>Tempo de resolução</th>
                    </tr>
                    {!loading && pagination?.empty &&
                        <tr>
                            <td colSpan={8}>
                                Sem dados
                            </td>
                        </tr>
                    }
                    {pagination?.content?.map((element) =>
                        <tr>
                            <td>{element?.protocol}</td>
                            <td><div title={element?.status?.description} class="circle" style={{ backgroundColor: element?.status?.color }}></div></td>
                            <td>{element?.category?.icon ?
                                <img
                                    style={{maxWidth: "35px"}}
                                    alt={element?.category?.name}
                                    title={element?.category?.name}
                                    src={`${process.env.REACT_APP_API_URL}/storage/render/${element?.category?.icon?.id}`}
                                />
                                : '-'}
                            </td>
                            <td>{element?.category?.name || '-'}</td>
                            <td>{moment.utc(element?.creationDate).local().format('D/M/Y HH:mm')}</td>
                            <td>{function (_text = "") {
                                _text = _text.replaceAll('  ', '').trim();
                                if (_text == '') {
                                    return 'Sem dados';
                                } else {
                                    return _text.trim();
                                }
                            }(`${(element?.address?.route || '')} ${(element?.address?.streetNumber || '')} ${(element?.address?.locality || '')}`)}</td>
                            <td>
                                {element?.urgentClassification?.description
                                    ? <span
                                        style={{ backgroundColor: element?.urgentClassification?.color }}
                                        class="urgenteClassification">
                                        {element?.urgentClassification?.description || 'Não classificado'}
                                    </span>
                                    : '-'}
                            </td>
                            <td>{element?.timeResolution?.description || '-'}</td>
                        </tr>)
                    }

                    {loading && <tr>
                        <td colSpan={8}>
                            <Loading />
                        </td>
                    </tr>}
                </table>
                {!pagination?.last ?
                    <div
                        class="btn-carregarmais"
                        style={{ cursor: "pointer" }}
                        onClick={() => setRowPerPage(parseInt(rowPerPage + rowPerPage * 0.5))}>
                        <img src={syncIcon}></img>
                        <br />
                        <span>Carregar mais</span>
                    </div>
                    : <br />
                }
            </div>
        </>)
}

export default Table;