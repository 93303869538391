import { Select } from 'antd';
import moment from 'moment-timezone';
import 'moment/locale/pt';
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import api from "../../../services/api";

var pt = require("apexcharts/dist/locales/pt.json");

const Practiced = () => {

    const [series, setSeries] = useState([])
    const [categories, setCategories] = useState([])
    const [day, setDay] = useState(7)

    const fetchsForecastWeather = () => api.get(`/meteorology/forecast/obs3h/top?limit=${day}`).then((response) => setSeries(generateSeries(response.data || [])));

    const generateSeries = (_data) => {
        let forecastDate = [];
        _data.forEach((element) => {
            if (!forecastDate.includes(element.time))
                forecastDate.push(element.time);
        });
        setCategories(forecastDate)
        return [
            {
                name: "Temperatura",
                data: forecastDate.map((_date) => _data.find((max) => max.time === _date)?.temperatura || 0),
            }
        ];
    };

    useEffect(fetchsForecastWeather, [day])

    return (<div style={{ marginTop: "10px" }}>
        <span>Temperatura média registrada nos últimos dias</span>
        <div className="contentParkWidget" style={{ marginTop: "0px" }}>
            <Select bordered={false} defaultValue="7" style={{ width: 150 }} onChange={(value) => setDay(value)}>
                <Select.Option value="7">Últimos 7 dias</Select.Option>
                <Select.Option value="10">Últimos 10 dias</Select.Option>
                <Select.Option value="20">Últimos 20 dias</Select.Option>
                <Select.Option value="30">Últimos 30 dias</Select.Option>
            </Select>
            <Chart
                options={{
                    dataLabels: {
                        enabled: false
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val, index) {
                                return `${val.toLocaleString()}°`;
                            }
                        }
                    },
                    xaxis: {
                        type: "datetime",
                        categories: categories,
                        labels: {
                            formatter: function (val, index) {
                                return `${moment.utc(val).local().format('ddd DD/M H:mm')}`;
                            }
                        }
                    },
                    chart: {
                        locales: [pt],
                        defaultLocale: "pt",
                        toolbar: { show: false },
                        type: 'bar'
                    },
                }}
                series={series}
                type="area"
                height={250}
                width="100%" />
        </div>
    </div>)
}

export default Practiced