// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/images/eletricMobility/suppliedEnergy/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eletricMobilityWidget.suppliedEnergy{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position: center;\n    background-repeat: no-repeat;\n}", "",{"version":3,"sources":["webpack://./src/pages/electricMobility/widget/suppliedEnergy/style.css"],"names":[],"mappings":"AAAA;IACI,yDAAyF;IACzF,2BAA2B;IAC3B,4BAA4B;AAChC","sourcesContent":[".eletricMobilityWidget.suppliedEnergy{\n    background-image: url(\"/src/assets/images/eletricMobility/suppliedEnergy/background.png\");\n    background-position: center;\n    background-repeat: no-repeat;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
