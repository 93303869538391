import React from "react";

function Icon({ station, temperatura, time, weatherTypePT }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      viewBox="0 0 349 132"
    >
      <defs>
        <filter
          id="e5ne6k2dsa"
          width="140.6%"
          height="322.7%"
          x="-20.3%"
          y="-106.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="orenhv3xuc"
          width="149.5%"
          height="202.1%"
          x="-24.7%"
          y="-49%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="bky5oi866e"
          width="140%"
          height="304.2%"
          x="-20%"
          y="-97.9%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="8ittbfbw0g"
          width="222.5%"
          height="322.7%"
          x="-61.2%"
          y="-106.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <text
          id="byw5wzmvfb"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="18"
          fontWeight="bold"
        >
          <tspan x="9" y="103">
            {station}
          </tspan>
        </text>
        <text
          id="xuarft34dd"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="40"
          fontWeight="bold"
        >
          <tspan x="9" y="46">
            {temperatura}
          </tspan>
        </text>
        <text
          id="gwkr5799of"
          fill="#FFF"
          fontFamily="JosefinSans-Light, Josefin Sans"
          fontSize="20"
          fontWeight="300"
        >
          <tspan x="9" y="70">
            {weatherTypePT}
          </tspan>
        </text>
        <text
          id="6xh8k8vt4h"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="18"
          fontWeight="bold"
        >
          <tspan x="286" y="103">
            {time}
          </tspan>
        </text>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FCA13C"
          d="M27.511 7H321.49c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 17.793 342 20.38 342 27.511v73.846c0 7.132-.743 9.718-2.137 12.325a14.538 14.538 0 01-6.048 6.049c-2.608 1.394-5.194 2.137-12.326 2.137H27.51c-7.132 0-9.718-.743-12.326-2.137a14.538 14.538 0 01-6.048-6.049C7.743 111.075 7 108.49 7 101.357V27.51c0-7.132.743-9.718 2.137-12.326a14.538 14.538 0 016.048-6.048C17.793 7.743 20.38 7 27.511 7z"
        ></path>
        <path
          fill="#FDB053"
          d="M321.489 7c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 17.793 342 20.38 342 27.511l.003 43.7C327.083 72.381 311.51 73 295.5 73 185.32 73 96 43.675 96 7.5c0-.167.002-.334.006-.5h225.483z"
        ></path>
        <path
          fill="#FCC66B"
          d="M321.489 7c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 17.793 342 20.38 342 27.511l.002 33.861C327.415 63.08 311.868 64 295.734 64 211.934 64 144 39.152 144 8.5c0-.502.018-1.002.054-1.501L321.49 7z"
        ></path>
        <path
          fill="#FCE765"
          d="M321.489 7c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 17.793 342 20.38 342 27.511V53.64c-4.423.238-8.928.36-13.5.36C268.577 54 220 32.957 220 7h101.489zm3.088 80.022c.937 0 1.844.052 2.72.153H342v17.27c0 3.562-.749 6.685-2.142 9.266a14.674 14.674 0 01-6.014 6.014c-2.582 1.394-5.705 2.143-9.267 2.143H24.423c-3.562 0-6.685-.749-9.267-2.143a14.674 14.674 0 01-6.014-6.014C7.75 111.131 7 108.007 7 104.445l.004-.424H7V87.177h14.704a23.524 23.524 0 012.719-.154h300.154z"
        ></path>
        <g fill="#FFF" transform="translate(7 7)">
          <use filter="url(#e5ne6k2dsa)" xlinkHref="#byw5wzmvfb"></use>
          <use xlinkHref="#byw5wzmvfb"></use>
        </g>
        <g fill="#FFF" transform="translate(7 7)">
          <use filter="url(#orenhv3xuc)" xlinkHref="#xuarft34dd"></use>
          <use xlinkHref="#xuarft34dd"></use>
        </g>
        <g fill="#FFF" transform="translate(7 7)">
          <use filter="url(#bky5oi866e)" xlinkHref="#gwkr5799of"></use>
          <use xlinkHref="#gwkr5799of"></use>
        </g>
        <g fill="#FFF" transform="translate(7 7)">
          <use filter="url(#8ittbfbw0g)" xlinkHref="#6xh8k8vt4h"></use>
          <use xlinkHref="#6xh8k8vt4h"></use>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
