import { Col, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import data from "./data.json";
import "./style.css";
var pt = require("apexcharts/dist/locales/pt.json");

const GraphicDistributionConsumption = ({ dateStart, dateEnd }) => {
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [categorys, setCategorys] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    setError(false);
    if (!totalConsumptionPrevious) await fetchTotalConsumptionPrevious();
    await fetchTotalConsumptionPeriod();
    setLoading(false);
  };

  const [totalConsumptionPrevious, setTotalConsumptionPrevious] = useState(0);
  const [totalConsumptionPeriod, setTotalConsumptionPeriod] = useState(0);

  const fetchTotalConsumptionPrevious = () => {
    api
      .get("/lighting/lightFixture/total/consumption/previous")
      .then((response) => {
        setTotalConsumptionPrevious(response.data.count);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      });
  };

  const fetchTotalConsumptionPeriod = () => {
    api
      .post("/assets/telemetry/count/consumption/period/chart", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
      })
      .then((response) => {
        setTotalConsumptionPeriod(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      });
  };

  const handleTotalConsumptionPeriod = () => {
    if (totalConsumptionPeriod)
      setSeries(generateSeries(totalConsumptionPeriod));
  };

  const generateSeries = (_data = []) => {
    let dates = [];
    let current = [];
    _data.forEach((element) => {
      if (!dates.includes(element.date)) dates.push(element.date);
      current.push(element);
    });
    dates.sort(function (a, b) {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    });
    setCategorys(dates);
    let total = totalConsumptionPrevious / 365;
    return [
      {
        name: "Atual",
        data: dates?.map((_date) =>
          (
            current.find((_current) => _current.date === _date)?.count || 0
          ).toFixed(2)
        ),
      },
      {
        name: "Anterior",
        data: dates?.map((_date) => {
          return total.toFixed(2);
        }),
      },
    ];
  };

  useEffect(() => {
    handleTotalConsumptionPeriod();
  }, [totalConsumptionPeriod, totalConsumptionPrevious]);

  useEffect(() => {
    handleTotalConsumptionPeriod();
    fetchData();
  }, [dateStart, dateEnd]);

  return (
    <>
      <Col xs={24}>
        <span>Gráfico de distribuição de consumo</span>
      </Col>

      {loading ? (
        <Loading />
      ) : error ? (
        <WidgetError message={`${error}`} refresh={() => fetchData()} />
      ) : (
        <div className="graphic_content">
          <Row gutter={16}>
            <Col xs={24}>
              <div id="chart-ticket-stats" className="graphic_chart">
                <Chart
                  options={{
                    ...data.options,
                    yaxis: {
                      show: false,
                      labels: {
                        formatter: function (value) {
                          return (
                            (value)?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 4,
                            }) + " kW"
                          );
                        },
                      },
                    },
                    xaxis: {
                      type: "datetime",
                      categories: categorys,
                      show: false,
                      labels: {
                        show: false,
                      },
                    },
                    chart: {
                      locales: [pt],
                      defaultLocale: "pt",
                      toolbar: { show: false },
                    },
                  }}
                  series={series}
                  type="line"
                  height="230"
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default GraphicDistributionConsumption;
