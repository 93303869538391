import React from "react";
import { Col, Row } from "antd";
import "./style.css";
import euroIcon from "../../../../assets/icons/buildings/euro.svg";

const visitorCost = () => {

    return (
        <>
            <span>
                Custos
            </span>
            <div className="buildingWidget">
                <Row>
                    <Col xs={5} style={{ paddingTop: "1.5em" }} >
                        <img src={euroIcon}></img>
                    </Col>
                    <Col xs={8}>
                        <div className="text">
                            Total
                        </div>
                        <div className="text">
                            Visitante
                        </div>
                    </Col>
                    <Col xs={11}>
                        <div className="divLine">
                            <div className="text">
                                146,57 €
                            </div>
                            <div className="text">
                                0,97 €
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>)
        ;
}

export default visitorCost;