import React from "react";

function Icon({ station, temperatura, time,weatherTypePT }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      viewBox="0 0 349 131"
    >
      <defs>
        <filter
          id="nszo8fuqja"
          width="222.5%"
          height="322.7%"
          x="-61.2%"
          y="-106.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="ws7hufexrc"
          width="140%"
          height="304.2%"
          x="-20%"
          y="-97.9%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="htxghmrh7e"
          width="162.8%"
          height="202.1%"
          x="-31.4%"
          y="-49%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="645gozsk0g"
          width="140.6%"
          height="322.7%"
          x="-20.3%"
          y="-106.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <text
          id="r8duuvtkwb"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="18"
          fontWeight="bold"
        >
          <tspan x="286" y="104">
            {time}
          </tspan>
        </text>
        <text
          id="9z58nqtozd"
          fill="#FFF"
          fontFamily="JosefinSans-Light, Josefin Sans"
          fontSize="20"
          fontWeight="300"
        >
          <tspan x="10" y="71">
           {weatherTypePT}
          </tspan>
        </text>
        <text
          id="50ohiyuhpf"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="40"
          fontWeight="bold"
        >
          <tspan x="10" y="48">
            {temperatura}
          </tspan>
        </text>
        <text
          id="5g0958fk6h"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="18"
          fontWeight="bold"
        >
          <tspan x="9.882" y="104">
            {station}
          </tspan>
        </text>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#083A5E"
          d="M27.511 5H321.49c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 15.793 342 18.38 342 25.511v73.846c0 7.132-.743 9.718-2.137 12.325a14.538 14.538 0 01-6.048 6.049c-2.608 1.394-5.194 2.137-12.326 2.137H27.51c-7.132 0-9.718-.743-12.326-2.137a14.538 14.538 0 01-6.048-6.049C7.743 109.075 7 106.49 7 99.357V25.51c0-7.132.743-9.718 2.137-12.326a14.538 14.538 0 016.048-6.048C17.793 5.743 20.38 5 27.511 5z"
        ></path>
        <path
          fill="#0F506C"
          d="M321.489 5c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 15.793 342 18.38 342 25.511v73.846c0 6.505-.618 9.228-1.785 11.635-1.66 2.072-3.4 4.083-5.21 6.032-.382.25-.78.487-1.19.707-2.608 1.394-5.194 2.137-12.326 2.137l-155.757.002C146.07 100.133 134 73.415 134 44a105.53 105.53 0 017.438-39.002L321.488 5z"
        ></path>
        <path
          fill="#146C7E"
          d="M306.291 5C317.698 14.164 325 28.23 325 44c0 27.614-22.386 50-50 50s-50-22.386-50-50c0-15.77 7.302-29.836 18.709-39z"
        ></path>
        <path
          fill="#EBD790"
          d="M324.577 85.022c.937 0 1.844.052 2.72.153H342v17.27c0 3.562-.749 6.685-2.142 9.266a14.674 14.674 0 01-6.014 6.014c-2.582 1.394-5.705 2.143-9.267 2.143H24.423c-3.562 0-6.685-.749-9.267-2.143a14.674 14.674 0 01-6.014-6.014C7.75 109.131 7 106.007 7 102.445l.004-.424H7V85.177h14.704a23.524 23.524 0 012.719-.154h300.154zM287.05 17C297.045 21.516 304 31.56 304 43.227 304 59.117 291.097 72 275.18 72c-11.609 0-21.615-6.853-26.18-16.729a26.116 26.116 0 0010.17 2.06c15.916 0 28.82-14.397 28.82-32.158 0-2.664-.291-5.252-.838-7.728z"
        ></path>
        <g fill="#FFF" transform="translate(7 5)">
          <use filter="url(#nszo8fuqja)" xlinkHref="#r8duuvtkwb"></use>
          <use xlinkHref="#r8duuvtkwb"></use>
        </g>
        <g fill="#FFF" transform="translate(7 5)">
          <use filter="url(#ws7hufexrc)" xlinkHref="#9z58nqtozd"></use>
          <use xlinkHref="#9z58nqtozd"></use>
        </g>
        <g fill="#FFF" transform="translate(7 5)">
          <use filter="url(#htxghmrh7e)" xlinkHref="#50ohiyuhpf"></use>
          <use xlinkHref="#50ohiyuhpf"></use>
        </g>
        <g fill="#FFF" transform="translate(7 5)">
          <use filter="url(#645gozsk0g)" xlinkHref="#5g0958fk6h"></use>
          <use xlinkHref="#5g0958fk6h"></use>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
