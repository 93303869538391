import { Col, Row } from "antd";
import React from "react";
import FireRisk from "./fireRisk";
import Practiced from "./practiced";
import Precipitation from "./precipitation";
import Prediction from "./prediction";
import Week from "./week";
const Meteorology = () => {
    return (
        <>
            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col xs={24} lg={12}>
                    <Week />
                </Col>
                <Col xs={24} lg={12}>
                    <FireRisk />
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col xs={24} lg={12}>
                    <Precipitation />
                </Col>
                <Col xs={24} lg={12}>
                    <Prediction />
                </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col xs={24}>
                    <Practiced />
                </Col>
            </Row>
        </>
    );
}

export default Meteorology