import { Select } from "antd";
import React, { useState } from "react";
import pin from "../../../assets/icons/pin.svg";
import parks from "./parks.json";
import "./style.css";
import Widget from "./widget";
import WidgetOccupations from "./widgetOccupations";

const { Option } = Select;

const Statistics = () => {

    const [parkSelected, setParkSelected] = useState(parks[0].id);

    return (
        <div className="contentParkWidget">
            <img
                src={pin}
                style={{ height: "1em" }} />
                
            <Select
                value={parkSelected}
                onChange={(newValue) => setParkSelected(newValue)}
                className="selectPark">
                {parks.map((park) => <Option value={park.id}>{park.name}</Option>)}
            </Select>

            <Widget
                icon={"total_vagas.svg"}
                title={"Total de vagas no parque"}
                value={"1079 Vagas"} />

            <Widget
                icon={"total_vagas_ocupadas.svg"}
                title={"Total de vagas ocupadas"}
                value={"54 Vagas"} />

            <Widget
                icon={"total_vagas_livres.svg"}
                title={"Total de vagas livres"}
                value={"1025 Vagas"} />

            <Widget
                icon={"ocupacao_vaga_especial.svg"}
                title={"Ocupação de vagas especiais"}
                value={"23 Vagas"} />

            <Widget
                icon={"tempo_media_permanecia.svg"}
                title={"Tempo médio de permanência"}
                value={"2h 12m"} />

            <Widget
                icon={"rotatividade_vagas.svg"}
                title={"Rotatividade das vagas"}
                value={"47%"} />

            <WidgetOccupations/>
        </div>
    );
}

export default Statistics;