import React from "react";

function NightWithRain({ station, temperatura, time, weatherTypePT }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      viewBox="0 0 349 135"
    >
      <defs>
        <filter
          id="58tqlfsdoa"
          width="128.8%"
          height="304.2%"
          x="-14.4%"
          y="-97.9%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="yq4nm6c3rc"
          width="162.8%"
          height="202.1%"
          x="-31.4%"
          y="-49%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="bl92unrrne"
          width="222.5%"
          height="322.7%"
          x="-61.2%"
          y="-106.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="2r09tqv5ng"
          width="140.6%"
          height="322.7%"
          x="-20.3%"
          y="-106.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <text
          id="ixslhl05vb"
          fill="#FFF"
          fontFamily="JosefinSans-Light, Josefin Sans"
          fontSize="20"
          fontWeight="300"
        >
          <tspan x="10" y="69">
            {weatherTypePT}
          </tspan>
        </text>
        <text
          id="2mu7pfo6md"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="40"
          fontWeight="bold"
        >
          <tspan x="10" y="44">
            {temperatura}
          </tspan>
        </text>
        <text
          id="rok300494f"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="18"
          fontWeight="bold"
        >
          <tspan x="286" y="104">
            {time}
          </tspan>
        </text>
        <text
          id="hyd2xtvi6h"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="18"
          fontWeight="bold"
        >
          <tspan x="9.882" y="104">
            {station}
          </tspan>
        </text>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#083A5E"
          d="M27.511 9H321.49c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 19.793 342 22.38 342 29.511v73.846c0 7.132-.743 9.718-2.137 12.325a14.538 14.538 0 01-6.048 6.049c-2.608 1.394-5.194 2.137-12.326 2.137H27.51c-7.132 0-9.718-.743-12.326-2.137a14.538 14.538 0 01-6.048-6.049C7.743 113.075 7 110.49 7 103.357V29.51c0-7.132.743-9.718 2.137-12.326a14.538 14.538 0 016.048-6.048C17.793 9.743 20.38 9 27.511 9z"
        ></path>
        <path
          stroke="#FFF"
          strokeLinecap="square"
          strokeWidth="3"
          d="M323 55l-18 24"
        ></path>
        <path
          fill="#0F506C"
          d="M321.489 9c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 19.793 342 22.38 342 29.511V42H144.997C131.192 42 120 30.809 120 16.993V9h201.489z"
        ></path>
        <path
          fill="#146C7E"
          d="M327.871 22a19.945 19.945 0 0114.13 5.841V56.16A19.943 19.943 0 01327.87 62H187.005C175.956 62 167 53.048 167 42c0-11.046 8.954-20 20.005-20H327.87z"
        ></path>
        <path
          stroke="#FFF"
          strokeLinecap="square"
          strokeWidth="3"
          d="M212 43l-18 24m36 12l-18 24m-48-20l-18 24"
        ></path>
        <path
          stroke="#D5D5D5"
          strokeLinecap="square"
          strokeWidth="2"
          d="M167 36l-18 24m136 7l-18 24"
        ></path>
        <path
          fill="#EBD790"
          d="M324.577 89.022c.937 0 1.844.052 2.72.153H342v17.27c0 3.562-.749 6.685-2.142 9.266a14.674 14.674 0 01-6.014 6.014c-2.582 1.394-5.705 2.143-9.267 2.143H24.423c-3.562 0-6.685-.749-9.267-2.143a14.674 14.674 0 01-6.014-6.014C7.75 113.131 7 110.007 7 106.445l.004-.424H7V89.177h14.704a23.524 23.524 0 012.719-.154h300.154zM297.51 18C308.412 22.927 316 33.885 316 46.611 316 63.947 301.924 78 284.56 78c-12.664 0-23.58-7.476-28.56-18.25a28.484 28.484 0 0011.094 2.248c17.364 0 31.44-15.707 31.44-35.082 0-2.906-.317-5.73-.914-8.43z"
        ></path>
        <g fill="#FFF" transform="translate(7 9)">
          <use filter="url(#58tqlfsdoa)" xlinkHref="#ixslhl05vb"></use>
          <use xlinkHref="#ixslhl05vb"></use>
        </g>
        <g fill="#FFF" transform="translate(7 9)">
          <use filter="url(#yq4nm6c3rc)" xlinkHref="#2mu7pfo6md"></use>
          <use xlinkHref="#2mu7pfo6md"></use>
        </g>
        <g fill="#FFF" transform="translate(7 9)">
          <use filter="url(#bl92unrrne)" xlinkHref="#rok300494f"></use>
          <use xlinkHref="#rok300494f"></use>
        </g>
        <g fill="#FFF" transform="translate(7 9)">
          <use filter="url(#2r09tqv5ng)" xlinkHref="#hyd2xtvi6h"></use>
          <use xlinkHref="#hyd2xtvi6h"></use>
        </g>
      </g>
    </svg>
  );
}

export default NightWithRain;
