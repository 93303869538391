import { Col, Row } from "antd";
import React, { useState } from "react";
import "./style.css";
import Info from "./info";

const Widget = () => {
    return (
        <>
            <span>
                Estacionamento
            </span>
            <div className="parkingWidget">
                <div className="content">
                    <Row gutter={16}>
                        <Col xs={24} md={6}>
                            <Info value={"435"} description={"Total de vagas"} />
                        </Col>
                        <Col xs={24} md={6}>
                            <Info value={"23"} description={"Vagas ocupadas (últimas 48h)"} />
                        </Col>
                        <Col xs={24} md={6}>
                            <Info value={"50 min"} description={"Tempo na vaga (últimas 48h)"} />
                        </Col>
                        <Col xs={24} md={6}>
                            <Info value={"31%"} description={"Rotatividade vaga (últimas 48h)"} />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default Widget;