import React from "react";
import Chart from "react-apexcharts";
import data from "./data.json";
import "./style.css";

var pt = require("apexcharts/dist/locales/pt.json");

const ChartOccupation = () => {

    return (
        <>
            <span>Gráfico de horário de ocupação (vagas) por %</span>
            <div className="parking_chart_content">
                <Chart
                    options={{
                        ...data.options,
                        chart: {
                            locales: [pt],
                            defaultLocale: "pt",
                            toolbar: { show: false },
                        },
                    }}
                    series={data.series}
                    type="area"
                    height="230"
                />
            </div>
        </>
    );
}

export default ChartOccupation;