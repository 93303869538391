import { Col, Row } from "antd";
import React from "react";
import "./style.css";

const Widget = ({ icon, title, value, unity, tooltip }) => {
    return (
        <div className="solarWidget">
            <Row>
                <Col xs={24} md={4} style={{ textAlign: "center" }}>
                    <img alt="" src={require(`../../../../assets/icons/solar/${icon}`)} />
                </Col>
                <Col xs={24} md={12}>
                    <span className="wasteFleetsDescription">
                        {title}
                    </span>
                </Col>
                <Col xs={24} md={8} >
                    <span className="solarWidgetValue" title={tooltip}>
                        {value} <span style={{ fontSize: "65%" }}>{unity}</span>
                    </span>
                </Col>
            </Row>
        </div>
    );
}
export default Widget;