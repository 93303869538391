import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import "./style.css";
import Widget from "./widget";
import WidgetError from "../../../components/error";
import { Col } from "antd";
import { currency, number } from "../../../util/Format";

const GeneralInfo = ({ dateStart, dateEnd }) => {

  const [totalCost, setTotalCost] = useState(0);
  const [realSavings, setRealSavings] = useState(0);
  const [totalConsumptionPrevious, setTotalConsumptionPrevious] = useState(0);
  const [totalConsumptionPreviousTemp, setTotalConsumptionPreviousTemp] = useState(0);
  const [totalConsumptionPeriod, setTotalConsumptionPeriod] = useState(0);

  const [tariff, setTariff] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchTariff = async () => {
    await api
      .get("/lighting/tariff/find/last", {})
      .then((response) => {
        setTariff(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      });
  };

  const fetchTotalConsumptionPrevious = async () => {
    await api
      .get("/lighting/lightFixture/total/consumption/previous")
      .then((response) => {
        setTotalConsumptionPreviousTemp(response.data.count);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      });
  };

  const fetchTotalConsumptionPeriod = async () => {
    await api
      .post("/assets/telemetry/count/consumption/period", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
      })
      .then((response) => {
        setTotalConsumptionPeriod(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      });
  };

  const handleTotalCost = () => {
    setTotalCost(
      ((totalConsumptionPeriod.count || 0) * (tariff?.tariff || 0))
    );
  };

  const handleRealSavings = () => {
    setRealSavings(
      (
        (totalConsumptionPreviousTemp - (totalConsumptionPeriod.count)) * tariff?.tariff
      )
    );
  };

  const handleTotalConsumptionPrevious = () => {
    let start = new Date(dateStart);
    let end = new Date(dateEnd);
    let decorrido = (end - start) / 86400;
    if (decorrido < 2000)
      setTotalConsumptionPrevious(
        (totalConsumptionPreviousTemp / 365)
      );
    else if (decorrido > 2000 && decorrido < 8000)
      setTotalConsumptionPrevious(
        (totalConsumptionPreviousTemp / 52)
      );
    else if (decorrido > 10000)
      setTotalConsumptionPrevious(
        (totalConsumptionPreviousTemp / 12)
      );
    else setTotalConsumptionPrevious(totalConsumptionPreviousTemp);
  };

  useEffect(() => {
    fetchTariff();
    fetchTotalConsumptionPrevious();
  }, []);

  useEffect(() => {
    setError(false)
    setLoading(true);
    fetchTotalConsumptionPeriod();
    handleTotalConsumptionPrevious();
    setLoading(false);
  }, [dateStart, dateEnd, totalConsumptionPreviousTemp]);

  useEffect(() => {
    handleTotalCost();
    handleRealSavings();
  }, [totalConsumptionPeriod]);

  const refresh = () => {
    setError(false)
    setLoading(true);
    fetchTariff();
    fetchTotalConsumptionPeriod();
    fetchTotalConsumptionPrevious();
    handleTotalConsumptionPrevious();
    handleTotalCost();
    handleRealSavings();
    setLoading(false);
  };

  return (
    <>
      <Col xs={24}>
        <span>Informação geral</span>
      </Col>
      {loading ? (
        <Loading />
      ) : error ? (
        <WidgetError
          message={`${error}`}
          refresh={() => refresh()}
        ></WidgetError>
      ) : (
        <div className="streetLighingGeneralInfoContent">
          <Widget
            icon="total_consumido_periodo_atual.svg"
            title="Total de kW consumidos do período em análise"
            value={`${number((totalConsumptionPeriod.count || 0))} kW`}
          />
          <Widget
            icon="total_consumido_periodo_anterior.svg"
            title="Total de kW consumidos anterior ao contrato"
            value={`${number(totalConsumptionPrevious)} kW`}
          />
          <Widget
            icon="custo_ip.svg"
            title="Custo total com IP em €"
            value={`${currency(totalCost)}`}
          />
          <Widget
            icon="poupanca_ip.svg"
            title="Poupança real com IP em €"
            value={`${currency(realSavings)}`}
          />
          <Widget
            icon="preco_atual_kw.svg"
            title="Preço atual kW"
            value={`${currency(tariff?.tariff)}/ kW`}
          />
        </div>
      )}
    </>
  );
};

export default GeneralInfo;
