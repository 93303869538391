import { Col, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import data from "./data.json";
import "./style.css";

var pt = require("apexcharts/dist/locales/pt.json");

const SuppliedEnergy = ({ dateStart, dateEnd }) => {

    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [graphiEnergy, setGraphiEnergy] = useState({
        series: [],
        avg: { value: 0 },
        total: { value: 0 },
        sessionTotal: { value: 0 }
    });



    const fetchGraphi = () => {
        setLoading(true);
        setError(undefined);
        api
            .post("/chargers/sessions/graphi/energy", {
                dateStart: dateStart ? moment(dateStart).utc() : undefined,
                dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
            })
            .then((response) => {
                setGraphiEnergy(response.data);
            })
            .catch((error) => {
                setError(
                    error?.response?.data?.message || "unknown error"
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    function format(value, decimal) {
        return Number(value).toLocaleString(
            undefined,
            { minimumFractionDigits: decimal }
        );
    }

    const generateSeries = (_data = []) => {
        let _categories = [];
        _data.forEach((element) => {
            _categories.push(`${element.date} ${element.date > 0 && element.date < 24 ? 'h' : ''}`);
        });
        setCategories(_categories);
        return [
            {
                name: "kWh",
                data: _data?.map((item) => {
                    return format(item.value / 1000, 2);
                }),
            },
        ];
    };


    useEffect(() => {
        setSeries(generateSeries(graphiEnergy.series))
    }, [graphiEnergy]);


    useEffect(fetchGraphi, [dateStart, dateEnd]);


    return (<>
        {loading ? (
            <Loading />
        ) : error ? (
            <WidgetError
                message={`${error}`}
                refresh={() => fetchGraphi()}
            />
        ) : (<div className="electric_mobility_supplied_energy_widget">

            <span>
                Energia Fornecida (em kWh)
            </span>

            <Chart options={{
                ...data.options,
                xaxis: {
                    show: false,
                    labels: {
                        show: false,
                    },
                    type: 'string',
                    categories: categories,

                },
                chart: {
                    locales: [pt],
                    defaultLocale: "pt",
                    toolbar: { show: false },
                },
            }}
                series={series}
                type="area"
                height={150} />

            <Row className="row_statistics">
                <Col xs={12} className="left">
                    <p className="title">
                        {format(graphiEnergy.total.value / 1000, 2)} kWh
                    </p>
                    <span>
                        em {graphiEnergy.sessionTotal.value} sessões
                    </span>
                </Col>
                <Col xs={12} className="right">
                    <p className="title">
                        {format(graphiEnergy.avg.value / 1000, 2)} kWh
                    </p>
                    <span>
                        média por sessão
                    </span>
                </Col>
            </Row>

        </div>)}</>


    );
}

export default SuppliedEnergy;