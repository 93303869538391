import 'moment/locale/pt';
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import api from "../../../services/api";
import data from "../data.json";

var pt = require("apexcharts/dist/locales/pt.json");

const Prediction = () => {

    const [series, setSeries] = useState([])
    const [categories, setCategories] = useState([])

    const fetchsForecastWeather = () => api.get('/meteorology/forecast/weather').then((response) => setSeries(generateSeries(response.data || [])));

    const generateSeries = (_data) => {
        let forecastDate = [];
        _data.forEach((element) => {
            if (!forecastDate.includes(element.forecastDate))
                forecastDate.push(element.forecastDate);
        });
        setCategories(forecastDate)
        return [
            {
                name: "Max",
                data: forecastDate.map((_date) => _data.find((max) => max.forecastDate === _date)?.tMax || 0),
            },
            {
                name: "Min",
                data: forecastDate.map((_date) => _data.find((min) => min.forecastDate === _date)?.tMin || 0),
            }
        ];
    };

    useEffect(() => { fetchsForecastWeather() }, [])

    return (<>
        <span>Temperatura prevista para semana</span>
        <div className="contentParkWidget" style={{ marginTop: "0px" }}>
            <Chart
                options={{
                    ...data.options,
                    yaxis: {
                        labels: {
                            formatter: function (val, index) {
                                return `${val.toLocaleString()}°`;
                            }
                        }
                    },
                    xaxis: {
                        ...data.options.xaxis,
                        type: "datetime",
                        categories: categories,
                        labels: {
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: 'MMM \'yy',
                                day: 'dddd, dd/MMM',
                                hour: 'HH:mm'
                            }
                        }
                    },
                    chart: {
                        locales: [pt],
                        defaultLocale: "pt",
                        toolbar: { show: false }
                    },
                }}
                series={series}
                type="line"
                height={290}
                width="100%" />
        </div>
    </>)
}

export default Prediction