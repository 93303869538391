import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import iconCompleted from "../../../../assets/icons/hydrogen/completed.png";
import iconForced from "../../../../assets/icons/hydrogen/forced.png";
import syncIcon from "../../../../assets/icons/sync.svg";
import WidgetError from "../../../../components/error";
import Loading from "../../../../components/loading";
import api from "../../../../services/api";
import "./style.css";

const SupplyTable = ({ dateStart, dateEnd }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [rowPerPage, setRowPerPage] = useState(5);
  const [pagination, setPagination] = useState({});

  const fetchSupplys = () => {
    setLoading(true);
    setError(undefined);
    api
      .post("/hydrogen/supply/find/date", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
        rowPerPage: rowPerPage,
      })
      .then((response) => {
        let { data } = response;
        setPagination(data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function milisecondToTime(sec) {
    var dateObj = new Date(sec * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var timeString =
      hours.toString().padStart(2, "0") +
      "h " +
      minutes.toString().padStart(2, "0") +
      "m";
    return timeString;
  }

  useEffect(fetchSupplys, [dateStart, dateEnd, rowPerPage]);

  return (
    <div className="hydrogen">
      <span>Detalhe dos abastecimentos</span>
      {loading ? (
        <Loading />
      ) : error ? (
        <WidgetError
          message={`${error}`}
          refresh={() => fetchSupplys()}
        />
      ) : (<div className="supplyTable">
        <table>
          <tr>
            <th>Data</th>
            <th>Abasteciamento</th>
            <th>Duração</th>
            <th>Tipo</th>
            <th>Tipo de paragem</th>
          </tr>

          {!loading && pagination?.empty && (
            <tr>
              <td colSpan={5} style={{ textAlign: "center " }}>Sem dados</td>
            </tr>
          )}

          {pagination?.content?.map((supply) => (
            <tr>
              <td>{moment.utc(supply.start).local().format('D/M/Y HH:mm')}</td>
              <td>{`${supply.mass?.toLocaleString(
                undefined,
                { minimumFractionDigits: 0 }
              )} kg`}</td>
              <td>{milisecondToTime(supply?.duration)}</td>
              <td>{supply.vehicleType}</td>
              <td style={{ textAlign: "center" }} title={supply.stopType}>
                {supply.stopType === "StopButton" ? (
                  <img src={iconForced} alt="" title={supply.stopType} />
                ) : (
                  <img src={iconCompleted} alt="" title={supply.stopType} />
                )}
              </td>
            </tr>
          ))}

          {loading && (
            <tr>
              <td colSpan={5}>
                <Loading />
              </td>
            </tr>
          )}

          {!loading && !pagination?.last &&
            <tr>
              <td colSpan={5}>
                <div
                  class="btn-carregarmais"
                  style={{ cursor: "pointer", marginTop: "1em", textAlign: "center" }}
                  onClick={() =>
                    setRowPerPage(parseInt(rowPerPage + rowPerPage * 0.5))
                  }
                >
                  <img src={syncIcon} alt=""></img>
                  <br />
                  <span>Carregar mais</span>
                  <br></br>
                </div>
              </td>
            </tr>
          }
        </table>

      </div>)}


    </div>
  );
};

export default SupplyTable;
