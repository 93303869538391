import { Col, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import Widget from "../../home/light_fixture/widget";

const Carrocell = ({ dateStart, dateEnd }) => {

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 250, itemsToShow: 2, itemsToScroll: 2 },
    { width: 350, itemsToShow: 3, itemsToScroll: 3 },
    { width: 450, itemsToShow: 4, itemsToScroll: 4 },
    { width: 550, itemsToShow: 5, itemsToScroll: 5 },
    { width: 700, itemsToShow: 6, itemsToScroll: 6 },
    { width: 800, itemsToShow: 7, itemsToScroll: 7 },
    { width: 900, itemsToShow: 8, itemsToScroll: 8 },
  ];

  const [lights, setLightingModels] = useState([]);
  const [lightingModelsLoad, setLightingModelsLoad] = useState(false);
  const [lightingModelsError, setLightingModelsError] = useState(false);
  const [inputsModels, setInputModels] = useState([]);
  const [inputModelRender, setInputModelRender] = useState(false);

  const fetchInputModels = () => {
    api.get('/lighting/inputmodel').then((response) => {
      if (response.data.length > 0)
        setInputModels(response.data.filter(i => i.component === 'SELECT_OPTIONS'))
      else
        setInputModels([])
    }).finally(() => {
      setInputModelRender(true);
    })
  };

  const fetchLightingModels = (model) => {
    setLightingModelsLoad(true);
    setLightingModelsError(false);
    api
      .post("/lighting/lightFixture/count/models", {
        dateStart: undefined,
        dateEnd: undefined,
        model: model ? model : 'model'
      })
      .then((response) => {
        setLightingModels(response.data);
      })
      .catch((error) => {
        setLightingModelsError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setLightingModelsLoad(false);
      });
  };

  useEffect(() => {
    fetchLightingModels("model")
    fetchInputModels()
  }, []);

  useEffect(() => {
    if (inputsModels.length > 0)
      fetchLightingModels(inputsModels[0].name)
  }, [inputModelRender]);

  return (
    <>
      <Row gutter={16}>
        {inputModelRender &&
          <Col xs={24}>
            <div className="select-carrocel">
              <Select
                key="Select"
                mode="single"
                allowClear
                showSearch
                style={{ width: '100%' }}
                placeholder="Selecione um atributo"
                defaultValue={inputsModels.length > 0 ? inputsModels[0].label : ""}
                onChange={(value) => fetchLightingModels(value)}
              >
                {(inputsModels || []).map((i, index) => <Select.Option key={index + i.name} value={i.name}>{i.label}</Select.Option>)}
              </Select>
            </div>
          </Col>
        }
      </Row>
      <Row gutter={16}>
        <Col xs={24}>
          {lightingModelsError ? (
            <WidgetError
              message={`${lightingModelsError}`}
              refresh={() => fetchLightingModels()}
            />
          ) : (
            <div className="lightFixtureGadgetRoot">
              {lightingModelsLoad ? (
                <Loading />
              ) : lights.length > 0 ? (
                <>

                  <Carousel
                    showArrows={false}
                    breakPoints={breakPoints}
                    enableAutoPlay
                    autoPlaySpeed={4000}
                  >
                    {lights?.map((light, index) => (
                      <Widget key={light.model + index} count={light?.count} model={light?.model}></Widget>
                    ))}
                  </Carousel></>

              ) : (
                <div className="no-data">
                  <span>Sem dados</span>
                </div>
              )}
            </div>
          )}
        </Col>
      </Row></>

  );
};

export default Carrocell;
