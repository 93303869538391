import React from "react";
import "./style.css";

const Widget = ({ count, model }) => {
  return (

    <div className="lightfixture_widget">
      <div>
        <b>{count}</b>
        <p style={{ color: "#B1B1B1" }}>{model}</p>
      </div>
    </div>


  );
};

export default Widget;
