import { Col, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import data from "./data.json";
import "./style.css";

var pt = require("apexcharts/dist/locales/pt.json");

const LoadAverage = ({ dateStart, dateEnd }) => {

    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [graphi, setGraphi] = useState({
        series: [],
        avg: { value: 0 },
        total: { value: 0 },
        sessionTotal: { value: 0 }
    });


    const fetchGraphi = () => {
        setLoading(true);
        setError(undefined);
        api
            .post("/chargers/sessions/graphi/duration", {
                dateStart: dateStart ? moment(dateStart).utc() : undefined,
                dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
            })
            .then((response) => {
                setGraphi(response.data);
            })
            .catch((error) => {
                setError(
                    error?.response?.data?.message || "unknown error"
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const generateSeries = (_data = []) => {
        let _categories = [];
        _data.forEach((element) => {
            _categories.push(`${element.date} ${element.date > 0 && element.date < 24 ? 'h' : ''}`);
        });
        setCategories(_categories);
        return [
            {
                name: "Minutos",
                data: _data?.map((item) => {
                    return ((item.value / 1000) / 60).toFixed(0);
                }),
            },
        ];
    };


    function milisecondToTime(milissegundo) {
        if (milissegundo !== undefined && milissegundo != null) {
            var ms = milissegundo % 1000;
            milissegundo = (milissegundo - ms) / 1000;
            var secs = milissegundo % 60;
            milissegundo = (milissegundo - secs) / 60;
            var mins = milissegundo % 60;
            var hrs = (milissegundo - mins) / 60;
            return hrs + ' h  ' + mins + 'm'
        }
        return 0;
    }

    useEffect(() => {
        setSeries(generateSeries(graphi.series))
    }, [graphi]);


    useEffect(fetchGraphi, [dateStart, dateEnd]);


    return (<>  {loading ? (
        <Loading />
    ) : error ? (
        <WidgetError
            message={`${error}`}
            refresh={() => fetchGraphi()}
        />
    ) : (<div className="electric_mobility_load_average">
        <span>
            Média de carregamentos
        </span>

        <Chart options={{
            ...data.options,
            xaxis: {
                show: false,
                labels: {
                    show: false,
                },
                type: "string",
                categories: categories,
            },
            chart: {
                locales: [pt],
                defaultLocale: "pt",
                toolbar: { show: false },
            },
        }}
            series={series}
            type="area"
            height={150} />

        <Row className="row_statistics">
            <Col xs={12} className="left">
                <p className="title">
                    {milisecondToTime(graphi.total.value)}
                </p>
                <span>
                    em {graphi.sessionTotal.value} sessões
                </span>
            </Col>
            <Col xs={12} className="right">
                <p className="title">
                    {milisecondToTime(graphi.avg.value)}
                </p>
                <span>
                    média por sessão
                </span>
            </Col>
        </Row>
    </div>)}</>
    );
}

export default LoadAverage;