import React from "react";
import "./style.css";

const LoadingTime = ({ value }) => {
    
    return (
        <div className="eletricMobilityWidget loadTime">
            <h2>{value}</h2>
            <span>
                Tempo de carregamento
            </span>
        </div>
    );
}

export default LoadingTime;