
import { Col, Empty, Row } from "antd"
import React, { useEffect, useState } from "react"
import Chart from "react-apexcharts"
import api from "../../../services/api"
import dataChart from "../data.json"


const Gender = ({ localSelected, polygon }) => {

  const [series, setSeries] = useState([])
  const categories = ["65 anos", "25 aos 64", "20 aos 24", "14 aos 19", "10 aos 13", "5 aos 9", "0 aos 4"]

  const properties = [
    "n_individuos_resident_m_0a4",
    "n_individuos_resident_m_5a9",
    "n_individuos_resident_m_10a13",
    "n_individuos_resident_m_14a19",
    "n_individuos_resident_m_20a24",
    "n_individuos_resident_m_25a64",
    "n_individuos_resident_m_65",
    "n_individuos_resident_h_0a4",
    "n_individuos_resident_h_5a9",
    "n_individuos_resident_h_10a13",
    "n_individuos_resident_h_14a19",
    "n_individuos_resident_h_20a24",
    "n_individuos_resident_h_25a64",
    "n_individuos_resident_h_65",
  ]

  function fetchData(localSelected) {
    api.get("geoservices/features/group/count/properties", {
      params: {
        local: localSelected.local ? localSelected.local : undefined,
        bgri: localSelected.bgri ? localSelected.bgri : undefined,
        search: localSelected.search ? localSelected.search : undefined,
        properties: properties.toString()
      }
    }).then((response) => { handleSeries(response.data || []) })
  }

  function handleSeries(_data) {
    let seriesH = []
    let seriesM = []
    _data.forEach(i => {
      switch (i.name) {
        case "n_individuos_resident_h_0a4":
          seriesH.push({ value: -i.value, index: 7 })
          break
        case "n_individuos_resident_h_5a9":
          seriesH.push({ value: -i.value, index: 6 })
          break
        case "n_individuos_resident_h_10a13":
          seriesH.push({ value: -i.value, index: 5 })
          break
        case "n_individuos_resident_h_14a19":
          seriesH.push({ value: -i.value, index: 4 })
          break
        case "n_individuos_resident_h_20a24":
          seriesH.push({ value: -i.value, index: 3 })
          break
        case "n_individuos_resident_h_25a64":
          seriesH.push({ value: -i.value, index: 2 })
          break
        case "n_individuos_resident_h_65":
          seriesH.push({ value: -i.value, index: 1 })
          break
        case "n_individuos_resident_m_0a4":
          seriesM.push({ value: i.value, index: 7 })
          break
        case "n_individuos_resident_m_5a9":
          seriesM.push({ value: i.value, index: 6 })
          break
        case "n_individuos_resident_m_10a13":
          seriesM.push({ value: i.value, index: 5 })
          break
        case "n_individuos_resident_m_14a19":
          seriesM.push({ value: i.value, index: 4 })
          break
        case "n_individuos_resident_m_20a24":
          seriesM.push({ value: i.value, index: 3 })
          break
        case "n_individuos_resident_m_25a64":
          seriesM.push({ value: i.value, index: 2 })
          break
        case "n_individuos_resident_m_65":
          seriesM.push({ value: i.value, index: 1 })
          break
        default:
      }
    })
    setSeries([
      { name: "Homens", data: seriesH.sort(function (a, b) { return a.index - b.index }).map(i => i.value) },
      { name: "Mulheres", data: seriesM.sort(function (a, b) { return a.index - b.index }).map(i => i.value) }
    ])
  }

  useEffect(() => {
    if (localSelected && (localSelected.local || localSelected.search))
      fetchData(localSelected)
  }, [localSelected])

  useEffect(() => {
    if (polygon && polygon.bgri)
      fetchData(polygon)
  }, [polygon])

  return (
    <>
      <span>
        Número de Indivíduos Residentes por Idade e Género
      </span>
      <div className="properties-content">
        {series.length ? <>        <Chart
          options={{
            ...dataChart.options,
            plotOptions: {
              bar: {
                horizontal: true,
                barHeight: '80%',
              }
            },
            chart: {
              type: 'bar',
              stacked: true,
              toolbar: {
                show: false
              },
            },
            grid: {
              xaxis: {
                lines: {
                  show: false
                }
              }
            },
            colors: ['#33CCCC', '#FF6478'],
            xaxis: {
              type: "category",
              categories: categories,
              labels: {
                formatter: function (val) {
                  return parseInt(val < 0 ? val * (-1) : val)
                }
              }
            },
            tooltip: {
              shared: false,
              x: {
                formatter: function (val) {
                  return val < 0 ? val * (-1) : val
                }
              },
              y: {
                formatter: function (val) {
                  return val < 0 ? val * (-1) : val
                }
              }
            },
          }}
          series={series}
          type="bar"
          stacked={true}
          height="90%"
          width="100%"
        />
          <Row >
            <Col xs={24} sm={12} >
              <div style={{ textAlign: "center", width: "150px", margin: "auto" }}>
                <table>
                  <tr>
                    <td><div style={{ width: "20px", height: "20px", backgroundColor: "#33CCCC" }}> </div></td>
                    <td>Homens</td>
                  </tr>
                </table>
              </div>
            </Col>
            <Col xs={24} sm={12} >
              <div style={{ textAlign: "center", width: "150px", margin: "auto" }}>
                <table>
                  <tr>
                    <td><div style={{ width: "20px", height: "20px", backgroundColor: "#FFA2B3" }}> </div></td>
                    <td>Mulheres</td>
                  </tr>
                </table>
              </div>
            </Col>
          </Row></> : <Empty description="Selecione uma opção no filtro"></Empty >}
      </div>
    </>
  )
}

export default Gender
