import { Col, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import noIcon from "../../../assets/icons/close.svg";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import "./style.css";

const Ticket = ({ dateStart, dateEnd }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tickets, setTickets] = useState([]);

  const searchTickets = () => {
    setLoading(true);
    setError(undefined);
    api
      .post("/helpdesk/ticket/search", {
        startTo: dateStart ? moment(dateStart).utc() : undefined,
        endTo: dateEnd ? moment(dateEnd).utc() : undefined,
        rowPerPage: 5,
      })
      .then((response) => {
        let { data } = response;
        setTickets(data.content);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(searchTickets, [dateStart, dateEnd]);

  return (
    <Row>
      <Col xs={24}>
        <span>Ocorrências</span>
      </Col>
      {error ? (
        <WidgetError message={`${error}`} refresh={() => searchTickets} />
      ) : (
        <div className="ticket_content">
          {loading ? (
            <Loading />
          ) : (
            <>
              <table>
                <tr>
                  <th>Estado</th>
                  <th>Ocorrência</th>
                  <th>Hora</th>
                  <th>Categoria</th>
                </tr>
                {tickets.map((element) => (
                  <tr>
                    <td>
                      <div
                        class="circle"
                        style={{ backgroundColor: element.status.color }}
                      ></div>
                    </td>
                    <td>
                      {(element.category || {}).icon && (
                        <img
                          alt=""
                          style={{maxWidth: "17px", maxHeight: "17px"}}
                          src={`${
                            process.env.REACT_APP_API_URL
                          }/storage/render/${element.category.icon.id}`}
                        />
                      )}
                      {!(element.category || {}).icon && (
                        <span>-</span>
                      )}
                    </td>
                    <td>
                      {moment.utc(element.creationDate).local().format("HH:mm")}
                    </td>
                    <td>
                      {(element.category || {}).name || "Não categorizado"}
                    </td>
                  </tr>
                ))}
                {Array.isArray(tickets) && tickets.length === 0 ? (
                  <tr>
                    <td colSpan={4}>
                      <span>Sem dados</span>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
              </table>
            </>
          )}
        </div>
      )}
    </Row>
  );
};

export default Ticket;
