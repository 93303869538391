import React, { useState } from "react";
import iconInfo from "../../../assets/icons/map_info.svg";
import "./style.css";

const Info = () => {

    return (
        <div className="infoContent">
            <img src={iconInfo} />
            <span>
                Informação do mapa
            </span>
        </div>
    );
}

export default Info;