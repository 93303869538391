import { Col, Row, Select } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import ChartDaily from "./chartDaily";
import ChartDate from "./chartDate";
import ChartHour from "./chartHour";
import Filters from "./filters";
import InfoChart from "./infoChart";
import InfoContent from "./infoMap";
import Map from "./map";

const Mobility = () => {

    const { Option } = Select;
    const [allSections, setAllSections] = useState();
    const [sections, setSections] = useState([]);
    const [ranges, setRanges] = useState([]);
    const [range, setRange] = useState({ id: 0 });
    const [hours, setHours] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]);

    const fetchSections = () => {
        api.get(`/mobility/statifyRegister/sections`).then((response) => {
            setAllSections(response.data);
        });
    }

    const fetchRanges = () => {
        api.get(`/mobility/ranges`).then((response) => {
            setRanges(response.data);
            setRange(response.data[0])
        });
    }

    useEffect(() => { fetchSections(); fetchRanges() }, []);

    return (
        <Row gutter={24}>
            {ranges && ranges.length > 0 && <> <Col xs={24} sm={12} ></Col>
                <Col xs={24} sm={12} >
                    <Select className="select_mobility"
                        allowClear
                        showSearch
                        value={range.id ? range.id : ""}
                        style={{ width: '100%', float: "right", border: 'none' }}
                        onChange={(v) => setRange(ranges.find(i => i.id === v))}
                        placeholder="Selecione aqui"
                    >
                        {ranges?.map((ele) => <Option key={ele.id} value={ele.id}>{`${ele.name} de ${moment(ele.range[0]).format("DD/MM/yyyy HH:mm")}  até ${moment(ele.range[1]).format("DD/MM/yyyy HH:mm")}`}</Option>)}
                    </Select>


                </Col></>}

            {!ranges || ranges.length === 0 && <Col xs={24}><div className="infoContent">
                <span>
                    Sem dados
                </span>
            </div></Col>}
            {range && <>
                <Col xs={24} lg={6}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} lg={24}>
                            <InfoContent></InfoContent>
                            <Filters
                                allSections={allSections}
                                sections={sections}
                                setSections={setSections}
                                hours={hours}
                                setHours={setHours} />
                        </Col>
                        <Col xs={24} md={12} lg={24}>
                            <InfoChart></InfoChart>
                            <ChartDaily
                                range={range}
                                hours={hours}
                                sections={Array.isArray(sections) && sections.length > 0 ? sections : allSections?.map((ele) => ele.code)} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} lg={18}>
                    <Map
                        range={range}
                        hours={hours}
                        sections={Array.isArray(sections) && sections.length > 0 ? sections : allSections?.map((ele) => ele.code)} />
                    <ChartHour
                        range={range}
                        hours={hours}
                        sections={Array.isArray(sections) && sections.length > 0 ? sections : allSections?.map((ele) => ele.code)}>
                    </ChartHour>
                    <ChartDate
                        range={range}
                        hours={hours}
                        sections={Array.isArray(sections) && sections.length > 0 ? sections : allSections?.map((ele) => ele.code)}>
                    </ChartDate>
                </Col></>}

        </Row>
    );
}

export default Mobility;