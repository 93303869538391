import React from "react";
import "./style.css";
import Widget from "./widget";

const Statistic = () => {

    return (
        <>
            <span>
                Frotas de Resíduos
            </span>
            <div className="wasteFleetsContent">
                <Widget icon="alarmes_abertos.svg" description="Alarmes em aberto das viatuas" value="2 Viaturas" />
                <Widget icon="km_percorrido.svg" description="Número de KM percorridos pela frota" value="399 KM" />
                <Widget icon="media_consumo.svg" description="Média de combustível consumido pela frota" value="276 Litros" />
                <Widget icon="consumo_frota.svg" description="Consumo de combustível global pela frota" value="389 Litros" />
                <Widget icon="total_viaturas.svg" description="Número total de viaturas" value="16 Viaturas" />
                <Widget icon="total_viaturas.svg" description="Total de viaturas em circulação" value="7 Viaturas" />
                <Widget icon="km_percorrido.svg" description="KM acima da média" value="67 KM" />
            </div>
        </>
    );
}

export default Statistic;