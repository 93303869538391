import { Col, Empty, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import ReactSpeedometer from "react-d3-speedometer";
import api from "../../../services/api";

const FireRisk = () => {

    const [on, setOn] = useState({})
    const [tho, setTho] = useState({})

    const fetchsForecastFireRisk = () => {
        api.get('/meteorology/forecast/fire/risk')
            .then((response) => {
                if (response.data) {
                    if (response.data.length > 0)
                        setOn(response.data[0]);
                    if (response.data.length > 1)
                        setTho(response.data[1]);
                }
            });
    };

    useEffect(() => { fetchsForecastFireRisk() }, [])

    return (
        <>
            <span>Previsão do Risco de Incêndio</span>
            <div className="contentParkWidget" style={{ marginTop: "0px", paddingBottom: '0em' }}>
                <Row xs={24}>
                    {

                        <>
                            <div className="contentParkWidget_1" >
                                {on?.data ? <ReactSpeedometer
                                    value={on?.data?.rcm - 0.5}
                                    height={window.screen.availHeight / 4.3}
                                    needleColor="steelblue"
                                    needleTransitionDuration={4000}
                                    needleTransition="easeElastic"
                                    segments={5}
                                    maxValue={5}
                                    currentValueText={`${moment(on.dataPrev).format("dddd, DD/MMM")}`}
                                    segmentColors={[
                                        "#00993C",
                                        "#FEE800",
                                        "#FC7217",
                                        "#EF2028",
                                        "#9D0035",
                                    ]}
                                    customSegmentLabels={[
                                        {
                                            text: "REDUZIDO",
                                            position: "OUTSIDE",
                                            fontSize: "10px"
                                        },
                                        {
                                            text: "MODERADO",
                                            position: "OUTSIDE",
                                            fontSize: "10px"
                                        },
                                        {
                                            text: "ELEVADO",
                                            position: "OUTSIDE",
                                            fontSize: "10px"
                                        },
                                        {
                                            text: "MUITO ELEVADO",
                                            position: "OUTSIDE",
                                            fontSize: "10px"
                                        },
                                        {
                                            text: "MÁXIMO",
                                            position: "OUTSIDE",
                                            fontSize: "10px"
                                        },
                                    ]}
                                /> : <Empty />}
                            </div>

                            <div  className="contentParkWidget_1" >
                                {tho?.data ? <ReactSpeedometer
                                    value={tho?.data?.rcm - 0.5}
                                    height={window.screen.availHeight / 4.3}
                                    needleColor="steelblue"
                                    needleTransitionDuration={4000}
                                    needleTransition="easeElastic"
                                    segments={5}
                                    maxValue={5}
                                    currentValueText={`${moment(tho.dataPrev).format("dddd, DD/MMM")}`}
                                    segmentColors={[
                                        "#00993C",
                                        "#FEE800",
                                        "#FC7217",
                                        "#EF2028",
                                        "#9D0035",
                                    ]}
                                    customSegmentLabels={[
                                        {
                                            text: "REDUZIDO",
                                            position: "OUTSIDE",
                                            fontSize: "10px"
                                        },
                                        {
                                            text: "MODERADO",
                                            position: "OUTSIDE",
                                            fontSize: "10px"
                                        },
                                        {
                                            text: "ELEVADO",
                                            position: "OUTSIDE",
                                            fontSize: "10px"
                                        },
                                        {
                                            text: "MUITO ELEVADO",
                                            position: "OUTSIDE",
                                            fontSize: "10px"
                                        },
                                        {
                                            text: "MÁXIMO",
                                            position: "OUTSIDE",
                                            fontSize: "10px"
                                        },
                                    ]}
                                /> : <Empty />}
                            </div>
                        </>

                    }
                </Row>
            </div>
        </>
    )
}

export default FireRisk