import { Select, Slider } from "antd";
import React from "react";
import filterIcon from "../../../assets/icons/filter.svg";
import "./style.css";

const Filters = ({ allSections, setSections, hours, setHours }) => {

    const { Option } = Select;

    return (
        <div className="analitysMobilityBody">

            <div className="filters">
                <img src={filterIcon} /> Filtros
            </div>

            <div className="sections">
                Secções estatísticas
                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    style={{ width: '100%' }}
                    onChange={(sectionsSelected) => setSections(sectionsSelected)}
                    placeholder="Selecione aqui"
                >
                    {allSections?.map((ele) => <Option key={ele.code} value={ele.code}>{`${ele.description ? ele.description + '; ': ''} ${ele.code}`}</Option>)}
                </Select>
            </div>

            <div className="timers">
                Horários
            </div>

            <div className="all" onClick={() => setHours(hours.length == 24 ? [] : Array.from(Array(24).keys()))}>
                {hours.length == 24 ? 'Todos' : 'Nenhum'}
            </div>

            <div className="buttonsGroup">
                {function () {
                    let buttons = [];
                    for (let i = 0; i < 24; i++) {
                        buttons.push({
                            label: `${i}h`,
                            value: i
                        });
                    }
                    return buttons;
                }().map((element) =>
                    <div
                        className={`hour-button ${hours.includes(element.value) ? 'selected' : ''}`}
                        onClick={() => {
                            if (hours?.includes(element.value)) {
                                setHours(hours.filter((ele) => ele != element.value));
                            } else {
                                setHours([...hours, element.value]);
                            }
                        }}>
                        {element.label}
                    </div>)
                }
            </div>
            <Slider
                onChange={(ele) => {
                    let newRange = [];
                    for (let i = ele[0]; i <= ele[1]; i++) {
                        newRange.push(i);
                    }
                    setHours(newRange);
                }}
                min={0}
                max={23}
                range
                value={[hours[0] || 0, hours[hours?.length - 1] || 0]}>
            </Slider>
        </div>
    );
}

export default Filters;