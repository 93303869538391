import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import AnalyticsMobility from "../../pages/analitysMobility";
import BuildingsColombo from "../../pages/buildings/colombo";
import BuildingsNorthShopping from "../../pages/buildings/northshopping";
import NorthShoppingFinancial from "../../pages/buildings/northshopping/financial";
import BuildingsSonae from "../../pages/buildings/sonae";
import Occupation from "../../pages/buildings/sonae/occupation";
import Bikesharingoccupation from "../../pages/ctech/intelligentmobility/bikesharingoccupation";
import Building from "../../pages/ctech/building"
import Buildingenergyconsumptiontool from "../../pages/ctech/building/energyconsumptiontool";
import Buildingretrofittool from "../../pages/ctech/building/retrofittool";
import Caraccidents from "../../pages/ctech/intelligentmobility/caraccidents";
import DadosurbanosAgriculture from "../../pages/ctech/buildingagriculture/urbanagriculture";
import DadosurbanosEnergy from "../../pages/ctech/building/urbanenergy";
import UrbanHumanConfort from "../../pages/ctech/urbanhumanconfort";
import Lorasensornetwork from "../../pages/ctech/urbanhumanconfort/lorasensornetwork";
import Parkingillegalities from "../../pages/ctech/intelligentmobility/parkingillegalities";
import Walkabilitysimulator from "../../pages/ctech/urbanhumanconfort/walkabilitysimulator";
import BuildingAgriculture from "../../pages/ctech/buildingagriculture";
import Wasteprodution from "../../pages/ctech/buildingagriculture/wasteprodution";
import Contacts from "../../pages/ctech/contacts"
import IntelligentMobility from "../../pages/ctech/intelligentmobility"
import FleetOptimization from "../../pages/ctech/intelligentmobility/fleetoptimization";
import MobilityDemand from "../../pages/ctech/intelligentmobility/mobilitydemand";
import ElectricMobility from "../../pages/electricMobility";
import HomePage from "../../pages/home/index";
import HomeCteck from "../../pages/home/ctech/index";
import Meteorology from "../../pages/meteorology";
import Occurrences from "../../pages/occurrences";
import OccurrencesList from "../../pages/occurrencesList";
import Parking from "../../pages/parking";
import UserInfo from "../../pages/profile";
import SolarDashboard from "../../pages/solar/dashboard";
import SolarProduction from "../../pages/solar/production";
import SolarStorage from "../../pages/solar/storage";
import StreetLighting from "../../pages/streetLighting";
import StreetLightingDetail from "../../pages/streetLightingDetail";
import WasteFleets from "../../pages/wasteFleets";
import PopulationData from "../../pages/populationData";
import Hydrogen from "../../pages/hydrogen/consumption";
import Storange from "../../pages/hydrogen/storange";
import Production from "../../pages/hydrogen/production";

const Content = () => {

  const { hasAccess, authority } = useContext(AuthContext);

  return (
    <Routes>

      {function (route) {
        if (hasAccess("ctec"))
          return <Route exact path={route} element={<HomeCteck />} />
      }("/")}

      {function (route) {
        if (hasAccess("ctec"))
          return <Route exact path={route} element={<HomeCteck />} />
      }("/home")}

      {function (route) {
        if (authority && hasAccess("building_sonae"))
          return <Route exact path={route} element={<Occupation />} />
      }("/")}

      {function (route) {
        if (authority && hasAccess("building_sonae"))
          return <Route exact path={route} element={<Occupation />} />
      }("/home")}

      {function (route) {
        if (!authority && hasAccess("dashboard"))
          return <Route exact path={route} element={<HomePage />} />
      }("/")}

      {function (route) {
        if (!authority && hasAccess("dashboard"))
          return <Route exact path={route} element={<HomePage />} />
      }("/home")}

      {function (route) {
        return <Route exact path={route} element={<Meteorology />} />
      }("/meteorology")}

      {function (route) {
        if (hasAccess("ticket"))
          return <Route exact path={route} element={<Occurrences />} />
      }("/occurrences/home")}

      {function (route) {
        if (hasAccess("ticket"))
          return <Route exact path={route} element={<OccurrencesList />} />
      }("/occurrences/detail")}

      {function (route) {
        if (hasAccess("wasteFleets"))
          return <Route exact path={route} element={<WasteFleets />} />
      }("/wasteFleets")}

      {function (route) {
        if (hasAccess("light_fixture"))
          return <Route exact path={route} element={<StreetLighting />} />
      }("/streetLighting/home")}

      {function (route) {
        if (hasAccess("light_fixture"))
          return <Route exact path={route} element={<StreetLightingDetail />} />
      }("/streetLighting/detail")}

      {function (route) {
        if (hasAccess("parking"))
          return <Route exact path={route} element={<Parking />} />
      }("/parking")}

      {function (route) {
        if (hasAccess("mobility"))
          return <Route exact path={route} element={<AnalyticsMobility />} />
      }("/analyticsMobility")}

      {function (route) {
        if (hasAccess("profile"))
          return <Route exact path={route} element={<UserInfo />} />
      }("/profile")}

      {function (route) {
        if (hasAccess("chargers"))
          return <Route exact path={route} element={<ElectricMobility />} />
      }("/electricMobility")}

      {function (route) {
        if (hasAccess("hydrogen"))
          return <Route exact path={route} element={<Hydrogen />} />
      }("/hydrogen/consumption")}

      {function (route) {
        if (hasAccess("hydrogen"))
          return <Route exact path={route} element={<Production />} />
      }("/hydrogen/production")}

      {function (route) {
        if (hasAccess("hydrogen"))
          return <Route exact path={route} element={<Storange />} />
      }("/hydrogen/storage")}

      {function (route) {
        if (hasAccess("building_sonae"))
          return <Route exact path={route} element={<BuildingsSonae />} />
      }("/buildings/sonae")}

      {function (route) {
        if (hasAccess("building_sonae"))
          return <Route exact path={route} element={<Occupation />} />
      }("/buildings/sonae/occupation")}

      {function (route) {
        if (hasAccess("building_northshopping"))
          return <Route exact path={route} element={<BuildingsNorthShopping />} />
      }("/buildings/northshopping")}

      {function (route) {
        if (hasAccess("building_northshopping"))
          return <Route exact path={route} element={<NorthShoppingFinancial />} />
      }("/buildings/northshopping/financial")}

      {function (route) {
        if (hasAccess("building_colombo"))
          return <Route exact path={route} element={<BuildingsColombo />} />
      }("/buildings/colombo")}

      {function (route) {
        if (hasAccess("solar"))
          return <Route exact path={route} element={<SolarDashboard />} />
      }("/solar/dashboard")}

      {function (route) {
        if (hasAccess("solar"))
          return <Route exact path={route} element={<SolarProduction />} />
      }("/solar/production")}

      {function (route) {
        if (hasAccess("solar"))
          return <Route exact path={route} element={<SolarStorage />} />
      }("/solar/storage")}

      {function (route) {
        if (hasAccess("ctec_lorasensornetwork"))
          return <Route exact path={route} element={<UrbanHumanConfort />} />
      }("/ctec/urbanhumanconfort")}

      {function (route) {
        if (hasAccess("ctec_lorasensornetwork"))
          return <Route exact path={route} element={<Lorasensornetwork />} />
      }("/ctec/urbanhumanconfort/environmentalqualitysensor")}

      {function (route) {
        if (hasAccess("ctec_walkabilitysimulator"))
          return <Route exact path={route} element={<Walkabilitysimulator />} />
      }("/ctec/urbanhumanconfort/walkabilitysimulator")}

      {function (route) {
        if (hasAccess(/* "ctec_building" */ "ctec_dadosurbanos"))
          return <Route exact path={route} element={<Building />} />
      }("/ctec/building")}

      {function (route) {
        if (hasAccess(/* "ctec_building_energy_consumption_tool" */"ctec_dadosurbanos"))
          return <Route exact path={route} element={<Buildingenergyconsumptiontool />} />
      }("/ctec/building/energyconsumptiontool")}

      {function (route) {
        if (hasAccess(/* "ctec_building_retrofit_tool" */"ctec_dadosurbanos"))
          return <Route exact path={route} element={<Buildingretrofittool />} />
      }("/ctec/building/retrofittool")}

      {function (route) {
        if (hasAccess("ctec_dadosurbanos"))
          return <Route exact path={route} element={<DadosurbanosEnergy />} />
      }("/ctec/building/urbanenergy")}

      {function (route){
        if (hasAccess(/* "ctec_mobility_demand" */"ctec_dadosurbanos"))
          return <Route exact path={route} element={<BuildingAgriculture/>}/>
      }("/ctec/agriculture")}
  
      {function (route) {
        if (hasAccess("ctec_dadosurbanos"))
          return <Route exact path={route} element={<DadosurbanosAgriculture />} />
      }("/ctec/agriculture/urban")}

      {function (route) {
        if (hasAccess("ctec_wasteprodution"))
          return <Route exact path={route} element={<Wasteprodution />} />
      }("/ctec/agriculture/wasteprodution")}
      
      {function (route){
        if (hasAccess(/* "ctec_mobility_demand" */"ctec_dadosurbanos"))
          return <Route exact path={route} element={<IntelligentMobility/>}/>
      }("/ctec/mobility")}

      {function (route){
        if (hasAccess(/* "ctec_mobility_demand" */"ctec_dadosurbanos"))
          return <Route exact path={route} element={<MobilityDemand/>}/>
      }("/ctec/mobility/demand")}
      
      {function (route){
        if (hasAccess(/* "ctec_fleet_optimization" */"ctec_dadosurbanos"))
          return <Route exact path={route} element={<FleetOptimization/>}/>
      }("/ctec/mobility/fleetoptimization")}

      {function (route) {
        if (hasAccess(/* "ctec_parkingillegalities" */"ctec_parkingillegaties"))
          return <Route exact path={route} element={<Parkingillegalities />} />
      }("/ctec/mobility/parkingillegalities")}

      {function (route) {
        if (hasAccess("ctec_caraccidents"))
          return <Route exact path={route} element={<Caraccidents />} />
      }("/ctec/mobility/caraccidents")}

      {function (route) {
        if (hasAccess("ctec_bikesharingoccupation"))
          return <Route exact path={route} element={<Bikesharingoccupation />} />
      }("/ctec/mobility/bikesharingoccupation")}

      {function (route) {
        if (hasAccess("population_data"))
          return <Route exact path={route} element={<PopulationData />} />
      }("/population_data")}
      
      {function (route){
        if (hasAccess(/* "ctec_contacts" */"ctec_dadosurbanos"))
          return <Route exact path={route} element={<Contacts/>}/>
      }("/ctec/contacts")}

      <Route exact path="*" element={<div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em" }}><h1>Erro 404 - Não há nada aqui</h1></div>} />

    </Routes>
  );
};

export default Content;
