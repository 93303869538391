import { Col, Row } from "antd";
import { GoogleApiWrapper, InfoWindow, Map, Marker, Polygon } from "google-maps-react";
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import "./style.css";
import stations from "../../../assets/icons/menu/mobilityEletric.png";
import lighting from "../../../assets/icons/menu/streetLighting.png";
import ticket from "../../../assets/icons/menu/occurrence.png";
import camera from "../../../assets/icons/menu/video.png";
import DetailsOccurrences from "../../occurrences/details";
import DetailsStations from "../../electricMobility/details";
import DetailsStreetLight from "../../streetLighting/details";


const Maps = ({ google, dateStart, dateEnd }) => {

    const [center, setCenter] = useState({
        lat: 38.75404065199562,
        lng: -9.138367997423133,
    });

    const [params, setParams] = useState();
    const [points, setPoints] = useState([]);
    const [error, setError] = useState();
    const [contours, setCountors] = useState([]);
    const [activeMarker, setActiveMarker] = useState();
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);

    const handlelighting = async (marker) => {
        let response = await api.get(`/lighting/lightFixture/${marker.name}`)
        return response.data
    };

    const handleStations = async (marker) => {
        let response = await api.get(`/chargers/stations/find/${marker.name}`)
        return response.data
    };

    const handleHelpdesk = async (marker) => {
        let response = await api.get(`/helpdesk/ticket/preview/${marker.name}`)
        return response.data
    };

    const handleonClick = async (props, marker) => {
        switch (marker.title) {
            case "lighting":
                marker._data = await handlelighting(marker)
                marker._type = 'lighting'
                setActiveMarker(marker)
                break
            case "camera":
                marker._data = await handleHelpdesk(marker)
                marker._type = 'camera'
                setActiveMarker(marker)
                break
            case "stations":
                marker._data = await handleStations(marker)
                marker._type = 'stations'
                setActiveMarker(marker)
                break
            default:
                marker._data = await handleHelpdesk(marker)
                marker._type = 'ticket'
                setActiveMarker(marker)
        }
        setShowingInfoWindow(true)
    }

    const refreshTicket = async (_params, _data) => {
        try {
            let data = {
                ...params,
                ..._params,
            };
            let response = await api.post(`/helpdesk/ticket/search/coordinates`, data)
            return [..._data, ...(response.data || []).map((item) => (
                {
                    ...item,
                    icon: 'ticket'
                }
            ))];
        } catch (error) {
            setError(error?.response?.data?.message || "unknown error");
        }

    };

    const refreshCamera = async (_params, _data) => {
        try {
            let data = {
                ...params,
                ..._params,
            };

            let response = await api.post(`/monitoring/camera/search/coordinates`, data);
            return [..._data, ...(response.data || []).map((item) => (
                {
                    ...item,
                    icon: 'camera'
                }
            ))];
        } catch (error) {
            setError(error?.response?.data?.message || "unknown error");
        }
    };


    const refreshCharges = async (_params, _data) => {
        try {
            let data = {
                ...params,
                ..._params,
            };

            let response = await api.post(`/chargers/stations/search/coordinates`, data)
            return [..._data, ...(response.data || []).map((item) => (
                {
                    ...item,
                    icon: 'stations'
                }
            ))];
        } catch (error) {
            setError(error?.response?.data?.message || "unknown error");
        }
    };

    const refreshLighting = async (_params, _data) => {
        try {
            let data = {
                ...params,
                ..._params,
            };
            let response = await api.post(`/lighting/lightFixture/gps/date`, data)
            return [..._data, ...(response.data || []).map((item) => (
                {
                    ...item,
                    icon: 'lighting'
                }
            ))];
        } catch (error) {
            setError(error?.response?.data?.message || "unknown error");
        }
    };


    const handleSearchAll = async (paran) => {
        let _data = [];
        _data = await refreshCamera(paran, _data);
        _data = await refreshLighting(paran, _data);
        _data = await refreshTicket(paran, _data);
        _data = await refreshCharges(paran, _data)
        setPoints(_data);
    }


    const handlerBounds = (mapProps, map) => {
        if (!map || !map.getBounds()) return;
        let bounds = map.getBounds();
        let ne = bounds.getNorthEast();
        let sw = bounds.getSouthWest();
        let cnt = map.getCenter();
        handleSearchAll({
            ne: {
                lat: ne.lat(),
                lng: ne.lng(),
            },
            sw: {
                lat: sw.lat(),
                lng: sw.lng(),
            },
            center: {
                lat: cnt.lat(),
                lng: cnt.lng(),
            }
        });
    };

    const handleCenterLocale = () => {
        api
            .get(`/oauth/public/environment/geometry`)
            .then((response) => {
                setCountors(response.data);
                let point = response.data[0][0];
                setCenter({ lat:point.lat, lng: point.lng });
            }).catch((e) => {
                setMyLocationInCenter();
            });
    };

    const setMyLocationInCenter = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            let { latitude, longitude } = position.coords;
            setCenter({ lat: latitude, lng: longitude });
        });
    };

    const handleIcon = (icon) => {
        switch (icon) {
            case "lighting":
                return lighting;
            case "camera":
                return camera;
            case "stations":
                return stations;
            default:
                return ticket;
        }
    }

    useEffect(handleCenterLocale, [dateStart, dateEnd]);

    return (
        <Row className="map" style={{ width: "600", height: "450" }}>
            <Col xs={24}>
                <Map
                    initialCenter={center}
                    center={center}
                    google={google}
                    zoom={13}
                    disableDefaultUI={true}
                    style={{ borderRadius: "16px" }}
                    fullscreenControl={false}
                    zoomControl={true}
                    streetViewControl={false}
                    mapTypeControl={false}
                    onIdle={handlerBounds}
                    googleMapStyle={[
                        {
                            "featureType": "administrative",
                            "stylers": [
                                { "visibility": "off" }
                            ]
                        }, {
                            "featureType": "road",
                            "stylers": [
                                { "visibility": "simple" },
                                { "color": "#393939" }
                            ]
                        }, {
                            "elementType": "labels",
                            "stylers": [
                                { "visibility": "off" }
                            ]
                        }, {
                            "featureType": "water",
                            "stylers": [
                                { "color": "#303030" }
                            ]
                        }, {
                            "featureType": "landscape",
                            "stylers": [
                                { "color": "#2a2a2a" }
                            ]
                        }, {
                            "featureType": "poi",
                            "stylers": [
                                { "color": "#393939" },
                                { "visibility": "off" }
                            ]
                        }, {
                            "featureType": "transit",
                            "elementType": "geometry",
                            "stylers": [
                                { "visibility": "off" }
                            ]
                        }, {
                            "featureType": "administrative",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                { "visibility": "on" },
                                { "color": "#393939" }
                            ]
                        }
                    ]}
                >
                    {points?.map((item) => (
                        <Marker
                            icon={handleIcon(item.icon)}
                            title={item.icon}
                            position={{
                                lat: item?.lat,
                                lng: item?.lng,
                            }}
                            name={item.id}
                            onClick={(props, marker) => handleonClick(props, marker)}
                        />
                    ))}

                    {(contours || []).map((ele) =>
                        <Polygon
                            options={{
                                strokeColor: '#000f63',
                                strokeOpacity: 1,
                                strokeWeight: 2,
                                fillOpacity: 0.2
                            }}
                            paths={ele}
                        >
                        </Polygon>)}

                    {activeMarker && activeMarker._data && <InfoWindow marker={activeMarker} visible={showingInfoWindow}>

                        {activeMarker._type === 'lighting' ? <DetailsStreetLight data={activeMarker._data} /> : activeMarker._type === 'camera' ? <></> : activeMarker._type === 'stations' ? <DetailsStations data={activeMarker._data} /> : <DetailsOccurrences data={activeMarker._data} />}
                    </InfoWindow>}
                </Map>
            </Col>
        </Row>
    );
}
export default GoogleApiWrapper({
    apiKey: `${process.env.REACT_APP_GOOGLE_MAPS_TOKEN}`,
})(Maps);
