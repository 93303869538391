import React from "react";
import { Col, Row } from "antd";
import "./style.css";
import icon from "../../../../assets/icons/buildings/water-icon.svg";

const Widget = () => {

    return (
        <>
            <span>
                Gastos totais da água
            </span>
            <div className="buildingWidgetSimple">
                <Row>
                    <Col xs={5} >
                        <img src={icon}></img>
                    </Col>
                    <Col xs={8}>
                        <div className="text">
                            8,1€ por dia
                        </div>
                    </Col>
                    <Col xs={11}>
                        <div className="divLine">
                            <div className="text">
                                10 m³ por dia
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>);
}

export default Widget;