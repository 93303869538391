import React, { useEffect, useState } from "react";
import "./style.css";
import api from "../../../services/api";
import Loading from "../../../components/loading";
import ErrorWidget from "../../../components/error";

const Legend = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const [categorys, setCategorys] = useState([]);
    const [status, setStatus] = useState([]);

    const fetchLegends = async () => {
        try {
            setLoading(true);
            setError(undefined);
            let statusResponse = await api.get(`/helpdesk/status`);
            let categorysResponse = await api.get(`/helpdesk/category/search`);
            setStatus(statusResponse?.data);
            setCategorys(categorysResponse?.data);
        } catch (_error) {
            setError(_error?.response?.data?.message || "unknown error");
        } finally {
            setLoading(false);
        }
    }

    const getToken = () => {
        let credentials = JSON.parse(localStorage.getItem(`credentials-${process.env.PUBLIC_URL}`));
        return `${credentials.token_type} ${credentials.access_token}`;
    };

    useEffect(fetchLegends, []);

    return (
        <div className="occurrences_list_legend_root">
            <div className="occurrentesTitle">
                <span>
                    Legenda
                </span>
            </div>

            {error && <ErrorWidget message={error} refresh={fetchLegends} />}

            {!error &&
                <div class="occurrences_list_legend">

                    {loading && <Loading />}

                    {!error && !loading &&
                        <div class="aling-left">
                            {status.map((_sts) =>
                                <div class="group">
                                    <div class="circle" style={{ backgroundColor: _sts?.color }}></div>
                                    <span>{_sts?.description}</span>
                                </div>)
                            }
                        </div>
                    }

                    {!error && !loading &&
                        <div class="aling-left">
                            {categorys.map((_cat) =>
                                <div class="group">
                                    {
                                        _cat?.icon?.id ?
                                        <img
                                            alt=""
                                            title={_cat?.name}
                                            style={{maxWidth: "17px", maxHeight: "17px"}}
                                            src={`${process.env.REACT_APP_API_URL}/storage/render/${_cat?.icon?.id}`}
                                        />
                                        : <span title="Sem ícone"></span>
                                    }
                                    <span>{_cat.name}</span>
                                </div>)
                            }
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default Legend;