import React from "react"
import "../../style.css";
import { Link } from "react-router-dom";
const Caraccidents = () => {

    const partner = <Link to="/ctec/contacts?partner=NOVA-IMS">NovaIMS</Link>

    return (<div><iframe className="frame-content" src="https://app.powerbi.com/view?r=eyJrIjoiNDQwYWFlZjMtMmEwOC00MDI1LTgzMGEtMGUzMjQ1ZjdjYjg2IiwidCI6ImU0YmQ2OWZmLWU2ZjctNGMyZS1iMjQ3LTQxYjU0YmEyNDkwZSIsImMiOjh9" title="Car Accidents"></iframe>
    <div><p>Built BY {partner}</p></div></div>)
}

export default Caraccidents