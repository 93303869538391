import React, { useState } from "react"
import "../style.css";
import CEIIA from "../../../assets/icons/contacts/CEIIA.png"
import IST from "../../../assets/icons/contacts/IST.png"
import LISBOAENOVA from "../../../assets/icons/contacts/LISBOA ENOVA.png"
import MIT from "../../../assets/icons/contacts/MIT.png"
import NOS from "../../../assets/icons/contacts/NOS.png"
import NOVAIMS from "../../../assets/icons/contacts/NOVA IMS.png"
import { useSearchParams } from "react-router-dom";
import _Menu from "../../../templeate/menu"

const Contacts = () => {

    
    const [overBuilding, setOverBuilding] = useState(false);
    const imageHeight = "12.5%"
    const imageWidth = "12.5%"
    const imageOpacity = "opacity(30%)"

    const [searchParams] = useSearchParams();
    const [selected, setSelected] = useState(searchParams.get("partner")? searchParams.get("partner") : "NOS");
    return(
        <div className="cteck-home">
            <div className="cteck-content" style={{ fontSize: "1.0em", color: "black", padding: "8px" }}>
                <div style={{minWidth:"50%" , display:"Flexbox", marginBottom:"2%", alignContent:"center", marginTop:"2%"}} >
                        <img 
                            src={NOS} 
                            alt="NOS Logo"
                            onClick={() =>setSelected("NOS")}
                            style={{textAlign:"center", maxHeight:imageHeight , maxWidth:imageWidth, filter:selected=="NOS"?"opacity(100%)":imageOpacity, marginLeft:"10%"}}
                        />
                   
                    
                        <img 
                            src={IST} 
                            alt="IST Logo" 
                            onClick={() => setSelected("IST")}
                            style={{maxHeight:imageHeight , maxWidth:imageWidth, filter:selected=="IST"?"opacity(100%)":imageOpacity, marginLeft:"15%",marginRight:"15%"}}
                        />
                   
                    
                        <img 
                            src={MIT} 
                            alt="MIT Logo" 
                            onClick={() => setSelected("MIT")}
                            style={{alignSelf:"right", maxHeight:imageHeight , maxWidth:"35%", filter:selected=="MIT"?"opacity(100%)":imageOpacity}}
                        />
                   
                </div>
                <div style={{minWidth:"50%", display:"Flexbox", marginBottom:"4%", alignContent:"center"}} >
                        <img 
                            src={NOVAIMS} 
                            alt="NOVA-IMS Logo" 
                            onClick={() =>setSelected("NOVA-IMS")}
                            style={{maxHeight:imageHeight, maxWidth:imageWidth, filter:selected=="NOVA-IMS"?"opacity(100%)":imageOpacity, marginLeft:"11%"}}
                        />
                    
                        <img 
                            src={LISBOAENOVA} 
                            alt="Lisboa Enova Logo" 
                            onClick={() => setSelected("LISBOAENOVA")}
                            style={{maxHeight:imageHeight, maxWidth:"25%", filter:selected=="LISBOAENOVA"?"opacity(100%)":imageOpacity, marginLeft:"10%",marginRight:"15%"}}
                        />
                    
                    
                        <img 
                            src={CEIIA} 
                            alt="CEIIA Logo" 
                            onClick={() => setSelected("CEIIA")}
                            style={{maxHeight:imageHeight , maxWidth:imageWidth, filter:selected=="CEIIA"?"opacity(100%)":imageOpacity}}
                        />
                    
                </div>
                {selected === "NOS" && 
                <div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution Name:</p>
                        <p>NOS Comunicações</p><br />
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution description:</p>
                        <p>NOS Comunicações provides electronic communications networks, services, products and equipment along with related facilities. Aware of the growing demand for innovative technological solutions in the context of Smart Cities, NOS created a business area exclusively dedicated to this specific market segment, providing the market with a technological platform, made up of different modular verticals, with the possibility of scalability and promoting ecosystem empowerment. This allows for the development and implementation of disruptive solutions in the field of Smart Cities, putting models resulting from research & development into production.</p><br/>
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution URL:</p>
                        <p><a target="_blank" href="https://www.nos.pt/">https://www.nos.pt/</a></p><br/>
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution Email for CTech contact:</p>
                        <p>pedro.machado@nos.pt</p>
                    </div>
                </div>}
                {selected === "IST" && 
                <div>
                     <div>
                        <p style={{fontWeight:"bold"}}>Institution Name:</p>
                        <p>Instituto Superior Técnico</p><br />
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution description:</p>
                        <p>Instituto Superior Técnico aims to contribute to the development of society, promoting andsharing excellence in higher education in the fields of Architecture, Engineering, Science andTechnology. Técnico offers Bachelor, Master and PhD programmes, lifelong training anddevelops Research, Development and Innovation (RD&I) activities, which are essential to providean education based on the top international standards.</p><br/>
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution URL:</p>
                        <p><a target="_blank" href="https://tecnico.ulisboa.pt/en/">https://tecnico.ulisboa.pt/en/</a></p><br/>
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution Email for CTech contact:</p>
                        <p>ricardo.a.gomes@tecnico.ulisboa.pt</p>
                    </div>
                </div>}
                {selected === "MIT" && 
                <div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution Name:</p>
                        <p>MIT Sustainable Design Lab (MIT SDL)</p><br />
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution description:</p>
                        <p>Inter-disciplinary research group with a grounding in architecture that develops design workflows, planning tools and metrics to evaluate the environmental performance of buildings and neighborhoods. Software tools originating from our lab are used in practice and education in over 90 countries.</p><br/>
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution URL:</p>
                        <p><a target="_blank" href=" http://mit.edu/SustainableDesignLab/"> http://mit.edu/SustainableDesignLab/</a></p><br/>
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution Email for CTech contact:</p>
                        <p>creinhart@mit.edu</p>
                    </div>
                </div>}
                {selected === "NOVA-IMS" && 
                <div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution Name:</p>
                        <p>NOVA Cidade – Urban Analytics Lab by NOVA Information Management School (IMS)</p><br />
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution description:</p>
                        <p>The NOVA Cidade – Urban Analytics Lab is a network of people and organizations, promoted by NOVA IMS, representing the protagonists of the construction of smart cities and territories, which collaboratively develops a set of activities that aim to promote the creation and transfer of knowledge applied in the context of urban intelligence aiming to actively contribute to the construction of smart and sustainable cities and territories. Based on information management and data science and involving local government, academia and industry, we aim to actively contribute to the fulfilment of SDG 11 – Sustainable Cities and Communities of the United Nations.</p><br/>
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution URL:</p>
                        <p><a target="_blank" href="https://novacidade.pt/en/">https://novacidade.pt/en/</a></p><br/>
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution Email for CTech contact:</p>
                        <p>novacidade@novaims.unl.pt</p>
                    </div>
                </div>}
                {selected === "LISBOAENOVA" && 
                <div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution Name:</p>
                        <p>Lisboa E-Nova – Energy and Environment Agency of Lisbon</p><br />
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution description:</p>
                        <p>Lisboa E-Nova (Lisbon's Municipal Energy and Environmental Agency) is a non-profit association operating under private law, which seeks to contribute to sustainable development by the systematic and continuous improvement of the energy and environmental performance of the city. Lisboa E-Nova's aim is to implement a continuous improvement process involving all the city's key stakeholders, based on a holistic and quantified approach, which will result in a better energy and environmental performance of the city.</p><br/>
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution URL:</p>
                        <p><a target="_blank" href="https://lisboaenova.org/">https://lisboaenova.org/</a></p><br/>
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution Email for CTech contact:</p>
                        <p>sarafreitas@lisboaenova.org</p>
                    </div>
                </div>}
                {selected === "CEIIA" && 
                <div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution Name:</p>
                        <p> CEiiA - Centro de Engenharia e Desenvolvimento do Produto</p><br />
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution description:</p>
                        <p>CEiiA is a European Centre of Engineering and Product Development focused on sustainable technologies in mobility, aeronautics, and ocean and space sectors. CEiiA designs vehicles, develop technology for mobility services, and has recently been awarded for innovation by Google and the European Commission.</p><br/>
                        <p>As part of the C-Tech project, CEiiA's multidisciplinary team worked on mobility strategy, AI development for urban mobility, sensor technology, and traffic modeling.</p><br />
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution URL:</p>
                        <p><a target="_blank" href="https://www.ceiia.com/">https://www.ceiia.com/</a></p><br/>
                    </div>
                    <div>
                        <p style={{fontWeight:"bold"}}>Institution Email for CTech contact:</p>
                        <p>sandra.melo@ceiia.com</p>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default Contacts