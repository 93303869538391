import { Col, Row, Select } from "antd";
import { useEffect, useState } from "react";
import api from "../../services/api";
import Age from "./age";
import Filter from "./filter";
import Gender from "./gender";
import Job from "./job";
import Maps from "./maps";
import Schooling from "./schooling";

const PopulationData = () => {

  const [locais, setLocais] = useState([])
  const [anos, setAnos] = useState([])
  const [anoSelected, setAnoSelected] = useState()
  const [localSelected, setLocalSelected] = useState({})
  const [section, setSection] = useState()
  const [identifier, setIdentifier] = useState([])
  const [polygon, setPolygon] = useState()

  const fetchIdentifier = () => {
    api.get("geoservices/identifier").then((response) => {
      if (response.data.length > 0) {
        setIdentifier(response.data)
      }
    })
  }

  const fetchLocal = () => {
    api.get("geoservices/local/tree", {
      params: {
        ano: anoSelected
      }
    }).then((response) => {
      setLocais(response.data || [])
      if (response.data.length > 0) {
        setLocalSelected({ local: response.data[0].id })
        setSection(response.data[0].name)
      }
    })
  }

  const fetchAnos = () => {
    api.get("geoservices/local/ano").then((response) => {
      if (response.data.length > 0) {
        setAnos(response.data)
      }
    })
  }

  const handleSearch = (event) => {
    let text = event.target.value
    if (text) {
      setLocalSelected({ search: text })
    }
  }

  useEffect(() => {
    fetchAnos()
    fetchIdentifier()
  }, [])

  useEffect(() => {
    if (anoSelected)
      fetchLocal()
  }, [anoSelected])


  return (
    <>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col xs={24}>
          <div className="filter_ano">
            <table>
              <tr>
                <td style={{ marginRight: "10px" }}>Censo</td>
                <td>
                  <Select
                    style={{ width: "300px", float: "right" }}
                    allowClear
                    value={anoSelected}
                    onSelect={(value) => setAnoSelected(value)}
                    options={(anos || []).map(i => ({ value: i, label: i }))}
                  />
                </td>
              </tr>
            </table>
          </div>
        </Col>
        <Col xs={24} sm={8} style={{ marginBottom: '10px' }}>
          <Filter locais={locais} setLocais={setLocais} localSelected={localSelected} setSection={setSection} setLocalSelected={setLocalSelected} anos={anos} setAnoSelected={setAnoSelected} anoSelected={anoSelected} />
        </Col>
        <Col xs={24} sm={16} style={{ marginBottom: '10px' }}>
          <Maps localSelected={localSelected} handleSearch={handleSearch} section={section} setSection={setSection} setPolygon={setPolygon} />
        </Col>
        <Col xs={24} sm={12} style={{ marginBottom: '10px' }}>
          <Age localSelected={localSelected} polygon={polygon} />
        </Col>
        <Col xs={24} sm={12} style={{ marginBottom: '10px' }}>
          <Gender localSelected={localSelected} polygon={polygon} />
        </Col>
        <Col xs={24} sm={12} >
          <Schooling localSelected={localSelected} polygon={polygon} identifier={identifier} />
        </Col>
        <Col xs={24} sm={12} >
          <Job localSelected={localSelected} identifier={identifier} polygon={polygon} />
        </Col>
      </Row>
    </>
  );
};

export default PopulationData;
