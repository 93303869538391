import { Col, Row } from "antd";
import React from "react";
import Loading from "../../../../../../components/loading";
import "./style.css";

const Widget = ({
  icon,
  title,
  valueComparative,
  loadingComparative,
  errorComparative,
  reloadComparative,

  valueAnalyse,
  loadingAnalyse,
  errorAnalyse,
  reloadAnalyse,
}) => {

  return (
    <div className="financialWidgetContent">
      <Row>

        <Col xs={24} md={2} className="icon">
          <img
            src={require(`../../../../../../assets/icons/financial/${icon}`)}
            alt=""
          />
        </Col>

        <Col xs={24} md={7} className="title">
          {title}
        </Col>

        <Col xs={24} md={7} className="value">
          {loadingAnalyse ? (
            <Loading size="0em" direction="right" />
          ) : errorAnalyse ? (
            <span
              style={{ color: "red", fontWeight: "normal", cursor: 'pointer' }}
              title={errorAnalyse}
              onClick={reloadAnalyse}
            >
              {`error: ${errorAnalyse.substring(0, 15)}`}{" "}
            </span>
          ) : (
            <>
              {valueAnalyse}
              <div className="subValue">
                análise
              </div>
            </>
          )}
        </Col>

        <Col xs={24} md={7} className="value">
          {loadingComparative ? (
            <Loading size="0em" direction="right" />
          ) : errorComparative ? (
            <span
              style={{ color: "red", fontWeight: "normal", cursor: 'pointer' }}
              title={errorComparative}
              onClick={reloadComparative}
            >
              {`error: ${errorComparative.substring(0, 15)}`}{" "}
            </span>
          ) : (
            <>
              {valueComparative}
              <div className="subValue">
                comparativo
              </div>
            </>
          )}
        </Col>

      </Row>
    </div>
  );
};

export default Widget;
