import { Col, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import Widget from "./widget";
import moment from "moment-timezone";

const Variaveis = ({ format, invSelected, inversores }) => {

    const variaveis = ["EaT_kWh", "Pac", "Pdc", "Iac", "UacL1", "TempAmbient", "TempPanel", "Irradiation",]
    const [lastReads, setLastReads] = useState([])
    const [lastTime, setLastTime] = useState(moment());
    const [loading, setLoading] = useState(false);

    const startTimeout = () => setTimeout(() => setLastTime(moment()), 1000);
    const fetchLastRead = () => {
        setLoading(true);
        api.get(`/solar/sensors/last/reading/sensors/Solar?name=${invSelected}`)
            .then(handlerVariable)
            .finally(() => setLoading(false))        
    };

    const handlerVariable = ({ data }) => {
        let inver = inversores.find((ele)=>ele.value == invSelected) || {};
        data = data.filter((dt) => dt.sourceName.name.includes(invSelected));
        let _data = []
        variaveis.forEach(element => {
            let _dataValue = data.find(i => i.sourceName.name.includes(element))
            if(_dataValue){
                _data.push({ name: _dataValue.sourceName.pt, value: _dataValue.sourceValue, unity: _dataValue.sourceName.unity, inv :inver.label})
            }
        });
        setLastReads(_data)
    }

    useEffect(() => {
        fetchLastRead()
    }, [lastTime, invSelected])

    useEffect(startTimeout, [lastTime]);

    return (
        <>
            <span>Variáveis em tempo real por inversor</span>
            <Row gutter={[4, 4]} style={{ marginTop: "-15px" }}>
                {lastReads.map((item) =>
                    <Col xs={24} >
                        <Widget
                            icon={function () {
                                switch (item.name.trim()) {
                                    case 'Energia Total Acumulada': return "energiaAcumulada.svg";
                                    case 'Saída de alimentação': return "saidaAlimentacao.svg";
                                    case 'Entrada de alimentação': return "entradaAlimentacao.svg";
                                    case 'Corrente': return "corrente.svg";
                                    case 'Tensão': return "tensao.svg";
                                    case 'Temperatura ambiente': return "temperaturaAmbiente.svg";
                                    case 'Temperatura do módulo fotovoltaico': return "temperaturaDoModulo.svg";
                                    case 'Irradiação': return "irradiacao.svg";
                                    default: return "media_consumo.svg";
                                }
                            }()}
                            title={item.name}
                            tooltip={item.value}
                            // tooltip2={item.v2}
                            value={format(item.value, 2)}
                            // v2={format(item.v2, 2)}
                            unity={item.unity}
                            name={item.inv}
                        />
                    </Col>
                )}
                {!lastReads || lastReads.length == 0 &&
                    <>
                        {loading ? <Skeleton active /> : <h1 style={{ marginTop: "2em" }}>
                            {'Sem dados'}
                        </h1>
                        }
                    </>
                }
            </Row>
        </>
    )
}

export default Variaveis