import { Col, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import data from "./data.json";
import "./style.css";
var pt = require("apexcharts/dist/locales/pt.json");

const GraphicDistributionPrice = ({ dateStart, dateEnd }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [series, setSeries] = useState([]);
  const [categorys, setCategorys] = useState([]);

  const fetchTariff = () => {
    setLoading(true);
    setError(false);
    api
      .get("/lighting/tariff/find/group/monther")
      .then((response) => {
        setSeries(generateSeries(response.data));
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSetDate = (response) => {
    let dates = [];
    let start = moment(response[0]?.dateFormat);

    dates.push(start.format("MMM"));
    while (start < moment()) {
      start = start.add(1, "M");
      dates.push(start.format("MMM"));
    }
    return dates;
  };



  const generateSeries = (_data = []) => {
    let _categories = []
    _data.forEach((element) => {
      _categories.push(moment(element.startsValidity).format('MMMM YY'))
    });
    setCategorys(_categories);
     return [
      {
        name: "Preço",
        data: _data?.map((item) =>
        (
          item.tariff || 0)
        
        )
      }
    ];
  };

  useEffect(() => {
    fetchTariff();
  }, [dateStart, dateEnd]);

  return (
    <>
      <Col xs={24} className="margin-top-mobile">
        <span> Gráfico de distribuição do preço por kW/h</span>
      </Col>
      {loading ? (
        <Loading />
      ) : error ? (
        <WidgetError message={`${error}`} refresh={() => fetchTariff()} />
      ) : (
        <div className="graphic_content">
          <Row gutter={16}>
            <Col xs={24}>
              <div id="chart-ticket-stats" className="graphic_chart">
                <Chart
                  options={{
                    ...data.options,
                    yaxis: {
                      labels: {
                        formatter: function (value) {
                          return new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4
                          }).format(value);
                        }
                      },
                    },
                    xaxis: {
                      type: "string",
                      categories: categorys,
                    },
                    chart: {
                      locales: [pt],
                      defaultLocale: "pt",
                      toolbar: { show: false },
                    },
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        borderRadius: 10
                      },
                    },
                  }}
                  series={series}
                  type="bar"
                  height="230"
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default GraphicDistributionPrice;
