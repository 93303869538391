import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "./style.css";
import data from "./data.json";
import { Col, Row } from "antd";
import api from "../../../services/api";
import ErrorWidget from "../../../components/error";

const ChartDaily = ({ sections, hours, range }) => {

    const [registers, setRegisters] = useState([]);
    const [error, setError] = useState();

    const fetchData = () => {
        if (range) {
            setError(undefined);
            api.post(`/mobility/statifyRegister/statistic/dayofweek`, {
                sections: sections || [],
                hours: hours || [],
                range: range.id
            }).then((response) => {
                setRegisters(response.data);
            }).catch((error) => {
                setError(error?.response?.data?.message || "unknown error");
            })
        }

    }

    useEffect(fetchData, [sections, hours]);

    return (
        error ? <ErrorWidget message={error} refresh={fetchData} /> :
            <div className="analitysMobilityChartDaily">
                <Row gutter={[0, 10]} style={{ marginBottom: "1em" }}>
                    <Col xs={12} md={12} lg={15} className="label">
                        Número de secções
                    </Col>
                    <Col xs={12} md={12} lg={9} style={{ textAlign: "right" }}>
                        {sections?.length || 0}
                    </Col>
                    <Col xs={12} md={12} lg={15} className="label">
                        Soma de passagens
                    </Col>
                    <Col xs={12} md={12} lg={9} style={{ textAlign: "right" }}>
                        {function () {
                            return registers?.reduce((ag, ele) => (ag + ele.value), 0)?.toLocaleString(
                                undefined,
                                { minimumFractionDigits: 0 }
                            );
                        }()}
                    </Col>
                </Row>
                <span>
                    Passagens
                </span>
                <Chart
                    options={data.options}
                    series={[
                        function () {
                            return {
                                name: "Passagens",
                                data: data.options.xaxis.categories.map((ele, idx) => registers.find((reg) => reg?.dayOfWeek == (idx + 1))?.value || 0)
                            };
                        }()
                    ]}
                    type="bar"
                    height={350} />
            </div>
    );
}

export default ChartDaily;