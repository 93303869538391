import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import moment from 'moment-timezone';
import "./style.css";

import CleanNight from "./widgets/cleanNight";
import DayWithClouds from "./widgets/dayWithClouds";
import DayWithRain from "./widgets/dayWithRain";
import Fog from "./widgets/fog";
import NightWithClouds from "./widgets/nightWithClouds";
import NightWithRain from "./widgets/nightWithRain";
import Snow from "./widgets/snow";
import Sun from "./widgets/sun";
import Thunderstorm from "./widgets/thunderstorm";
import Wind from "./widgets/wind";
import WidgetError from "../../../components/error";
import api from "../../../services/api";
import Loading from "../../../components/loading";

const Meteorology = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [forecast, setForecast] = useState({
    type: "",
    station: "",
    temperatura: "",
    time: "",
    weatherTypePT: "",
  });

  const searchForecast = () => {
    setLoading(true);
    setError(false);
    api
      .get("/meteorology/forecast/obs3h", {})
      .then((response) => {
        setForecast({
          ...response.data,
          time: moment.utc(response.data.time).local().format('HH:mm'),
          temperatura: `${response.data.temperatura.toLocaleString(undefined, { minimumFractionDigits: 1 })}º`
        }
        );
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    searchForecast();
  }, []);

  return (
    <Row >
      <Col xs={24}>
        <span className="meteorology-spam">Meteorologia</span>
      </Col>

      <Col xs={24}>
        {loading ? (
          <Loading />
        ) : error ? (
          <WidgetError message={`${error}`} refresh={() => searchForecast()} />
        ) : (
          <div style={{marginTop:"-10px"}}>
            {(function () {
              switch (forecast.type) {
                case "CleanNight":
                  return <CleanNight {...forecast} />;
                case "DayWithClouds":
                  return <DayWithClouds {...forecast} />;
                case "DayWithRain":
                  return <DayWithRain {...forecast} />;
                case "Fog":
                  return <Fog {...forecast} />;
                case "NightWithClouds":
                  return <NightWithClouds {...forecast} />;
                case "NightWithRain":
                  return <NightWithRain {...forecast} />;
                case "Snow":
                  return <Snow {...forecast} />;
                case "Sun":
                  return <Sun {...forecast} />;
                case "Thunderstorm":
                  return <Thunderstorm {...forecast} />;
                case "Wind":
                  return <Wind {...forecast} />;
                default:
                  return <span>Invalid meteorology type</span>;
              }
            })()}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default Meteorology;
