import { Col, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import Carrocell from "../carrocell";
import WidgetInfo from "./widgetInfo";

const Statistics = ({ dateStart, dateEnd }) => {

  const [averageDailyConsumption, setAverageDailyConsumption] = useState(0);
  const [averageDailyConsumptionLoad, setAverageDailyConsumptionLoad] = useState(false);
  const [averageDailyConsumptionError, setAverageDailyConsumptionError] = useState(false);

  const [averageTicketDuration, setAverageTicketDuration] = useState(0);
  const [averageTicketDurationLoad, setAverageTicketDurationLoad] = useState(false);
  const [averageTicketDurationError, setAverageTicketDurationError] = useState(false);

  const [tiketOpen, setTiketOpen] = useState(0);
  const [tiketOpenLoad, setTiketOpenLoad] = useState(false);
  const [tiketOpenError, setTiketOpenError] = useState(false);

  const [tiketClosed, setTiketClosed] = useState(0);
  const [tiketClosedLoad, setTiketClosedLoad] = useState(false);
  const [tiketClosedError, setTiketClosedError] = useState(false);

  const [averageDailyCost, setAverageDailyCost] = useState(0);
  const [tariff, setTariff] = useState(0);
  const [tariffLoad, setTariffLoad] = useState(false);
  const [tariffError, setTariffError] = useState(false);


  const fetchAverageDailyConsumption = () => {
    setAverageDailyConsumptionLoad(true);
    setAverageDailyConsumptionError(false);
    api
      .post("/assets/telemetry/average/consumption/daily", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
      })
      .then((response) => {
        setAverageDailyConsumption(response.data);
      })
      .catch((error) => {
        setAverageDailyConsumptionError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setAverageDailyConsumptionLoad(false);
      });
  };

  const fetchAverageTicketDuration = () => {
    setAverageTicketDurationLoad(true);
    setAverageTicketDurationError(false);
    api
      .post("/helpdesk/ticket/average/duration", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        today: dateEnd ? moment(dateEnd).utc() : undefined,
        type: "light",
      })
      .then((response) => {
        setAverageTicketDuration(convertSecondstoTime(response.data.count));
      })
      .catch((error) => {
        setAverageTicketDurationError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setAverageTicketDurationLoad(false);
      });
  };

  const fetchTiketOpen = () => {
    setTiketOpenLoad(true);
    setTiketOpenError(false);
    api
      .post("/helpdesk/ticket/count/item/group/situation/date", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        today: dateEnd ? moment(dateEnd).utc() : undefined,
        type: "light_fixture",
        status: [
          "_0_in_triage",
          "_0_registered",
          "_1_overdue",
          "_2_forwarded",
          "_3_in_progress",
          "_4_under_review",
        ],
      })
      .then((response) => {
        setTiketOpen(response.data.count);
      })
      .catch((error) => {
        setTiketOpenError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setTiketOpenLoad(false);
      });
  };

  const fetchTiketClosed = () => {
    setTiketClosedLoad(true);
    setTiketClosedError(false);
    api
      .post("/helpdesk/ticket/count/item/group/situation/date", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        today: dateEnd ? moment(dateEnd).utc() : undefined,
        type: "light_fixture",
        status: ["_5_complete", "_6_canceled"],
      })
      .then((response) => {
        setTiketClosed(response.data.count);
      })
      .catch((error) => {
        setTiketClosedError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setTiketClosedLoad(false);
      });
  };

  const fetchTariff = () => {
    setTariffLoad(true);
    setTariffError(false);
    api
      .get("/lighting/tariff/find/last", {})
      .then((response) => {
        setTariff(response.data);
      })
      .catch((error) => {
        setTariffError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setTariffLoad(false);
      });
  };

  const handleAverageDailyCost = () => {
    setAverageDailyCost(((averageDailyConsumption?.count || 0) * (tariff?.tariff || 0)).toLocaleString(
      undefined,
      { maximumFractionDigits: 4 }
    ));
  };

  useEffect(() => {
    handleAverageDailyCost();
  }, [averageDailyConsumption, tariff]);

  function convertSecondstoTime(sec) {
    var dateObj = new Date(sec * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var timeString =
      hours.toString().padStart(2, "0") +
      "h " +
      minutes.toString().padStart(2, "0") +
      "m";
    return timeString;
  }

  useEffect(() => {
    fetchAverageDailyConsumption();
    fetchAverageTicketDuration();
    fetchTiketOpen();
    fetchTiketClosed();
    fetchTariff();
  }, [dateStart, dateEnd]);
  return (
    <>
      
        <Carrocell dateStart={dateStart} dateEnd={dateEnd} />
  
      <Row gutter={16} style={{ marginTop: "16px" }}>
        <Col xs={24}>
          <WidgetInfo
            error={averageDailyConsumptionError}
            loading={averageDailyConsumptionLoad}
            icon="media_consumo.svg"
            title="Média de consumo diário kW"
            value={`${(averageDailyConsumption?.count || 0).toLocaleString(
              undefined,
              { maximumFractionDigits: 4 }
            )} kW`}
          />
        </Col>
        <Col xs={24}>
          <WidgetInfo
            error={tariffError}
            loading={tariffLoad}
            icon="media_custo.svg"
            title="Média do custo diário"
            value={`${averageDailyCost} € / kW`}
          />
        </Col>
        <Col xs={24}>
          <WidgetInfo
            error={tiketOpenError}
            loading={tiketOpenLoad}
            icon="total_ocorrencias_aberto.svg"
            title="Total de ocorrências no estado aberto"
            value={`${tiketOpen} ocorrências`}
          />
        </Col>
        <Col xs={24}>
          <WidgetInfo
            error={tiketClosedError}
            loading={tiketClosedLoad}
            icon="total_ocorrencias_fechada.svg"
            title="Total de ocorrências fechadas até á data"
            value={`${tiketClosed} ocorrências`}
          />
        </Col>
        <Col xs={24}>
          <WidgetInfo
            error={averageTicketDurationError}
            loading={averageTicketDurationLoad}
            icon="tempo_medio_resolucao.svg"
            title="Tempo médio de resolução das ocorrências"
            value={averageTicketDuration}
          />
        </Col>
      </Row>
    </>
  );
};

export default Statistics;
