import React from "react";

function Icon({ station, temperatura, time ,weatherTypePT}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            viewBox="0 0 349 136"
        >
            <defs>
                <filter
                    id="bwh0tnftla"
                    width="222.5%"
                    height="322.7%"
                    x="-61.2%"
                    y="-106.8%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="j94gslrcnc"
                    width="140%"
                    height="304.2%"
                    x="-20%"
                    y="-97.9%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="a6xafoiaee"
                    width="162.8%"
                    height="202.1%"
                    x="-31.4%"
                    y="-49%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="l03v2qdm0g"
                    width="140.6%"
                    height="322.7%"
                    x="-20.3%"
                    y="-106.8%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <text
                    id="xwetlod9cb"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="18"
                    fontWeight="bold"
                >
                    <tspan x="286" y="104">
                        {time}
                    </tspan>
                </text>
                <text
                    id="pctk221s9d"
                    fill="#FFF"
                    fontFamily="JosefinSans-Light, Josefin Sans"
                    fontSize="20"
                    fontWeight="300"
                >
                    <tspan x="11" y="70">
                    {weatherTypePT}
                    </tspan>
                </text>
                <text
                    id="m8r0yfxbsf"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="40"
                    fontWeight="bold"
                >
                    <tspan x="11" y="43">
                        {temperatura}
                    </tspan>
                </text>
                <text
                    id="9z6c2th2lh"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="18"
                    fontWeight="bold"
                >
                    <tspan x="9.882" y="104">
                        {station}
                    </tspan>
                </text>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#3A7BD5"
                    d="M27.511 10H321.49c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 20.793 342 23.38 342 30.511v73.846c0 7.132-.743 9.718-2.137 12.325a14.538 14.538 0 01-6.048 6.049c-2.608 1.394-5.194 2.137-12.326 2.137H27.51c-7.132 0-9.718-.743-12.326-2.137a14.538 14.538 0 01-6.048-6.049C7.743 114.075 7 111.49 7 104.357V30.51c0-7.132.743-9.718 2.137-12.326a14.538 14.538 0 016.048-6.048C17.793 10.743 20.38 10 27.511 10z"
                ></path>
                <path
                    fill="#114884"
                    d="M321.489 10c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 20.793 342 23.38 342 30.511V43H144.997C131.192 43 120 31.809 120 17.993V10h201.489z"
                ></path>
                <path
                    fill="#3A6073"
                    d="M324.577 90.022c.937 0 1.844.052 2.72.153H342v17.27c0 3.562-.749 6.685-2.142 9.266a14.674 14.674 0 01-6.014 6.014c-2.582 1.394-5.705 2.143-9.267 2.143H24.423c-3.562 0-6.685-.749-9.267-2.143a14.674 14.674 0 01-6.014-6.014C7.75 114.131 7 111.007 7 107.445l.004-.424H7V90.177h14.704a23.524 23.524 0 012.719-.154h300.154z"
                ></path>
                <path
                    fill="#3E799B"
                    d="M328.87 23c5.025 0 9.617 1.852 13.131 4.91v30.18A19.932 19.932 0 01328.87 63H187.006C175.957 63 167 54.048 167 43c0-11.046 8.957-20 20.006-20H328.87z"
                ></path>
                <path
                    fill="#4D97C7"
                    d="M331.992 43c3.646 0 7.064.974 10.008 2.677v34.645A19.92 19.92 0 01331.992 83h-98.984C221.958 83 213 74.048 213 63c0-11.046 8.96-20 20.008-20h98.984z"
                ></path>
                <path
                    fill="#FCE765"
                    fillRule="nonzero"
                    d="M168.945 57.02a.66.66 0 00-.605-.395h-15.972l6.636-13.541c-7.443-.122-11.204-.111-11.28.032l-11.646 21.776a.66.66 0 00.582.971h13.53l-7.54 18.224a.66.66 0 001.094.702l25.08-27.055a.66.66 0 00.12-.713z"
                ></path>
                <g fill="#FFF" transform="translate(7 10)">
                    <use filter="url(#bwh0tnftla)" xlinkHref="#xwetlod9cb"></use>
                    <use xlinkHref="#xwetlod9cb"></use>
                </g>
                <g fill="#FFF" transform="translate(7 10)">
                    <use filter="url(#j94gslrcnc)" xlinkHref="#pctk221s9d"></use>
                    <use xlinkHref="#pctk221s9d"></use>
                </g>
                <g fill="#FFF" transform="translate(7 10)">
                    <use filter="url(#a6xafoiaee)" xlinkHref="#m8r0yfxbsf"></use>
                    <use xlinkHref="#m8r0yfxbsf"></use>
                </g>
                <g fill="#FFF" transform="translate(7 10)">
                    <use filter="url(#l03v2qdm0g)" xlinkHref="#9z6c2th2lh"></use>
                    <use xlinkHref="#9z6c2th2lh"></use>
                </g>
            </g>
        </svg>
    );
}

export default Icon;
