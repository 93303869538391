import { Col, Row, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import filterIcon from "../../../../assets/icons/filter.svg";
import pin from "../../../../assets/icons/pin.svg";
import Menu from "../../../../components/menu";
import { MenuContext } from "../../../../context/menu";
import api from "../../../../services/api";
import Hour from "../chart/hour";
import Month from "../chart/month";
import Day from "../chart/day";
import Statistics from "../statistics";
import moment from "moment-timezone";

const Occupation = () => {
    const [camera, setCamera] = useState();
    const [cameras, setCameras] = useState([]);
    const { Option } = Select;
    const { data } = useContext(MenuContext);
    const [dateStart, setDateStart] = useState(data.start);
    const [dateEnd, setDateEnd] = useState(data.stop);
    const enter = '2';
    const exit = '1';

    const onClickedMenu = (data) => {
        let { start, end } = data;
        setDateStart(start);
        setDateEnd(end);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                let { data } = await api.get(`/monitoring/camera/list`, {
                    params: {
                        name: null,
                    }
                })
                setCameras(data);
                if (Array.isArray(data) && data.length > 0) {
                    setCamera(data[0]?.id);
                }
            } catch (error) {
                setCameras([]);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Menu onClicked={onClickedMenu} />
            <Row gutter={16}>
                <Col xs={24} lg={12}>
                    <div className="filters">
                        <img src={filterIcon} alt="" /> Filtros
                    </div>
                    <div className="finalcialFilters">
                        <img alt=""
                            src={pin}
                            style={{ height: "1em" }}
                        />
                        <Select
                            placeholder="Seleione a câmera"
                            value={camera}
                            onChange={(newValue) => setCamera(newValue)}
                            className="selectCamera">
                            {cameras?.map((c) => <Option value={c.id}>{c.name}</Option>)}
                        </Select>
                    </div>
                    <Statistics camera={camera} dateStart={dateStart} dateEnd={dateEnd} enter={enter} exit={exit} />
                </Col>
                <Col xs={24} lg={12}>
                    <Hour camera={camera} dateStart={dateStart} dateEnd={dateEnd} enter={enter} exit={exit} />
                </Col>
                <Col xs={24} md={12}>
                    <Day camera={camera} dateStart={dateStart} dateEnd={dateEnd} enter={enter} exit={exit} />
                </Col>
                <Col xs={24} md={12}>
                    <Month camera={camera} dateStart={dateStart} dateEnd={dateEnd} enter={enter} exit={exit} />
                </Col>
            </Row>
        </>
    );
}

export default Occupation;