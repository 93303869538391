import { Col, Row } from "antd";
import { MenuContext } from "../../../context/menu.js";
import React, { useContext, useState } from "react";


import "../style.css";
import Statistic from "./statistic/index.js";
import TotalConsumed from "./totalConsumed/index.js";
import SupplyTable from "./supplyTable/index.js";
import Menu from "../../../components/menu/index.js";



const Hydrogen = () => {

  const { data } = useContext(MenuContext);
  const [dateStart, setDateStart] = useState(data.start);
  const [dateEnd, setDateEnd] = useState(data.stop);

  const onClickedMenu = (data) => {
    let { start, end } = data;
    setDateStart(start);
    setDateEnd(end);
  };

  return (
    <Row gutter={24}>
      <Col xs={24}>
        <Menu onClicked={onClickedMenu}></Menu>
      </Col>
      <Col xs={24} lg={12}>
        <Statistic dateStart={dateStart} dateEnd={dateEnd} />
      </Col>
      <Col xs={24} lg={12}>
        <SupplyTable dateStart={dateStart} dateEnd={dateEnd} />
      </Col>
      <Col xs={24}>
        <TotalConsumed dateStart={dateStart} dateEnd={dateEnd} />
      </Col>
    </Row>
  );
};

export default Hydrogen;
