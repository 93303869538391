import { Col, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import icon from "../../../assets/icons/co2.svg";
import gasIcon from "../../../assets/icons/gas.svg";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import "./style.css";

const Co2 = ({ dateStart, dateEnd }) => {

  const [consumer, setConsumer] = useState(0);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)

  const fetchConsumer = () => {
    setLoading(true);
    setError(undefined);
    api
      .post("/chargers/sessions/total/consumer", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
      })
      .then((response) => {
        setConsumer(response.data);
      })
      .catch((error) => {
        setError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const calcPoupado = (value) => {
    if (value > 0) {
      let _value = (value / 1000) //kW/h;
      _value = _value * 0.220;
      return  format(_value.toFixed(2),2);
    }
    return value;
  }

  const calcLitros = (value) => {
    if (value > 0) {
      let _value = (value / 1000) //kW/h;
      _value = _value * 0.302;
      return format(_value.toFixed(2),2);
    }
    return value;
  }


  function format(value, decimal) {
    return Number(value).toLocaleString(
      undefined,
      { minimumFractionDigits: decimal }
    );
  }

  useEffect(fetchConsumer, [dateStart, dateEnd]);

  return (
    <div style={{ marginTop: "1em" }}>
      <Row>
        <Col xs={24}>
          <span>Emissão de CO2</span>
        </Col>
      </Row>

      {loading ? (
        <Loading />
      ) : error ? (
        <WidgetError
          message={`${error}`}
          refresh={() => fetchConsumer()}
        />
      ) :
        (
          <Row className="co2_content" style={{ marginBottom: "1em" }}>

            <Col xs={24} md={6} className="margin-top-mobile">
              <img src={icon} alt="" />
              <img src={gasIcon} style={{ position: "absolute", marginLeft: "-5px" }} alt="" />
            </Col>

            <Col xs={24} md={9} className="margin-top-mobile">
              <div style={{ marginBottom: "-0.5em" }} className="margin-top-mobile">
                <span className="co2_title">
                  {calcPoupado(consumer.value)} kg
                </span>
              </div>
              <span className="span_co2_title">
                de CO2 poupado
              </span>
              <br />
              <div className="co2_group"></div>
            </Col>

            <Col xs={24} md={9} className="margin-top-mobile">
              <div style={{ marginBottom: "-0.5em" }} className="margin-top-mobile">
                <span className="co2_title">
                  {calcLitros(consumer.value)} Litros
                </span>
              </div>
              <span className="span_co2_title">
                de combustível substituído
              </span>
              <br />
              <div className="co2_group"></div>
            </Col>
          </Row>
        )}
    </div>
  );
};

export default Co2;
