import { Col, Row } from "antd";
import React from "react";
import "../style.css";
import icon from "../../../../assets/icons/syringe.svg";

const Vaccination = ({ vaccinated }) => {
  return (
    <div className="card_covid">
      <Row>
        <Col xs={24}>
          <img src={icon} alt="" style={{ width: "40px" }} />
        </Col>
        <Col xs={24} style={{ paddingTop: "1em" }}>
          <span>{new Intl.NumberFormat("portugal").format(vaccinated)}</span>
        </Col>
        <Col xs={24}>
          <span>vacinações completa</span>
        </Col>
      </Row>
    </div>
  );
};

export default Vaccination;
