import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React from "react";
import "./style.css";

const _Map = ({ google }) => {

    const _center = { lat: 41.22920295539571, lng: -8.632621498893629 }

    return (
        <>
            <span>
                Parque de estacionamentos
            </span>
            <div className='parking_map'>
                <Map
                    initialCenter={_center}
                    center={_center}
                    google={google}
                    zoom={13}
                    disableDefaultUI={true}
                    className="occurrences_map"
                    style={{ borderRadius: "16px" }}
                    fullscreenControl={false}
                    zoomControl={false}
                    streetViewControl={false}
                    mapTypeControl={false}>
                    <Marker position={_center} name={'Barreiro, Portugal'} />
                </Map>
            </div>
        </>
    )
}

export default GoogleApiWrapper({
    apiKey: (`${process.env.REACT_APP_GOOGLE_MAPS_TOKEN}`)
})(_Map)