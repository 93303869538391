
import { Empty } from "antd"
import React, { useEffect, useState } from "react"
import Chart from "react-apexcharts"
import api from "../../../services/api"
import { capitalize } from "../../../util/StringUtil"

const Job = ({ localSelected, identifier, polygon }) => {

  const [series, setSeries] = useState([])
  const [categories, setCategories] = useState([])


  const properties = [
    "n_ind_resid_desemp_proc_emprg",
    "n_ind_resid_empregados",
    "n_ind_resid_empreg_sect_prim",
    "n_ind_resid_desemp_proc_1emprg",
    "n_ind_resid_empreg_sect_terc",
    "n_ind_resid_estud_mun_resid",
    "n_ind_resid_pens_reform",
    "n_ind_resid_sem_act_econ",
    "n_ind_resid_trab_mun_resid"
  ]

  function handleSeries(_data) {
    let _series = []
    _data.forEach(i => {
      switch (i.name) {
        case "n_ind_resid_desemp_proc_emprg":
          _series.push({ value: i.value, index: 1 })
          break
        case "n_ind_resid_empregados":
          _series.push({ value: i.value, index: 2 })
          break
        case "n_ind_resid_empreg_sect_prim":
          _series.push({ value: i.value, index: 3 })
          break
        case "n_ind_resid_desemp_proc_1emprg":
          _series.push({ value: i.value, index: 4 })
          break
        case "n_ind_resid_empreg_sect_terc":
          _series.push({ value: i.value, index: 5 })
          break
        case "n_ind_resid_estud_mun_resid":
          _series.push({ value: i.value, index: 6 })
          break
        case "n_ind_resid_pens_reform":
          _series.push({ value: i.value, index: 7 })
          break
        case "n_ind_resid_sem_act_econ":
          _series.push({ value: i.value, index: 8 })
          break
        case "n_ind_resid_trab_mun_resid":
          _series.push({ value: i.value, index: 9 })
          break
        default:
      }
    })
    setSeries(_series.sort(function (a, b) { return a.index - b.index }).map(i => i.value))
  }

  function fetchData(localSelected) {
    api.get("geoservices/features/group/count/properties", {
      params: {
        local: localSelected.local ? localSelected.local : undefined,
        bgri: localSelected.bgri ? localSelected.bgri : undefined,
        search: localSelected.search ? localSelected.search : undefined,
        properties: properties.toString()
      }
    }).then((response) => { handleSeries(response.data || []) })
  }


  function fetchDesc(name) {
    let indent = identifier.find(i => i.codigo === name)
    return (indent && indent.desc ? indent.desc.substring(22, indent.desc.length) : name).trim()
  }

  useEffect(() => {
    if (localSelected && (localSelected.local || localSelected.search))
      fetchData(localSelected)
  }, [localSelected])

  useEffect(() => {
    if (identifier) {
      let _categories = [
        capitalize(fetchDesc("n_ind_resid_desemp_proc_emprg")),
        capitalize(fetchDesc("n_ind_resid_empregados")),
        capitalize(fetchDesc("n_ind_resid_empreg_sect_prim")),
        capitalize(fetchDesc("n_ind_resid_desemp_proc_1emprg")),
        capitalize(fetchDesc("n_ind_resid_empreg_sect_terc")),
        capitalize(fetchDesc("n_ind_resid_estud_mun_resid")),
        capitalize(fetchDesc("n_ind_resid_pens_reform")),
        capitalize(fetchDesc("n_ind_resid_sem_act_econ")),
        capitalize(fetchDesc("n_ind_resid_trab_mun_resid"))
      ]
      setCategories(_categories)
    }
  }, [identifier])

  useEffect(() => {
    if (polygon && polygon.bgri)
      fetchData(polygon)
  }, [polygon])

  return (
    <>
      <span>
        Situação de Emprego
      </span>
      <div className="properties-content dunet" >
        {series.length > 0 ? <Chart
          options={{
            chart: {
              type: 'donut',
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  offset: +42,
                  minAngleToShowLabel: 5,
                },
                donut: {
                  size: 45
                }
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 280
                },
                legend: {
                  position: 'bottom'
                }
              }
            }],
            dataLabels: {
              style: { colors: ['#000'], },
              formatter: function (val, opts) {
                return opts.w.globals.series[opts.seriesIndex];
              },
            },
            labels: categories,
            colors: ['#1f2e95', '#7b3b9b', '#669DB5', '#df759e', '#ff9fa6', '#d07699', '#669DB5', '#99548b', '#5d3977', '#15235d'],
          }}
          series={series}
          type="donut"
          height="100%"
          width="100%"
        /> : <Empty description="Selecione uma opção no filtro"></Empty >}

      </div>
    </>
  )
}

export default Job;
