import React from "react";
import "./style.css";

const Widget = ({ title, value }) => {


    return (
        <div className="buildingsEnergyConsumptionWidget">
            <div className="value">
                {value}
            </div>
            <div className="title">
                {title}
            </div>
        </div>
    );
}

export default Widget;