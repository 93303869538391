import React from "react"
import "../../style.css";
import { Link } from "react-router-dom";
const Walkabilitysimulator = () => {

    const partner = <Link to="/ctec/contacts?partner=NOVA-IMS">NovaIMS</Link>

    return (<div><iframe className="frame-content" src="https://app.powerbi.com/view?r=eyJrIjoiNDJhZmIxMDAtNzE3OC00YTBmLWE1MjItNjRmYjcwMGM2YzcyIiwidCI6ImU0YmQ2OWZmLWU2ZjctNGMyZS1iMjQ3LTQxYjU0YmEyNDkwZSIsImMiOjh9&pageName=ReportSection605f9041338c62bb0273" title="Walkability Simulator"></iframe>
    <div><p>Built BY {partner}</p></div></div>)
}

export default Walkabilitysimulator