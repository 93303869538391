import { Col, Row } from "antd";
import React from "react";
import Menu from "../../../components/menu";
import Chart from "./chart";
import ElecticityCost from "./electricityCost";
import GasCost from "./gasCost";
import MenuShopping from "./menu";
import VisitorCost from "./visitorCost";
import WaterCost from "./waterCost";
import StatisticPanel from "./statisticsPanel";

const ColomboShopping = () => {
  return (
    <>
      <div>
        <Menu />
      </div>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <VisitorCost />
          <WaterCost />
          {/* <GasCost /> */}
          <ElecticityCost />
          <Chart />
        </Col>
        <Col xs={24} lg={12}>
          {/* <MenuShopping values={["Todos", "Contadores de água","Contadores de Electricidade", "Unidade de tratamento de ar", "Instalação Sanitária", "Hottes (Lojas)"]} /> */}
          <StatisticPanel
            title="Ocorrências"
            childens={[
              {
                icon: "numero_total_ocorrencias.svg",
                description: "Número Total de Ocorrências",
                value: "5 ocorrências",
              },
              {
                icon: "numero_total_ocorrencias_curso.svg",
                description: "Número Total de Ocorrências em curso",
                value: "1 ocorrências",
              },
            ]}
          />

          <StatisticPanel
            title="Trabalhos"
            childens={[
              {
                icon: "numero_total_trabalhos_concluidos.svg",
                description: "Número Total de Trabalhos Concluídos",
                value: "4 trabalhos",
              },
              {
                icon: "numero_total_trabalhos_planeados.svg",
                description: "Número Total de Trabalhos Planejados",
                value: "3 totais",
              },
            ]}
          />

          <StatisticPanel
            title="Ativos em monotorização"
            childens={[
              {
                icon: "numero_contadores_agua.svg",
                description: "Número de contadores de água",
                value: "2 contadores",
              },
              // {
              //     icon: "numero_contadores_gas.svg",
              //     description: "Número de contadores de gás",
              //     value: "3 contadores"
              // }
              // ,
              {
                icon: "numero_contadores_eletricidade.svg",
                description: "Número de contadores de electricidade",
                value: "6 contadores",
              },
              {
                icon: "unidade_tratamento_ar.svg",
                description: "Unidade Tratamento do Ar",
                value: "2 unidades",
              },
              {
                icon: "instalacao_sanitaria.svg",
                description: "Instalação Sanitária",
                value: "12 unidades",
              },
              {
                icon: "total_hattes.svg",
                description: "Total de hottes (lojas)",
                value: "3 hottes (lojas)",
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default ColomboShopping;
