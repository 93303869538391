import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import "./style.css";
import Widget from "./widget";

const Statistic = ({ dateStart, dateEnd }) => {
  const [totalSuppliesLoading, setTotalSuppliesLoading] = useState(false);
  const [totalSuppliesError, setTotalSuppliesError] = useState();
  const [totalSupplies, setTotalSupplies] = useState(0);
  const fetchTotalSupplies = () => {
    setTotalSuppliesLoading(true);
    setTotalSuppliesError(undefined);
    api
      .post("/hydrogen/supply/total/supplies", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
      })
      .then((response) => {
        setTotalSupplies(response.data);
      })
      .catch((error) => {
        setTotalSuppliesError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setTotalSuppliesLoading(false);
      });
  };

  const [totalConsumedLoading, setTotalConsumedLoading] = useState(false);
  const [totalConsumedError, setTotalConsumedError] = useState();
  const [totalConsumed, setTotalConsumed] = useState(0);
  const fetchTotalConsumed = () => {
    setTotalConsumedLoading(true);
    setTotalConsumedError(undefined);
    api
      .post("/hydrogen/supply/total/consumed", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
      })
      .then((response) => {
        setTotalConsumed(response.data);
      })
      .catch((error) => {
        setTotalConsumedError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setTotalConsumedLoading(false);
      });
  };

  const [averageConsumedLoading, setAverageConsumedLoading] = useState(false);
  const [averageConsumedError, setAverageConsumedError] = useState();
  const [averageConsumed, setAverageConsumed] = useState(0);
  const fetchAverageConsumed = () => {
    setAverageConsumedLoading(true);
    setAverageConsumedError(undefined);
    api
      .post("/hydrogen/supply/average/consumed", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
      })
      .then((response) => {
        setAverageConsumed(response.data);
      })
      .catch((error) => {
        setAverageConsumedError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setAverageConsumedLoading(false);
      });
  };

  const [averageConsumedWeekLoading, setAverageConsumedWeekLoading] =
    useState(false);
  const [averageConsumedWeekError, setAverageConsumedWeekError] = useState();
  const [averageConsumedWeek, setAverageConsumedWeek] = useState(0);
  const fetchAverageConsumedWeek = () => {
    setAverageConsumedWeekLoading(true);
    setAverageConsumedWeekError(undefined);
    api
      .post("/hydrogen/supply/average/consumed/week", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
      })
      .then((response) => {
        setAverageConsumedWeek(response.data);
      })
      .catch((error) => {
        setAverageConsumedWeekError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setAverageConsumedWeekLoading(false);
      });
  };

  const [averageConsumedDurationLoading, setAverageConsumedDurationLoading] =
    useState(false);
  const [averageConsumedDurationError, setAverageConsumedDurationError] =
    useState();
  const [averageConsumedDuration, setAverageConsumedDuration] = useState(0);
  const fetchAverageConsumedDuration = () => {
    setAverageConsumedDurationLoading(true);
    setAverageConsumedDurationError(undefined);
    api
      .post("/hydrogen/supply/average/consumed/duration", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
      })
      .then((response) => {
        setAverageConsumedDuration(response.data);
      })
      .catch((error) => {
        setAverageConsumedDurationError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setAverageConsumedDurationLoading(false);
      });
  };

  function format(value, decimal) {
    return Number(value).toLocaleString(
      undefined,
      { minimumFractionDigits: decimal }
    );
  }

  function milisecondToTime(sec) {
    sec = sec / 1000;
    var dateObj = new Date(sec * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var timeString =
      hours.toString().padStart(2, "0") +
      "h " +
      minutes.toString().padStart(2, "0") +
      "m";
    return timeString;
  }

  const [loadingCurrentIndicators, setLoadingCurrentIndicators] = useState(false); 
  const [currentProduction, setCurrentProduction] = useState(0);
  const [currentStorage, setCurrentStorage] = useState(0);

  async function fetchLastInfo() {
    setLoadingCurrentIndicators(true);
    try{
      let indicators = await api.get("/hydrogen/indicators/names");
      let response = await api.get("/hydrogen/indicators/last", {
        params: {
            names:  indicators.data.join(","),
        }
      });
      let sumKm = response?.data?.filter((ele) => ele?.data?.name?.includes("massa_")).reduce( (total, ele) => total + ele.value, 0 );
      setCurrentStorage(sumKm);
  
      let sumCd = response?.data?.filter((ele) => ele?.data?.name?.includes("cp_")).reduce( (total, ele) => total + ele.value, 0 );
      setCurrentProduction(sumCd);
    }catch(err){
      console.error(err);
    }finally{
      setLoadingCurrentIndicators(false);
    }
  }

  useEffect(() => {
    fetchTotalSupplies();
    fetchTotalConsumed();
    fetchAverageConsumed();
    fetchAverageConsumedWeek();
    fetchAverageConsumedDuration();
    fetchLastInfo();
  }, [dateStart, dateEnd]);

  return (
    <>
      <span>Informações Instantâneas</span>
      <div>
        <Widget
          icon="total.svg"
          title="Hidrogénio disponível"
          value={`${parseFloat(currentStorage).toLocaleString()} Kg`}
          loading={loadingCurrentIndicators}
          error={undefined}
          reload={fetchLastInfo}
        />
        <Widget
          icon="bar.svg"
          title="Produção atual"
          value={`${parseFloat(currentProduction).toLocaleString()} Nm3/h`}
          loading={loadingCurrentIndicators}
          error={undefined}
          reload={fetchLastInfo}
        />
      </div>
      <span>Informação Geral Hidrogénio</span>
      <div>
        <Widget
          icon="total_abastecimento.png"
          title="Total de abastecimentos"
          value={`${totalSupplies.value} abastecimentos`}
          loading={totalSuppliesLoading}
          error={totalSuppliesError}
          reload={fetchTotalSupplies}
        />
        <Widget
          icon="total_km_consumido.png"
          title="Total de kg consumidos"
          value={`${format(totalConsumed.value, 3)} Kg`}
          loading={totalConsumedLoading}
          error={totalConsumedError}
          reload={fetchTotalConsumed}
        />
        <Widget
          icon="media_abastencimento.png"
          title="Média de cada abastecimento"
          value={`${format(averageConsumed.value, 3)} Kg`}
          loading={averageConsumedLoading}
          error={averageConsumedError}
          reload={fetchAverageConsumed}
        />
        <Widget
          icon="media_duracao_abastecimento.png"
          title="Média da duração de abastecimento"
          value={`${milisecondToTime(averageConsumedDuration.value)}`}
          loading={averageConsumedDurationLoading}
          error={averageConsumedDurationError}
          reload={fetchAverageConsumedDuration}
        />
      </div>
    </>
  );
};

export default Statistic;
