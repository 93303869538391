import { UserOutlined } from "@ant-design/icons";
import { useKeycloak } from "@react-keycloak/web";
import { Avatar, Badge } from "antd";
import React, { useContext } from "react";
import calendar from "../../assets/images/calendar.svg";
import question from "../../assets/images/question.svg";
import { AuthContext } from "../../context/auth";
import { getToken } from "../../services/api";

const Header = () => {

  const { hasAccess, user } = useContext(AuthContext);
  const { initialized, keycloak } = useKeycloak()
  let cteck = hasAccess("ctec")

  const handleProfile = () => {
    keycloak.accountManagement()
  };

  return (
    <nav>
      <ul className="itens-header">
        <li>
          <img src={question} alt="Question" className="question"></img>
        </li>
        <li>
          <img src={calendar} alt="Calendar" className="calendar"></img>
        </li>
        {
          (initialized && keycloak.authenticated) && <>
            <li id="system-title">
              {cteck ? <h3>Integrated Urban Management Platform</h3> : <h3>Plataforma Integrada de Gestão</h3>}

            </li>
            <li className='li-divider'>
              {/* Divider element, not remove */}
            </li>
          </>
        }

        <li>
          {user?.profile ? (
            <Badge>
              <img
                alt="imagem perfil"
                title="Acessar perfil"
                onClick={handleProfile}
                style={{ borderRadius: "50%" }}
                width={65}
                height={65}
                src={
                  user?.profile
                    ? `${process.env.REACT_APP_API_URL}/storage/render/${user?.profile
                    }/${btoa(getToken())}`
                    : undefined
                }
                icon="user"
              />
            </Badge>
          ) : (
            <Avatar size={44} icon={<UserOutlined />} onClick={handleProfile} />
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Header;
