import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import LoadingTime from "./loadingTime";
import SuppliedEnergy from "./suppliedEnergy";
import TotalLoads from "./totalLoads";
import "./style.css";
import api from "../../../services/api";
import moment from "moment-timezone";

const Widget = ({ dateStart, dateEnd }) => {

    const [sessions, setSessions] = useState({ value: 0 });
    const [consumer, setConsumer] = useState({ value: 0 });
    const [duration, setDuration] = useState({ value: 0 });
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined)

    const fetchConsumer = () => {
        setLoading(true);
        setError(undefined);
        api
            .post("/chargers/sessions/total/consumer", {
                dateStart: dateStart ? moment(dateStart).utc() : undefined,
                dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
            })
            .then((response) => {
                setConsumer(response.data);
            })
            .catch((error) => {
                setError(
                    error?.response?.data?.message || "unknown error"
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchSessions = () => {
        setLoading(true);
        setError(undefined);
        api
            .post("/chargers/sessions/total/sessions", {
                dateStart: dateStart ? moment(dateStart).utc() : undefined,
                dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
            })
            .then((response) => {
                setSessions(response.data);
            })
            .catch((error) => {
                setError(
                    error?.response?.data?.message || "unknown error"
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchDuration = () => {
        setLoading(true);
        setError(undefined);
        api
            .post("/chargers/sessions/total/duration", {
                dateStart: dateStart ? moment(dateStart).utc() : undefined,
                dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
            })
            .then((response) => {
                setDuration(response.data);
            })
            .catch((error) => {
                setError(
                    error?.response?.data?.message || "unknown error"
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };



    function milisecondToTime(milissegundo) {
        if (milissegundo !== undefined && milissegundo != null) {
            var ms = milissegundo % 1000;
            milissegundo = (milissegundo - ms) / 1000;
            var secs = milissegundo % 60;
            milissegundo = (milissegundo - secs) / 60;
            var mins = milissegundo % 60;
            var hrs = (milissegundo - mins) / 60;
            return hrs + ' h  ' + mins + 'm'
        }
        return 0;
    }


    useEffect(() => {
        fetchConsumer();
        fetchDuration();
        fetchSessions();
    }, [dateStart, dateEnd]);

    return (
        <div className="eletricMobilityWidgetContent">
            <span>
                Mobilidade Elétrica
            </span>
            {<Row gutter={12}>
                <Col xs={24} md={8}>
                    <SuppliedEnergy value={consumer.value} />
                </Col>
                <Col xs={24} md={8}>
                    <LoadingTime value={milisecondToTime(duration.value)} />
                </Col>

                <Col xs={24} md={8}>
                    <TotalLoads value={sessions.value} />
                </Col>
            </Row>}
        </div>
    );
}

export default Widget;