import React from "react";
import "./style.css";

const SuppliedEnergy = ({ value }) => {

    function format(value, decimal) {
        if (value > 0) {
            return Number(value).toLocaleString(
                undefined,
                { minimumFractionDigits: decimal }
            );
        } return 0;
    }

    return (
        <div className="eletricMobilityWidget suppliedEnergy">
            <h2>{format(value / 1000, 2)} kWh</h2>
            <span>
                Energia fornecida
            </span>
        </div>
    );
}

export default SuppliedEnergy;