import React from "react";
import { Link } from "react-router-dom";

import ctechLogo from "../../../assets/images/ctech_main_logo.png"
//import SelectEventHandler from "../../../../node_modules/rc-menu/lib/interface.d.ts"

const Home = () => {
  const ctechPage = <a target="_blank" href={"https://www.ctechplatform.com"}>www.ctechplatform.com</a>
  const contacts = <Link to="ctec/contacts">Contacts</Link>
  const modelsPage = <Link to="ctec/building"
                      style={{textAlign:"center", fontSize:"1.2em", fontWeight:"bold", textDecoration:"underline"}}> MODELS </Link>
  
  return (
    <div className="cteck-home">
      <div className="cteck-content" style={{ fontSize: "1.0em", color: "white", padding: "8px" }}>
        
        <div className="ctitle" style={{ fontSize: "3.0em", color: "#000", textAlign: "center"}} >
          <img src={ctechLogo} style={{ maxWidth:"35%"}}/>
        </div>
        <p>The C-Tech Smart Cities platform is an initiative involving a pilot area in Lisbon (Beato, Marvila and Parque das Nações).</p><br />
        <p>This digital platform aims to support planning and management decisions for the city, with a view to optimizing energy consumption in buildings, improving vehicle and pedestrian mobility, as well as increasing the potential for urban agriculture. </p><br />
        <p>The project directly contributes towards two strategic research topics, namely 1) Climate and Climate Change and 2) Sustainable Cities.</p><br />
        <p>C-Tech platform is part of C-Tech project, financed by European funds, which started in 2020. It is based on a partnership between NOS, CEiiA, MIT, IST, NOVA IMS and Lisboa E-Nova (see {contacts}). Know more about C-Tech project at {ctechPage} .</p><br/>
        <p>C-Tech invites you to evaluate several scenarios resulting from Models developed during the project. Please select one topic in the menu.</p><br/>
        <p style={{textAlign:"center"}}> {modelsPage} </p>
      </div>
    </div>
  );
};

export default Home;
