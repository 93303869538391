import React from "react";
import Chart from "react-apexcharts";
import data from "./data.json";
import "./style.css";

var pt = require("apexcharts/dist/locales/pt.json");

const ChartData = ({ id, title, series, categories, symbol, type, xSymbol, xaxis = {}, options = {}, yaxis = {} }) => {

    return (
        <>
            <span>
                {title}
            </span>
            <div className="financial_chart">
                <Chart
                    options={{
                        ...data.options,
                        yaxis: {
                            labels: {
                                formatter: function (val, index) {
                                    return `${val.toLocaleString()} ${symbol ? symbol : ''}`;
                                }
                            },
                            ...yaxis
                        },
                        xaxis: {
                            ...data.options.xaxis,
                            categories: categories,
                            labels: {
                                formatter: function (val, index) {
                                    return `${val}${xSymbol ? xSymbol : ''}`;
                                }
                            },
                            ...xaxis
                        },
                        chart: {
                            id: id,
                            locales: [pt],
                            defaultLocale: "pt",
                            toolbar: { show: false },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                borderRadius: 10
                            },
                        },
                        ...options
                    }}
                    series={series}
                    type={type}
                    height={250}
                    width="100%" />

            </div>
        </>
    );
}

export default ChartData;