import { Col, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import data from "./data.json";
import "./style.css";

var pt = require("apexcharts/dist/locales/pt.json");

const Graphic = ({ dateStart, dateEnd }) => {
  const [series, setSeries] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [totalConsumptionPrevious, setTotalConsumptionPrevious] = useState(0);
  const [totalConsumptionPeriod, setTotalConsumptionPeriod] = useState(0);
  const [tariff, setTariff] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    setError(false);
    if (!totalConsumptionPrevious) await fetchTotalConsumptionPrevious();
    if (!tariff) await fetchTariff();
    await fetchTotalConsumptionPeriod();
    setLoading(false);
  };

  const fetchTotalConsumptionPrevious = () => {
    api
      .get("/lighting/lightFixture/total/consumption/previous")
      .then((response) => {
        setTotalConsumptionPrevious(response.data.count);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      });
  };

  const fetchTariff = () => {
    api
      .get("/lighting/tariff/find/last", {})
      .then((response) => {
        setTariff(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      });
  };

  const fetchTotalConsumptionPeriod = () => {
    api
      .post("/assets/telemetry/count/consumption/period/chart", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
      })
      .then((response) => {
        setTotalConsumptionPeriod(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      });
  };

  const handleTotalConsumptionPeriod = () => {
    if (totalConsumptionPeriod)
      setSeries(generateSeries(totalConsumptionPeriod));
  };

  const generateSeries = (_data = []) => {
    let dates = [];
    let current = [];
    _data.forEach((element) => {
      if (!dates.includes(element.date)) dates.push(element.date);
      current.push(element);
    });
    dates.sort(function (a, b) {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    });
    setCategorys(dates);
    let total = (tariff.tariff * (totalConsumptionPrevious / 365));
    return [
      {
        name: "Atual",
        data: dates?.map((_date) =>
        (
          tariff.tariff *
          (current.find((_current) => _current.date === _date)?.count || 0)
        )
        ),
      },
      {
        name: "Anterior",
        data: dates?.map((_date) => {
          return total;
        }),
      },
    ];
  };

  useEffect(() => {
    handleTotalConsumptionPeriod();
  }, [totalConsumptionPeriod, totalConsumptionPrevious]);

  useEffect(() => {
    fetchData();
  }, [dateStart, dateEnd]);

  useEffect(() => {
    fetchTotalConsumptionPrevious();
    fetchTariff();
  }, []);

  return (
    <>
      <Col xs={24} className="margin-top-mobile">
        <span>Gráfico de distribuição dos custos</span>
      </Col>

      {loading ? (
        <Loading />
      ) : error ? (
        <WidgetError message={`${error}`} refresh={() => fetchData()} />
      ) : (
        <div className="graphic_content">
          <Row gutter={16}>
            <Col xs={24}>
              <div id="chart-ticket-stats" className="graphic_chart">
                <Chart
                  options={{
                    ...data.options,
                    yaxis: {
                      labels: {
                        formatter: function (value) {
                          return new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 4
                          }).format(value);
                        },
                      },
                    },
                    xaxis: {
                      type: "datetime",
                      categories: categorys,
                    },
                    chart: {
                      locales: [pt],
                      defaultLocale: "pt",
                      toolbar: { show: false },
                    },
                  }}
                  series={series}
                  type="line"
                  height="230"
                />
              </div>
            </Col>
            <Col xs={24} md={12}>
              <span style={{ color: "#979797" }}>
                1. Evolução do custo total com IP (€) por ano
              </span>
            </Col>
            <Col xs={24} md={12}>
              <span style={{ color: "#979797" }}>
                2. Evolução do custo total com IP (€) por ano anterior ao
                contrato (baseline)
              </span>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Graphic;
