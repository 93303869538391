import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import bar from "../../../../assets/icons/hydrogen/bar.svg";
import total from "../../../../assets/icons/hydrogen/total.svg";
import api from "../../../../services/api";
import { number } from "../../../../util/Format";


const Indicators = ({ names, rackSelected }) => {

    const [indicators, setIndicators] = useState({})

    const fetchIndicators = () => {
        api.get("/hydrogen/indicators/last", {
            params: {
                names: rackSelected === 'all' ? [names.filter(i => i.includes("massa") || i.includes("bar"))].toString() : [names.filter(i => i.includes(rackSelected))].toString(),
                aggregationType: "YEAR"
            }
        }).then((response) => {
            let bar = response.data.filter(i => i.data.name.includes('bar')).reduce((r, q) => {
                return (r + q.value);
            }, 0)
            let count = response.data.filter(i => i.data.name.includes('bar')).length;
            let massa = response.data.filter(i => i.data.name.includes('massa')).reduce((r, q) => {
                return (r + q.value);
            }, 0)
            setIndicators({ bar: bar/count, massa: massa })
        })

    }

    useEffect(() => { if (rackSelected) fetchIndicators() }, [rackSelected])

    return (

        <>
            <div className="solarWidget">
                <Row>
                    <Col xs={24} md={4} style={{ textAlign: "center" }}>
                        <img alt="" src={total} />
                    </Col>
                    <Col xs={24} md={10}>
                        <span className="wasteFleetsDescription">
                            Massa total
                        </span>
                    </Col>
                    <Col xs={24} md={10}>
                        <span className="solarWidgetValue" title="Total">
                            {indicators.massa ? number(indicators.massa) : 0} <span style={{ fontSize: "10px" }}>kg</span>
                        </span>
                    </Col>
                </Row>
            </div>

            <div className="solarWidget">
                <Row>
                    <Col xs={24} md={4} style={{ textAlign: "center" }}>
                        <img alt="" src={bar} />
                    </Col>
                    <Col xs={24} md={10}>
                        <span className="wasteFleetsDescription">
                            Pressão em bar
                        </span>
                    </Col>
                    <Col xs={24} md={10}>
                        <span className="solarWidgetValue" title="Total">
                            {indicators.bar ? number(indicators.bar) : 0} <span style={{ fontSize: "10px" }}>bar</span>

                        </span>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Indicators;