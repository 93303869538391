import { Col, Row } from "antd";
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import React, { useEffect, useState } from "react";
import iconActive from "../../../assets/images/eletricMobility/markers/active.png";
import iconUsed from "../../../assets/images/eletricMobility/markers/used.png";
import WidgetError from "../../../components/error";
import api from "../../../services/api";
import DetailsStations from "../details";
import "./style.css";

const _Map = ({ google, environment, dateStart, dateEnd }) => {

    const [center, setCenter] = useState({
        lat: 38.75404065199562,
        lng: -9.138367997423133,
    });

    const [positions, setPositions] = useState([]);
    const [error, setError] = useState();
    const [activeMarker, setActiveMarker] = useState();
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);

    const handleMarkerMouseOver = async (props, marker) => {
        if ((activeMarker && activeMarker.stations && activeMarker.stations.id !== marker.name) || !activeMarker) {
            let response = await api.get(`/chargers/stations/find/${marker.name}`)
            marker.stations = response.data
            setActiveMarker(marker);
        }
        setShowingInfoWindow(true);
    };

    const handleMarkerMouseOut = () => {
        //setActiveMarker();
        setShowingInfoWindow(false);
    };


    const fetchPositions = () => {
        api
            .get(`/chargers/stations/search`
            )
            .then((response) => {
                let _positions = response.data.filter(
                    (current) => current?.lat && current?.lng
                );
                setPositions(_positions);
                if (_positions.length > 0) {
                    setCenter({
                        lat: _positions[0]?.lat,
                        lng: _positions[0]?.lng,
                    });
                }
            })
            .catch((error) => {
                setError(error?.response?.data?.message || "unknown error");
            })
    };

    useEffect(fetchPositions, []);

    return (
        <Row>
            <Col xs={24}>
                <span>Estações de carregamentos</span>
            </Col>
            {error ? (
                <WidgetError message={error} refresh={() => fetchPositions()} />
            ) : (

                <div className="eletric_mobility_map">
                    <div className="statusGroup">
                        <span className="electric_status active">
                            Livre
                        </span>
                        <span className="electric_status used">
                            Utilizado
                        </span>
                        <span className="electric_status damaged">
                            Avariado
                        </span>
                    </div>
                    <Map
                        initialCenter={center}
                        center={center}
                        google={google}
                        zoom={15}
                        disableDefaultUI={true}
                        style={{ borderRadius: "16px" }}
                        fullscreenControl={false}
                        zoomControl={true}
                        streetViewControl={true}
                        mapTypeControl={false}
                    >
                        {positions?.map((item) => (
                            <Marker icon={item.status === 'Available' ? iconActive : iconUsed}
                                position={{
                                    lat: item.lat,
                                    lng: item.lng,
                                }}
                                name={item.id}
                                onMouseover={(props, marker) => handleMarkerMouseOver(props, marker)}
                                onClick={handleMarkerMouseOut}
                            />
                        ))}
                        {activeMarker && activeMarker.stations && <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
                            <DetailsStations data={activeMarker.stations} />
                        </InfoWindow>}
                    </Map>
                </div>)}
        </Row>
    );
};

export default GoogleApiWrapper({
    apiKey: `${process.env.REACT_APP_GOOGLE_MAPS_TOKEN}`,
})(_Map);

//icon={iconDamaged}
//icon={iconUsed}
//      icon={iconActive}