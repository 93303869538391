import { Col, Row } from "antd";
import React, { useContext, useState } from "react";
import Menu from "../../components/menu";
import { AuthContext } from "../../context/auth";
import { MenuContext } from "../../context/menu.js";
import Co2 from "./co2";
import Graphic from "./graphic";
import Map from "./map";
import Statistics from "./statistics";
import "./style.css";

const StreetLighting = () => {
  const { environment } = useContext(AuthContext);

  const { data } = useContext(MenuContext);
  const [dateStart, setDateStart] = useState(data.start);
  const [dateEnd, setDateEnd] = useState(data.stop);
  const onClickedMenu = (data) => {
    let { start, end } = data;
    setDateStart(start);
    setDateEnd(end);
  };

  return (
    <>
      <Menu onClicked={onClickedMenu} />

      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col xs={24} lg={12}>
          <span>Total de Luminárias</span>
          <Statistics dateStart={dateStart} dateEnd={dateEnd} />
        </Col>

        <Col xs={24} lg={12}>
          <Co2 dateStart={dateStart} dateEnd={dateEnd}></Co2>
          <Graphic dateStart={dateStart} dateEnd={dateEnd} />
          <Map
            environment={environment}
            dateStart={dateStart}
            dateEnd={dateEnd}
          />
        </Col>

      </Row>
    </>
  );
};

export default StreetLighting;
