// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/images/mobilityBackground/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".parkingWidget {\n    display: flex;\n    min-height: 10em;\n    height: auto;\n    width: 100%;\n\n    padding: 9px 20px 8px;\n    border-radius: 16px;\n    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);\n    background-color: #fff;\n    padding-bottom: 0;\n    margin-bottom: 1em;\n}\n\n.parkingWidget .content {\n    padding: 1em;\n    width: 100%;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n.parkingWidget .content {\n    margin: auto;\n}", "",{"version":3,"sources":["webpack://./src/pages/parking/widget/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,WAAW;;IAEX,qBAAqB;IACrB,mBAAmB;IACnB,yCAAyC;IACzC,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yDAA6E;IAC7E,2BAA2B;IAC3B,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".parkingWidget {\n    display: flex;\n    min-height: 10em;\n    height: auto;\n    width: 100%;\n\n    padding: 9px 20px 8px;\n    border-radius: 16px;\n    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);\n    background-color: #fff;\n    padding-bottom: 0;\n    margin-bottom: 1em;\n}\n\n.parkingWidget .content {\n    padding: 1em;\n    width: 100%;\n    background-image: url(\"/src/assets/images/mobilityBackground/background.png\");\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n.parkingWidget .content {\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
