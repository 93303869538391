import React from "react";
import Childen from "./childen";
import "./style.css";

const StatisticsPanel = ({ title = "[Title Widget here]", childens = [] }) => {
  return (
    <div>
      <span>{title}</span>
      <div className="buildins_statistic_panel">
        {childens.map((childen) => (
          <Childen {...childen} />
        ))}
      </div>
    </div>
  );
};

export default StatisticsPanel;
