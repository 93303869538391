
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import api from "../../../../../services/api";
import "../style.css";
var pt = require("apexcharts/dist/locales/pt.json");
const Month = ({ camera, dateStart, dateEnd, enter, exit }) => {

    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    const fetchData = () => {
        try {
            api
                .post("/monitoring/hikvision/group/month", {
                    id: camera,
                    event: "linedetection",
                    target: "human",
                    start: undefined,
                    end: undefined,
                })
                .then((response) => {
                    setSeries(generateSeries(response.data));
                })
        } catch (error) {
            setSeries([]);
        }
    };

    const generateSeries = (_data = []) => {
        let dates = [];
        let _enter = [];
        let _exits = [];
        _data.forEach((element) => {
            if (!dates.includes(element.date))
                dates.push(element.date);
            if (element.key === enter)
                _enter.push(element);
            if (element.key === exit)
                _exits.push(element);
        });

        setCategories(dates);
        return [
            {
                name: "Entrada",
                data: dates.map(
                    (_date) =>
                        _enter.find((item) => item.date === _date)?.value || 0
                ),
            }
            ,
            {
                name: "Saídas",
                data: dates.map(
                    (_date) =>
                        _exits.find((item) => item.date === _date)?.value || 0
                ),
            }
        ]
    }

    useEffect(() => { if (camera) fetchData() }, [camera, dateStart, dateEnd])

    return (
        <>
            <span>Grafico de entrada e saída mensal</span>
            <div className="graphic_content">
                <ReactApexChart options={{
                    chart: {
                        height: 200,
                        type: 'bar',
                        locales: [pt],
                        defaultLocale: "pt",
                        toolbar: {
                            show: true
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        show: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: 'string',
                        categories: categories.map((_ele) => `${_ele.split('-')[1]}/${_ele.split('-')[0]}`),
                    },
                    tooltip: {
                        x: {
                            format: 'MMM/yy'
                        },
                    },
                    colors: ['#26E7A5', '#FEB019']
                }}
                    series={series}
                    type="bar"
                    height={270} />
            </div></>


    );
}

export default Month;