import React, { useContext, useState } from "react";
import Menu from "../../components/menu";
import { AuthContext } from "../../context/auth";
import { MenuContext } from "../../context/menu.js";
import Legend from "./legend";
import "./style.css";
import Table from "./table";

const OccurrencesList = () => {

    const { data } = useContext(MenuContext);
    const [dateStart, setDateStart] = useState(data.start);
    const [dateEnd, setDateEnd] = useState(data.stop);
    const { environment } = useContext(AuthContext);

    const onClicked = (value) => {
        setDateStart(value?.start);
        setDateEnd(value?.end);
    }

    return (
        <div className="occurrencesContent">
            <Menu onClicked={onClicked} />
            <Table environment={environment} dateStart={dateStart} dateEnd={dateEnd} />
            <Legend />
        </div>
    );
}

export default OccurrencesList;