import { Col, Row } from "antd";
import React from "react";
import Statistic from "./statistic";
import Chart from "./chart";
import visitors from "./visitors.json";
import rents from "./rents.json";
import totalSales from "./totalSales.json";
import totalSalesPerM2 from "./totalSalesPerM2.json";

const Financial = () => {

    const categories = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} lg={12}>
                    <Statistic />
                    <Chart
                        symbol="€"
                        title="Total de rendas"
                        categories={categories}
                        series={rents} />
                </Col>
                <Col xs={24} lg={12}>
                    <Chart
                        title="Tráfego de visitantes"
                        series={visitors}
                        categories={categories} />
                    <Chart
                        symbol="€"
                        title="Total de vendas"
                        series={totalSales}
                        categories={categories} />
                    <Chart
                        symbol="€"
                        title="Total de vendas por m²"
                        series={totalSalesPerM2}
                        categories={categories} />
                </Col>
            </Row>
        </>
    );
}

export default Financial;