import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import api from "../../../services/api";
import data from "./data.json";
import "./style.css";
import Loading from "../../../components/loading";
import WidgetError from "../../../components/error";
import { Col, Row } from "antd";

const Graphic = ({ dateStart, dateEnd }) => {
  const [series, setSeries] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const fetchData = () => {
    setLoading(true);
    setError(undefined);
    api
      .get("/helpdesk/ticket/count/group/situation/date", {
        params: {
          dateStart: dateStart
            ? moment(dateStart).utc().toISOString()
            : undefined,
          dateEnd: dateEnd ? moment(dateEnd).utc().toISOString() : undefined,
        },
      })
      .then((response) => {
        setSeries(generateSeries(response.data));
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((_error) => {
        setError(_error?.response?.data?.message || "unknown error");
      });
  };

  const generateSeries = (_data = []) => {
    let dates = [];
    let closed = [];
    let open = [];
    let running = [];
    _data.forEach((element) => {
      if (!dates.includes(element.date)) dates.push(element.date);
      if (element.situation == "closed") {
        closed.push(element);
      } else if (element.situation == "running") {
        running.push(element);
      } else if (element.situation == "open") {
        open.push(element);
      }
    });
    setCategorys(dates)
    return [
      {
        name: "Ocorrências fechadas",
        data: dates.map(
          (_date) =>
            closed.find((_closed) => _closed.date === _date)?.count || 0
        ),
      },
      {
        name: "Ocorrências em aberto",
        data: dates.map(
          (_date) => open.find((_open) => _open.date === _date)?.count || 0
        ),
      },
      {
        name: "Ocorrências em resolução",
        data: dates.map(
          (_date) =>
            running.find((_running) => _running.date === _date)?.count || 0
        ),
      },
    ];
  };

  useEffect(fetchData, [dateStart, dateEnd]);

  return (
    <Row className="margin-top-mobile">
      <Col xs={24}>
        <span className="graphic_title">Gráfico de Ocorrências</span>
      </Col>

      {error && !loading && <WidgetError message={error} refresh={fetchData} />}
      {!error && (
        <div className="graphic_content">
          {!loading && (
            <div className="graphic_states">
              <div className="graphic_circle_group">
                <div
                  className="graphic_circle"
                  style={{ backgroundColor: "#7fc7af" }}
                ></div>
                <span>Ocorrências fechadas</span>
              </div>
              <div className="graphic_circle_group">
                <div
                  className="graphic_circle"
                  style={{ backgroundColor: "#ffb01a" }}
                ></div>
                <span>Ocorrências em aberto</span>
              </div>
              <div className="graphic_circle_group">
                <div
                  className="graphic_circle"
                  style={{ backgroundColor: "#008ffb" }}
                ></div>
                <span>Ocorrências em resolução</span>
              </div>
            </div>
          )}

          {loading && <Loading />}

          {!loading && (
            <div id="chart-ticket-stats" className="graphic_chart">
              <Chart
                options={{
                  ...data.options,
                  xaxis: {
                    type: "datetime",
                    labels: {
                      format: "dd/MM/yyyy",
                    },
                    categories: categorys,
                  },
                }}
                series={series}
                type="area"
                height="220"
              />
            </div>
          )}
        </div>
      )}
    </Row>
  );
};

export default Graphic;
