import { Col, Row, Skeleton } from "antd";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import WidgetInfo from "./widgetInfo";

const Info = () => {

    const [accumulateds, setAccumulateds] = useState([]);
    const [lastTime, setLastTime] = useState(moment());
    const [loading, setLoading] = useState(false);

    const fetchsAccumulateds = async () => {
        let response = await api.get('/solar/sensors/fetch/production')
        setAccumulateds(response.data)
    };

    const startTimeout = () => setTimeout(() => setLastTime(moment()), 1000);

    useEffect(async () => {
        try {
            setLoading(true);
            await fetchsAccumulateds();
        } finally {
            setLoading(false);
            startTimeout();
        }
    }, [lastTime]);

    return (<>
        <span>Produção em tempo real</span>
        <div className="contentParkWidget" style={{ marginTop: "0px" }}>
            <Row gutter={[16, 16]}>
                {accumulateds.map((item, idx) =>
                    <Col xs={24} >
                        <WidgetInfo
                            icon={['hoje.svg', 'ontem.svg', 'estaSemana.svg', 'nesteMes.svg', 'nesteAno.svg', 'total.svg'][idx] || "media_consumo.svg"}
                            title={item.name}
                            subValue={"kWh"}
                            value={item.value}
                        />
                    </Col>
                )}
                {
                    (!accumulateds || accumulateds.length == 0) && <Col xs={24} >{loading ? <Skeleton active /> : <div style={{ textAlign: "center" }}><h3>{'Sem dados'}</h3></div>}</Col>
                }
            </Row>
        </div>
    </>);
}

export default Info;