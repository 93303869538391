import { Col, Row } from "antd";
import { GoogleApiWrapper, InfoWindow, Map, Marker, Polygon } from "google-maps-react";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import DetailsOccurrences from "../details";
import "./style.css";

const _Map = ({ google, environment, dateStart, dateEnd }) => {

  const [center, setCenter] = useState({
    lat: 38.75404065199562,
    lng: -9.138367997423133,
  });

  const [positions, setPositions] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [contours, setCountors] = useState([]);
  const [activeMarker, setActiveMarker] = useState();
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);

  const showDetails = async (props, marker) => {
    let response = await api.get(`/helpdesk/ticket/preview/${marker.name}`)
    marker._data = response.data
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };

  const handleCenterLocale = () => {
    api
      .get(`/oauth/public/environment/geometry`)
      .then((response) => {
        setCountors(response.data);
        let point = response.data[0][0];
        setCenter({ lat: point.lat, lng: point.lng });
      }).catch((e) => {
        setMyLocationInCenter();
      });
  };

  const setMyLocationInCenter = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      let { latitude, longitude } = position.coords;
      setCenter({ lat: latitude, lng: longitude });
    });
  };

  const fetchPositions = () => {
    setLoading(true);
    handleCenterLocale();
    api
      .get(`/helpdesk/ticket/search/location`, {
        params: {
          dateStart: dateStart
            ? moment(dateStart).utc().toISOString()
            : undefined,
          dateEnd: dateEnd ? moment(dateEnd).utc().toISOString() : undefined,
        },
      })
      .then((response) => {
        let _positions = response.data.filter(
          (current) => current?.address?.lat && current?.address?.lng
        );
        setPositions(_positions);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(fetchPositions, [dateStart, dateEnd]);

  return (
    <Row>
      <Col xs={24}>
        <span>Localização das ocorrências</span>
      </Col>

      {!loading && error && (
        <WidgetError message={error} refresh={fetchPositions} />
      )}

      {!error && (
        <div className="occurrences_map">
          {loading && <Loading />}
          {!loading && (
            <Map
              initialCenter={center}
              center={center}
              google={google}
              zoom={10}
              disableDefaultUI={true}
              className="occurrences_map"
              style={{ borderRadius: "16px" }}
              fullscreenControl={false}
              zoomControl={true}
              streetViewControl={true}
              mapTypeControl={false}
            >
              {positions?.map((_ticket) => (

                <Marker
                  position={{
                    lat: _ticket?.address?.lat,
                    lng: _ticket?.address?.lng,
                  }}
                  name={_ticket.id}
                  title={`Ocorrência: ${_ticket.id}`}
                  onClick={(props, marker) => showDetails(props, marker)}

                />
              ))}

              {(contours || []).map((ele) =>
                <Polygon
                  options={{
                    strokeColor: '#000f63',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillOpacity: 0.2
                  }}
                  paths={ele}
                >
                </Polygon>)}
              {activeMarker && activeMarker._data && <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
                <DetailsOccurrences data={activeMarker._data} />
              </InfoWindow>}
            </Map>
          )}
        </div>
      )}
    </Row>
  );
};

export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_GOOGLE_MAPS_TOKEN}`,
})(_Map);
