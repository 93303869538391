import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import React, { useEffect, useState } from "react"
import api from "../../../../services/api"


const Page = ({ local, onSelect }) => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const fetchLoad = async (local, page, rowPerPage) => {
        setLoading(true)
        let { data } = await api.get("/geoservices/features", {
            params: {
                local: local,
                page: page > 0 ? page : 0,
                rowPerPage: 15
            }
        })
        setLoading(false)
        return data
    }

    const handlePage = async (local, page) => {
        onClickTree(local, page)
    }

    const onClickTree = async (local, page = 0) => {
        let _data = await fetchLoad(local, page)
        setData(_data)
    }

    const onClick = (c) => {
        onSelect({ local: c.local, bgri: c.bgri, name: c.section + '-' + c.bgri })
        let _data = data
        _data.content = (data.content || []).map(i => ({
            ...i, open: i.bgri === c.bgri ? !c.open : false
        }))
        setData(_data)
    }

    useEffect(() => {
        if (local.open)
            onClickTree(local.id, 0)
        else
            setData({})
    }, [local])

    return (
        <>
            {local.open && data && data.content && data.content.length > 0 ?
                <>
                    {(data.content || []).map(c =>
                        <li key={c.bgri} >
                            <span onClick={() => onClick(c)} className="item" style={c.open ? { color: "#1890ff" } : {}}>{c.section + '-' + c.bgri}</span>
                        </li>)}
                    <li className="page-content">
                        <ul className="pagination">
                            {data.number > 0 && <li style={{ display: "inline-flex" }}>
                                <span title="Página anterior" className="item" style={{ color: "#1890ff" }}> <LeftOutlined onClick={() => handlePage(local.id, data.number - 1)} /></span>
                            </li>
                            }
                            {data.number >= 1 && <li style={{ display: "inline-flex" }} onClick={() => handlePage(local.id, data.number - 1)}>
                                <span title="Anterior" className="item">{data.number - 1}</span>
                            </li>}
                            <li className="page-item" style={{ display: "inline-flex", color: " #1890ff" }} onClick={() => handlePage(local.id, data.number)}>
                                <span title="Atual" className="item">{data.number}</span>
                            </li>
                            {!data.last && <li style={{ display: "inline-flex" }} onClick={() => handlePage(local.id, data.number + 1)}>
                                <span title="Proxima" className="item">{data.number + 1}</span>
                            </li>}
                            {!data.last &&
                                <li style={{ display: "inline-flex" }} onClick={() => handlePage(local.id, data.totalPages - 1)} >
                                    <span title="Total de paginas" className="item">{data.totalPages}</span>
                                </li>}
                            {!data.last && <li style={{ display: "inline-flex" }}>
                                <span title="Proxima página" className="item"><RightOutlined onClick={() => handlePage(local.id, data.number + 1)}
                                    style={{ color: "#1890ff" }}
                                /></span>
                            </li>}
                        </ul>
                    </li>
                </> : <> </>
            }
        </>
    )
}

export default Page