import React, { useEffect, useState } from "react";
import "./style.css";
import api from "../../../../services/api";
import Loading from "../../../../components/loading";
import { Col, Row } from "antd";

const Legend = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [status, setStatus] = useState([]);

  const fetchLegends = async () => {
    try {
      setLoading(true);
      setError(undefined);
      let statusResponse = await api.get(`/helpdesk/status`);
      setStatus(statusResponse?.data);
    } catch (_error) {
      setError(_error?.response?.data?.message || "unknown error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(fetchLegends, []);

  return (
    <Row style={{ marginTop: "1em" }}>
      <Col xs={24}>
        <span>Legenda</span>
      </Col>
      <div class="table-ticket-legend-simple">
        {loading && <Loading />}

        {!error && !loading && (
          <div class="aling-left">
            {status.map((_sts) => (
              <div class="group">
                <div
                  class="circle"
                  style={{ backgroundColor: _sts?.color }}
                ></div>
                <span>{_sts?.description}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </Row>
  );
};

export default Legend;
