import React from "react";

function Icon({ station, temperatura, time,weatherTypePT }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            viewBox="0 0 348 131"
        >
            <defs>
                <filter
                    id="e4cranhdza"
                    width="222.5%"
                    height="322.7%"
                    x="-61.2%"
                    y="-106.8%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="l9bp1532bc"
                    width="140%"
                    height="304.2%"
                    x="-20%"
                    y="-97.9%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="ygkw4bce2e"
                    width="162.8%"
                    height="202.1%"
                    x="-31.4%"
                    y="-49%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="cm55wv7xqg"
                    width="140.6%"
                    height="322.7%"
                    x="-20.3%"
                    y="-106.8%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <text
                    id="dd597mjrqb"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="18"
                    fontWeight="bold"
                >
                    <tspan x="286" y="104">
                        {time}
                    </tspan>
                </text>
                <text
                    id="rwhjwdcdyd"
                    fill="#FFF"
                    fontFamily="JosefinSans-Light, Josefin Sans"
                    fontSize="20"
                    fontWeight="300"
                >
                    <tspan x="10" y="71">
                    {weatherTypePT}
                    </tspan>
                </text>
                <text
                    id="s574telpkf"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="40"
                    fontWeight="bold"
                >
                    <tspan x="10" y="48">
                        {temperatura}
                    </tspan>
                </text>
                <text
                    id="j5ni3sdbuh"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="18"
                    fontWeight="bold"
                >
                    <tspan x="10" y="104">
                        {station}
                    </tspan>
                </text>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g strokeLinecap="square">
                    <path
                        stroke="#FFF"
                        strokeWidth="3"
                        d="M134 63l-18 24m95-48l-18 24m36 12l-18 24m111-48l-18 24"
                    ></path>
                    <path
                        stroke="#D5D5D5"
                        strokeWidth="2"
                        d="M172.5 47.5l-18 24M284 63l-18 24M125 39l-18 24"
                    ></path>
                </g>
                <path
                    fill="#5494E0"
                    d="M26.511 5H320.49c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C340.257 15.793 341 18.38 341 25.511v73.846c0 7.132-.743 9.718-2.137 12.325a14.538 14.538 0 01-6.048 6.049c-2.608 1.394-5.194 2.137-12.326 2.137H26.51c-7.132 0-9.718-.743-12.326-2.137a14.538 14.538 0 01-6.048-6.049C6.743 109.075 6 106.49 6 99.357V25.51c0-7.132.743-9.718 2.137-12.326a14.538 14.538 0 016.048-6.048C16.793 5.743 19.38 5 26.511 5z"
                ></path>
                <g strokeLinecap="square">
                    <path
                        stroke="#FFF"
                        strokeWidth="3"
                        d="M134 63l-18 24m95-48l-18 24m36 12l-18 24m111-48l-18 24"
                    ></path>
                    <path
                        stroke="#D5D5D5"
                        strokeWidth="2"
                        d="M172.5 47.5l-18 24M284 63l-18 24M125 39l-18 24"
                    ></path>
                </g>
                <path
                    fill="#BCCFE5"
                    d="M320.489 5c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C340.257 15.793 341 18.38 341 25.511V38H143.997C130.192 38 119 26.809 119 12.993V5h201.489z"
                    opacity="0.914"
                ></path>
                <path
                    fill="#99B4D6"
                    d="M329.006 18c4.5 0 8.653 1.487 11.995 3.997v32.002A19.9 19.9 0 01329.006 58h-97.012C220.952 58 212 49.048 212 38c0-11.046 8.963-20 19.994-20h97.012z"
                ></path>
                <path
                    fill="#B3C9E4"
                    d="M323.577 85.022c.937 0 1.844.052 2.72.153H341v17.27c0 3.562-.749 6.685-2.142 9.266a14.674 14.674 0 01-6.014 6.014c-2.582 1.394-5.705 2.143-9.267 2.143H23.423c-3.562 0-6.685-.749-9.267-2.143a14.674 14.674 0 01-6.014-6.014C6.75 109.131 6 106.007 6 102.445l.004-.424H6V85.177h14.704a23.524 23.524 0 012.719-.154h300.154z"
                ></path>
                <g fill="#FFF" transform="translate(6 5)">
                    <use filter="url(#e4cranhdza)" xlinkHref="#dd597mjrqb"></use>
                    <use xlinkHref="#dd597mjrqb"></use>
                </g>
                <g fill="#FFF" transform="translate(6 5)">
                    <use filter="url(#l9bp1532bc)" xlinkHref="#rwhjwdcdyd"></use>
                    <use xlinkHref="#rwhjwdcdyd"></use>
                </g>
                <g fill="#FFF" transform="translate(6 5)">
                    <use filter="url(#ygkw4bce2e)" xlinkHref="#s574telpkf"></use>
                    <use xlinkHref="#s574telpkf"></use>
                </g>
                <g fill="#FFF" transform="translate(6 5)">
                    <use filter="url(#cm55wv7xqg)" xlinkHref="#j5ni3sdbuh"></use>
                    <use xlinkHref="#j5ni3sdbuh"></use>
                </g>
            </g>
        </svg>
    );
}

export default Icon;
