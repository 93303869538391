import { Col, Row } from "antd";
import React, { useContext, useState } from "react";
import Menu from "../../components/menu";
import { MenuContext } from "../../context/menu.js";
import Map from "./map";
import Statistic from "./statistic";
import "./style.css";

const WasteFleets = () => {

  const { data } = useContext(MenuContext);
  const [dateStart, setDateStart] = useState(data.start);
  const [dateEnd, setDateEnd] = useState(data.stop);

  return (
    <Row style={{ marginBottom: 16 }} gutter={16}>
      <Col xs={24}>
        <Menu />
      </Col>
      <Col xs={24} lg={11}>
        <Map />
      </Col>
      <Col xs={24} lg={13}>
        <Statistic />
      </Col>
    </Row>
  );
};

export default WasteFleets;
