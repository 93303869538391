import React from "react"
import "../../style.css";
import { Link } from "react-router-dom";
const Bikesharingoccupation = () => {

    const partner = <Link to="/ctec/contacts?partner=NOVA-IMS">NovaIMS</Link>

    return (<div><iframe className="frame-content" src="https://app.powerbi.com/view?r=eyJrIjoiMTU5YWYxN2ItZDQzYy00YzE4LTk5ZjYtNDFkZTJkZWJlNzE3IiwidCI6ImU0YmQ2OWZmLWU2ZjctNGMyZS1iMjQ3LTQxYjU0YmEyNDkwZSIsImMiOjh9" title="Bike Sharing Occupation"></iframe>
    <div><p>Built BY {partner}</p></div></div>)
}

export default Bikesharingoccupation