import React from "react";
import "./style.css";

const Widget = ({ value, title, h2Props }) => {

    return (
        <div className="card_info_content" style={{ marginTop: "1em" }}>
            <h2 {...h2Props} >{value}</h2>
            <span>{title}</span>
        </div>)
}

export default Widget;