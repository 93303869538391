import { Col, Row } from "antd";
import React from "react";
import "./style.css";

const Widget = ({ icon, title, value, unity, tooltip, name }) => {
    return (
        <div className="solarWidget">
            <Row>
                <Col xs={24} md={4} style={{ textAlign: "center" }}>
                    <img alt="" src={require(`../../../../../assets/icons/solar/${icon}`)} />
                </Col>
                <Col xs={24} md={10}>
                    <span className="wasteFleetsDescription">
                        {title}
                    </span>
                </Col>
                <Col xs={24} md={10}>
                    <span className="solarWidgetValue" title={tooltip}>
                        {value} <span style={{ fontSize: "10px" }}>{unity}</span>
                        <div className="subValue">
                            {name}
                        </div>
                    </span>
                </Col>
                {/* <Col xs={24} md={6} >
                    <span className="solarWidgetValue" title={tooltip2}>
                        {v2} <span style={{ fontSize: "10px" }}>{unity}</span>
                        <div className="subValue">
                            Inversor 2
                        </div>
                    </span>
                </Col> */}
            </Row>
        </div>
    );
}
export default Widget;