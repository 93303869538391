import { Col, Row } from "antd";
import React, { useContext, useState } from "react";
import Menu from "../../components/menu";
import { MenuContext } from "../../context/menu.js";
import ChartOccupation from "./chartOccupation";
import Map from "./map";
import Statistics from "./statistics";

const Parking = () => {

    const { data } = useContext(MenuContext);
    const [dateStart, setDateStart] = useState(data.start);
    const [dateEnd, setDateEnd] = useState(data.stop);

    return (
        <Row gutter={16}>
            <Col xs={24}>
                <Menu />
            </Col>
            <Col xs={24} lg={12}>
                <Map />
                <ChartOccupation />
            </Col>
            <Col xs={24} lg={12}>
                <Statistics />
            </Col>
        </Row>
    );
}

export default Parking;