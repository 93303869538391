import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import bar from "../../../../assets/icons/hydrogen/bar.svg";
import totalSvg from "../../../../assets/icons/hydrogen/total.svg";
import api from "../../../../services/api";
import { number } from "../../../../util/Format";

const Indicators = ({ names, rackSelected }) => {

    const [current, setCurrent] = useState(0)
    const [total, setTotal] = useState(0)

    const fetchTotal = () => {
        api.get("/hydrogen/indicators/aggregate", {
            params: {
                names: rackSelected === 'all' ? [names.filter(i => i.includes("cp"))].toString() : rackSelected,
                aggregationType: "YEAR"
            }
        }).then((response) => {
            if (response.data.length > 0) {
                let value = response.data.reduce((r, q) => {
                    return (r + q.sum);
                }, 0)
                setTotal(value)
            }

        })
    }

    const fetchCurrent = () => {
        api.get("/hydrogen/indicators/last", {
            params: {
                names: rackSelected === 'all' ? [names.filter(i => i.includes("cp"))].toString() : rackSelected,
                aggregationType: "YEAR"
            }
        }).then((response) => {
            if (response.data.length > 0) {
                let value = response.data.reduce((r, q) => {
                    return (r + q.value);
                }, 0)
                setCurrent(value)
            }

        })
    }

    useEffect(() => {
        if (rackSelected) {
            fetchTotal()
            fetchCurrent()
        }
    }, [rackSelected])

    return (

        <>

            <div className="solarWidget">
                <Row>
                    <Col xs={24} md={4} style={{ textAlign: "center" }}>
                        <img alt="" src={totalSvg} />
                    </Col>
                    <Col xs={24} md={10}>
                        <span className="wasteFleetsDescription">
                            Produção atual
                        </span>
                    </Col>
                    <Col xs={24} md={10}>
                        <span className="solarWidgetValue" title="Produção atual">
                            {number(current)} <span style={{ fontSize: "10px" }}>Nm3</span>
                        </span>
                    </Col>

                </Row>
            </div>
        </>
    );
};

export default Indicators;