import { InstagramOutlined, TwitterOutlined } from "@ant-design/icons";
import { Col, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import "./style.css";

const SocialNetworks = () => {
  const [twitters, setTwitters] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const searchTwitters = () => {
    setLoading(true);
    setError(false);
    api
      .get("/social/twitter/search")

      .then((response) => {
        setTwitters(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    searchTwitters();
  }, []);

  return (
    <Row style={{ marginTop: "1em" }}>
      <Col xs={24} className="social_networks">
        <div className="title">
          <div className="left">
            <span>Redes Sociais</span>
          </div>

          <div className="right">
            <Space>
              <InstagramOutlined />
              <TwitterOutlined />
            </Space>
          </div>
        </div>
      </Col>
      <Col xs={24}>
        {error ? (
          <WidgetError message={`${error}`} refresh={() => searchTwitters()} />
        ) : twitters?.error ? <WidgetError message={`${twitters?.msg}`} refresh={() => searchTwitters()} /> : (
          <div className="twitter_content">
            {loading ? (
              <Loading />
            ) : (
              <>
                {twitters?.data &&
                  twitters?.data?.map((d) => (
                    <TwitterTweetEmbed tweetId={d.id} />
                  ))}
                {twitters?.meta?.resulCount === 0 && (
                  <span className="no-data" style={{ paddingTop: "16px" }}>
                    Sem dados
                  </span>
                )}

              </>
            )}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default SocialNetworks;
