import { Col, Row } from "antd";
import React from "react";
import "./style.css";

const Widget = ({ title, icon, internal, outside }) => {


    return (
        <>
            <span>
                {title}
            </span>
            <div className="buildingsWidgetContent">
                <Row>
                    <Col xs={6} style={{ textAlign: "center", paddingTop: "10px" }}>
                        <img style={{ width: "2.5em" }} src={require(`../../../../assets/icons/buildings/${icon}`)} />
                    </Col>
                    <Col xs={9}>
                        <div className="title">{internal}</div>
                        <div className="value">interior</div>
                    </Col>
                    <Col xs={9}>
                        <div className="title">{outside}</div>
                        <div className="value">exterior</div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Widget;