import { Col, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import icon from "../../../assets/icons/co2.svg";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import "./style.css";

const Co2 = ({ dateStart, dateEnd }) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [spared, setSpared] = useState(0);

  const fator = 0.408;

  const fetchPrevious = async () => {
    let { data } = await api.get("/lighting/lightFixture/total/consumption/previous");
    return data.count;
  };

  const fetchCurrent = async () => {
    let { data } = await api.post("/assets/telemetry/count/consumption/period", {
      dateStart: dateStart ? moment(dateStart).utc() : undefined,
      dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
    });

    return data.count;
  };

  const handlePrevious = (_previous) => {
    let start = new Date(dateStart);
    let end = new Date(dateEnd);
    let decorrido = (end - start) / 86400;
    if (decorrido < 2000) return (_previous / 365).toLocaleString(
      undefined,
      { maximumFractionDigits: 4 }
    );
    else if (decorrido > 2000 && decorrido < 8000)
      return (_previous / 52).toFixed(2);
    else if (decorrido > 10000) return (_previous / 12).toLocaleString(
      undefined,
      { maximumFractionDigits: 4 }
    );
    return _previous.toLocaleString(
      undefined,
      { maximumFractionDigits: 4 }
    );
  };

  const handleRealSavings = (_previus, _current) => {
    let value =  parseInt(handlePrevious(_previus)) 
    setSpared(value - _current);
  };

  const refresh = async () => {
    try {
      setError(false);
      setLoading(true);
      handleRealSavings(await fetchPrevious(), await fetchCurrent());
    } catch (error) {
      setError(error?.message);
    } finally {
      setLoading(false);
    };
  };

  useEffect(() => {
    refresh();
  }, [dateStart, dateEnd]);

  return (
    <>
      <Row>
        <Col xs={24}>
          <span>Emissão de CO2</span>
        </Col>
      </Row>
      {loading ? (
        <Loading />
      ) : error ? (
        <WidgetError
          message={`${error}`}
          refresh={() => refresh()}
        ></WidgetError>
      ) : (
        <>
          <Row className="co2_content">
            <Col xs={24} md={6} className="margin-top-mobile">
              <img src={icon} alt="" />
            </Col>

            <Col xs={24} md={12} className="margin-top-mobile">
              <div style={{ marginBottom: "-0.5em" }} className="margin-top-mobile">
                <span className="co2_title">{function format(value, decimal) {
                  return Number(value).toLocaleString(undefined, {
                    minimumFractionDigits: decimal,
                  });
                }(((spared * fator) > 1000 ? (spared * fator) / 1000 : (spared * fator)), 0)}</span>
                <span>{(spared * fator) > 1000 ? 'Tn' : 'Kg'}</span>
              </div>
              <span className="span_co2_title">Poupado</span>
              <br />
              <div className="co2_group"></div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Co2;
