import { Col, Row, Select, Skeleton } from "antd";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import ChartData from "../production/chart";
import Widget from "./widget";
import filterIcon from "../../../assets/icons/filter.svg";
import "../style.css";

const SolarStorage = () => {

    const [loading, setLoading] = useState(false);
    const [lastRead, setLastRead] = useState([])
    const [series, setSeries] = useState()
    const [categories, setCategories] = useState([])
    const [lastTime, setLastTime] = useState();
    const [chartAcumulated, setChartAcululated] = useState({
        serie: [],
        categorys: []
    });
    const [storages, setStorages] = useState([]);

    const [selected, setSelected] = useState("Storage");


    const fetchBaterias = () => {
        api.get("/solar/assets/find/asset").then((response) => {
            let newData = response.data.map((dt) => {
                if(dt.externalReference == "BATTERY1"){
                    dt.externalReference = "Storage";
                }
                return dt;
            }).filter((ele)=>ele.externalReference.includes('Storage'))
                .map((ele) => {
                    return {
                        label: ele.name,
                        value: ele.externalReference
                    }
                });
            setStorages(newData);
        });
    }

    const fetchLastRead = async () => {
        let { data } = await api.get(`/solar/sensors/last/reading/sensors/${selected}`);
        setLastRead(data);
    };

    const fetchBateryPercentual = async () => {
        let { data } = await api.get('/solar/sensors/find/value', {
            params: {
                name: `${selected}_Pnes_Bat_State_of_Charge`,
                dtStart: moment().set({ h: 0, m: 0, s: 0 }).toISOString(),
                dtEnd: moment().set({ h: 23, m: 59, s: 59 }).toISOString(),
            }
        });
        setSeries(generateSeries(data));
    };

    const generateSeries = (_data = []) => {
        let dates = [];
        _data.forEach((element) => {
            if (!dates.includes(element.sourceTime))
                dates.push(element.sourceTime);

        });
        setCategories(dates.map((e) => moment.utc(e).local().toISOString()))
        return [
            {
                name: "Percentual",
                data: dates.map((_date) => _data.find((i) => i.sourceTime == _date)?.sourceValue || 0),
            },
        ];
    };

    const fetchLastData = async () => {
        let response = await api.get(`/solar/sensors/last/reading/sensors/${selected}_Pnes_Inv_AC_Power`);
        let _serie = [
            {
                name: "Energia consumida",
                data: [...((chartAcumulated.serie[0] || {}).data || []), response.data.map(_ele => _ele.sourceValue).reduce((partialSum, a) => partialSum + a, 0)]
            }
        ]
        let _newChartAcumulated = {
            serie: _serie,
            categorys: [...chartAcumulated.categorys, moment().toLocaleString()]
        };
        let _maxLenght = 200;
        if (_newChartAcumulated.serie[0].data.length > _maxLenght) {
            _newChartAcumulated.serie[0].data = _newChartAcumulated.serie[0].data.slice(_newChartAcumulated.serie[0].data.length - _maxLenght - 1, _newChartAcumulated.serie[0].data.length)
        }
        if (_newChartAcumulated.categorys.length > _maxLenght) {
            _newChartAcumulated.categorys = _newChartAcumulated.categorys.slice(_newChartAcumulated.categorys.length - _maxLenght - 1, _newChartAcumulated.categorys.length)
        }
        setChartAcululated(_newChartAcumulated);
    }

    useEffect(()=>{
        fetchBaterias();
    }, []);

    useEffect(async () => {
        try {
            setLoading(true);
            await fetchLastRead();
            await fetchBateryPercentual();
            await fetchLastData();
        } finally {
            setLoading(false);
            startTimeout();
        }
    }, [lastTime])

    const startTimeout = () => setTimeout(() => setLastTime(moment()), 1000);

    return (
        <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col xs={24} lg={12}>

                <div className="filters">
                    <img src={filterIcon} /> Filtros
                </div>

                <div className="filters-energy">
                    <Row>
                    <Col xs={12}>
                        <div>
                        <span>
                            Bateria em análise:
                        </span>
                        
                        <Select className="select_mobility"
                            value={selected}
                            bordered={false}
                            showSearch
                            options={storages}
                            onChange={(i) => setSelected(i)}
                            placeholder="Selecione uma bateria"
                        />
                        </div>
                    </Col>
                    </Row>
                </div>

                <div>
                    <span>Armazenamento</span>
                    <Row gutter={[16, 16]} style={{ marginTop: "-10px" }}>
                        {lastRead.map((item) =>
                            <Col xs={24} >
                                <Widget
                                    icon={function () {
                                        switch (item.sourceName.tag.substring(item.sourceName.tag.indexOf("Pnes_"), item.sourceName.tag.length) ) {
                                            case "Pnes_Bat_DC_Current": return "bateriasAlimentacaoDC.svg";
                                            case "Pnes_Bat_DC_Power": return "baterias.svg";
                                            case "Pnes_Bat_DC_Voltage": return "tensaoDC.svg";
                                            case "Pnes_Bat_Energy_Remaining": return "energiaRestante.svg";
                                            case "Pnes_Bat_State_of_Charge": return "estadoDeCarga.svg";
                                            case "Pnes_Bat_Temperature": return "temperatura.svg";
                                            case "Pnes_GridConnectionPower": return "energiaConexao.svg";
                                            case "Pnes_Inv_AC_Current": return "corrente.svg";
                                            case "Pnes_Inv_AC_Power": return "saidaEnergia.svg";
                                            case "Pnes_Inv_AC_Voltage": return "tensao.svg";
                                            case "Pnes_Loads_Power": return "potenciaDeCarga.svg";
                                            case "Pnes_Operating_Mode": return "modoOperacional.svg";
                                            default: return "media_consumo.svg";
                                        }
                                        
                                    }
                                    
                                    ()}
                                    tooltip={item.sourceValue}
                                    title={item.sourceName.pt}
                                    value={item.sourceValue.toLocaleString(undefined, {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2,
                                    })}
                                    unity={item.sourceName.unity}
                                />
                            </Col>                           
                        )}
                        {(!lastRead || lastRead.length == 0) && <>
                            {
                                loading ? <Skeleton active /> : <h1>Sem dados</h1>
                            }
                        </>
                        }
                    </Row>
                </div>
            </Col>
            <Col xs={24} lg={12}>
                <ChartData
                    title="Consumo em tempo real"
                    categories={chartAcumulated.categorys}
                    xaxis={{
                        type: 'datetime',
                        labels: {
                            formatter: function (val, index) {
                                return moment(val).format("HH:mm:ss");
                            }
                        }
                    }}
                    series={chartAcumulated.serie}
                    symbol="kW"
                    type="line">
                </ChartData>
                <ChartData xaxis={{
                    type: 'datetime',
                    labels: {
                        formatter: function (val, index) {
                            return moment(val).format("HH:mm:ss");
                        }
                    }
                }}
                    options={{
                        dataLabels: {
                            enabled: true,
                        },
                    }}
                    yaxis={{
                        min: 0,
                        max: 100
                    }}
                    id="batery" title="Estado da bateria em %" xSymbol={"h"} categories={categories} series={series || []} symbol="%" type="line"></ChartData>
            </Col>
        </Row>
    );
}

export default SolarStorage;