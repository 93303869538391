import React from "react";

function Icon({ station, temperatura, time, weatherTypePT }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      viewBox="0 0 349 131"
    >
      <defs>
        <filter
          id="cr4rnzjg2a"
          width="222.5%"
          height="322.7%"
          x="-61.2%"
          y="-106.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="g54oj9h4fc"
          width="140%"
          height="304.2%"
          x="-20%"
          y="-97.9%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="ms0k0mqu2e"
          width="162.8%"
          height="202.1%"
          x="-31.4%"
          y="-49%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="kk63yvmk4g"
          width="140.6%"
          height="322.7%"
          x="-20.3%"
          y="-106.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <text
          id="b4ddikxivb"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="18"
          fontWeight="bold"
        >
          <tspan x="286" y="104">
            {time}
          </tspan>
        </text>
        <text
          id="6sawbc1pbd"
          fill="#FFF"
          fontFamily="JosefinSans-Light, Josefin Sans"
          fontSize="20"
          fontWeight="300"
        >
          <tspan x="10" y="71">
            {weatherTypePT}
          </tspan>
        </text>
        <text
          id="0xl3m0xodf"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="40"
          fontWeight="bold"
        >
          <tspan x="10" y="48">
            {temperatura}
          </tspan>
        </text>
        <text
          id="osq45fgm0h"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="18"
          fontWeight="bold"
        >
          <tspan x="9.882" y="104">
            {station}
          </tspan>
        </text>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#66C2FB"
          d="M27.511 5H321.49c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 15.793 342 18.38 342 25.511v73.846c0 7.132-.743 9.718-2.137 12.325a14.538 14.538 0 01-6.048 6.049c-2.608 1.394-5.194 2.137-12.326 2.137H27.51c-7.132 0-9.718-.743-12.326-2.137a14.538 14.538 0 01-6.048-6.049C7.743 109.075 7 106.49 7 99.357V25.51c0-7.132.743-9.718 2.137-12.326a14.538 14.538 0 016.048-6.048C17.793 5.743 20.38 5 27.511 5z"
        ></path>
        <g transform="translate(111 40)">
          <path
            fill="#FFF"
            d="M71.5 7l4.28 2.764-1.635 4.472h-5.29L67.22 9.764zm-57-7L19 4l-4.5 4L10 4zm199 33l4.28 2.764-1.635 4.472h-5.29l-1.635-4.472zm-86-5l4.5 4-4.5 4-4.5-4zm-123 2l4.28 2.764-1.635 4.472h-5.29L.22 32.764z"
          ></path>
          <circle cx="40" cy="24" r="4" fill="#D0F4FC"></circle>
          <circle cx="86" cy="36" r="4" fill="#D0F4FC"></circle>
          <circle cx="174" cy="28" r="4" fill="#D0F4FC"></circle>
        </g>
        <path
          fill="#F8FCFF"
          d="M321.489 5c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 15.793 342 18.38 342 25.511V38H144.997C131.192 38 120 26.809 120 12.993V5h201.489z"
        ></path>
        <path
          fill="#D0F4FC"
          d="M341.999 18v40H233.002C221.955 58 213 49.048 213 38c0-11.046 8.954-20 20.002-20h108.997zm-17.422 67.022c.937 0 1.844.052 2.72.153H342v17.27c0 3.562-.749 6.685-2.142 9.266a14.674 14.674 0 01-6.014 6.014c-2.582 1.394-5.705 2.143-9.267 2.143H24.423c-3.562 0-6.685-.749-9.267-2.143a14.674 14.674 0 01-6.014-6.014C7.75 109.131 7 106.007 7 102.445l.004-.424H7V85.177h14.704a23.524 23.524 0 012.719-.154h300.154z"
        ></path>
        <g fill="#FFF" transform="translate(7 5)">
          <use filter="url(#cr4rnzjg2a)" xlinkHref="#b4ddikxivb"></use>
          <use xlinkHref="#b4ddikxivb"></use>
        </g>
        <g fill="#FFF" transform="translate(7 5)">
          <use filter="url(#g54oj9h4fc)" xlinkHref="#6sawbc1pbd"></use>
          <use xlinkHref="#6sawbc1pbd"></use>
        </g>
        <g fill="#FFF" transform="translate(7 5)">
          <use filter="url(#ms0k0mqu2e)" xlinkHref="#0xl3m0xodf"></use>
          <use xlinkHref="#0xl3m0xodf"></use>
        </g>
        <g fill="#FFF" transform="translate(7 5)">
          <use filter="url(#kk63yvmk4g)" xlinkHref="#osq45fgm0h"></use>
          <use xlinkHref="#osq45fgm0h"></use>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
