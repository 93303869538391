import { Descriptions } from "antd"
import React from "react"

const DetailsAssets = ({ data }) => {
    return (
        <Descriptions title={data.name}>
            <Descriptions.Item key="Nome" span={3} label="Nome">{data.name}</Descriptions.Item>
            <Descriptions.Item key="Referência" span={3} label="Referência">{data.externalReference}</Descriptions.Item>
            <Descriptions.Item key="Localização" span={3} label="Localização">{`${data.lat},${data.lng}`}</Descriptions.Item>
        </Descriptions>
    )
}
export default DetailsAssets