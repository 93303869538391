import { Col, Row } from "antd";
import React, { useState } from "react";
import icon from '../../../../assets/icons/parking/widget/ocupacao_turnos.svg';
import "./style.css";

const WidgetOccupations = () => {

    const [morning, setMorning] = useState(30);
    const [afternoon, setAfternoon] = useState(68);
    const [night, setNight] = useState(12);

    return (
        <div className="widget_occupation_parking">
            <Row>
                <Col xs={24} md={2} className="nocenter">
                    <img
                        className="icon"
                        src={icon} />
                </Col>
                <Col xs={24} md={8}>
                    <span className="value">
                        {`${morning}% manhã`}
                    </span>
                </Col>
                <Col xs={24} md={7}>
                    <span className="value">
                        {`${afternoon}% tarde`}
                    </span>
                </Col>
                <Col xs={24} md={7}>
                    <span className="value">
                        {`${night}% noite`}
                    </span>
                </Col>
            </Row>
        </div>
    );
}

export default WidgetOccupations;