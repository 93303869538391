
import React, { useEffect, useState } from "react"
import api from "../../../services/api"
import Chart from "react-apexcharts"
import dataChart from "../data.json"
import { Empty } from "antd"
const Age = ({ localSelected, polygon }) => {

  const [series, setSeries] = useState([])
  const categories = ["0 aos 4", "5 aos 9", "10 aos 13", "14 aos 19", "20 aos 24", "25 aos 64", "65 anos"]

  const properties = [
    "n_individuos_resident_0a4",
    "n_individuos_resident_5a9",
    "n_individuos_resident_10a13",
    "n_individuos_resident_14a19",
    "n_individuos_resident_20a24",
    "n_individuos_resident_25a64",
    "n_individuos_resident_65",
  ]

  function fetchData(localSelected) {
    api.get("geoservices/features/group/count/properties", {
      params: {
        local: localSelected.local ? localSelected.local : undefined,
        bgri: localSelected.bgri ? localSelected.bgri : undefined,
        search: localSelected.search ? localSelected.search : undefined,
        properties: properties.toString()
      }
    }).then((response) => { handleSeries(response.data || []) })
  }

  function handleSeries(_data) {
    let _series = []
    _data.forEach(i => {
      switch (i.name) {
        case "n_individuos_resident_0a4":
          _series.push({ value: i.value, index: 1 })
          break
        case "n_individuos_resident_5a9":
          _series.push({ value: i.value, index: 2 })
          break
        case "n_individuos_resident_10a13":
          _series.push({ value: i.value, index: 3 })
          break
        case "n_individuos_resident_14a19":
          _series.push({ value: i.value, index: 4 })
          break
        case "n_individuos_resident_20a24":
          _series.push({ value: i.value, index: 5 })
          break
        case "n_individuos_resident_25a64":
          _series.push({ value: i.value, index: 6 })
          break
        case "n_individuos_resident_65":
          _series.push({ value: i.value, index: 7 })
          break
        default:
      }
    })
    setSeries([{ data: _series.sort(function (a, b) { return a.index - b.index }).map(i => i.value) }])
  }

  useEffect(() => {
    if (localSelected && (localSelected.local || localSelected.search))
      fetchData(localSelected)
  }, [localSelected])

  useEffect(() => {
    if (polygon && polygon.bgri)
      fetchData(polygon)
  }, [polygon])

  return (
    <>
      <span>
        Número de Indivíduos Residentes por Idade
      </span>
      <div className="properties-content">
        {series.length ? <Chart
          options={{
            ...dataChart.options,
            xaxis: {
              type: "category",
              categories: categories,
            },
            colors: ['#33CCCC'],
            chart: {
              toolbar: { show: false },
            },
          }}
          series={series}
          type="bar"
          height="100%"
          width="100%"
        /> : <Empty description="Selecione uma opção no filtro"></Empty >}

      </div>
    </>
  )
}

export default Age
