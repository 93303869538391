
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import api from "../../../../services/api";

import "./style.css";
import Widget from "./widget";

const Statistics = ({ dateStart, dateEnd, camera, enter, exit }) => {


    const handleLoad = () => {
        return dateEnd && dateEnd && (moment(dateEnd).dayOfYear() - moment(dateStart).dayOfYear()) < 2;
    }

    const [data, setData] = useState([
        {
            title: "Entradas",
            icon: "enter.svg",
            value: 0,
            start: dateStart ? moment(dateStart).utc() : undefined,
            end: dateEnd ? moment(dateEnd).utc() : undefined,
            area: enter,
            action: " entradas"
        },
        {
            title: "Saidas",
            icon: "logout-2.svg",
            value: 0,
            start: dateStart ? moment(dateStart).utc() : undefined,
            end: dateEnd ? moment(dateEnd).utc() : undefined,
            area: exit,
            action: " saídas"
        }
    ]);

    const fetchData = async (camera, element) => {
        try {
            let { data } = await api.post("/monitoring/hikvision/count/date", {
                id: camera,
                event: "linedetection",
                target: "human",
                start: element.start,
                end: element.end,
                area: element.area
            });
            return data.count;
        } catch (error) {
            return 0;
        }
    };

    const handleData = async (camera) => {
        let _data = [];
        for (var a = 0; a < data.length; a++) {
            data[a].start = dateStart ? moment(dateStart).utc() : undefined;
            data[a].end = dateEnd ? moment(dateEnd).utc() : undefined;
            let value = await fetchData(camera, data[a]);
            data[a].value = value;
            _data.push(data[a]);
        }
        setData(_data);
        return true;
    }


    const reload = async () => {
        if (handleLoad() && camera) {
            await handleData(camera);
        }
    }

    useEffect(() => {
        if (camera) handleData(camera);
        const interval = setInterval(() => {
            reload();
        }, 1000);
        return () => clearInterval(interval);
    }, [camera, dateStart, dateEnd]);

    return (
        <> Informação Geral
            <div className="contentCountPeopleWidget">
                {data.map((element) => {
                    return <Widget
                        icon={element.icon}
                        title={element.title}
                        value={`${element.value}`}
                    />
                })}
            </div>
        </>

    );
}

export default Statistics;