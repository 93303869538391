import { Select } from 'antd';
import 'moment/locale/pt';
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import api from "../../../services/api";
import data from "../data.json";

var pt = require("apexcharts/dist/locales/pt.json");

const Precipitation = () => {

    const [series, setSeries] = useState([])
    const [categories, setCategories] = useState([])
    const [day, setDay] = useState(10)

    const fetchsPrecipitation = () => api.get(`/meteorology/forecast/precipitation/${day}`).then((response) => setSeries(generateSeries(response.data || [])));

    const generateSeries = (_data) => {
        let date = [];
        _data.forEach((element) => {
            if (!date.includes(element.date))
                date.push(element.date);
        });
        setCategories(date)
        return [
            {
                name: "Max",
                data: date.map((_date) => (_data.find((max) => max.date === _date)?.maximum || 0).toFixed(4)),
            },
            {
                name: "Min",
                data: date.map((_date) => (_data.find((min) => min.date === _date)?.minimum || 0).toFixed(4)),
            }
        ];
    };

    useEffect(fetchsPrecipitation, [day]);

    return (<div style={{ marginTop: "5px" }}>
        <span>Precipitação total diária</span>

        <div className="contentParkWidget" style={{ marginTop: "0px" }}>
            <Select bordered={false} defaultValue="7" style={{ width: 150 }} onChange={(value) => setDay(value)}>
                <Select.Option value="7">Últimos 7 dias</Select.Option>
                <Select.Option value="10">Últimos 10 dias</Select.Option>
                <Select.Option value="20">Últimos 20 dias</Select.Option>
                <Select.Option value="30">Últimos 30 dias</Select.Option>
            </Select>
            <Chart
                options={{
                    ...data.options,
                    yaxis: {
                        labels: {
                            formatter: function (val, index) {
                                return `${val.toLocaleString()}mm`;
                            }
                        }
                    },
                    xaxis: {
                        ...data.options.xaxis,
                        type: "datetime",
                        categories: categories,
                        labels: {
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: 'MMM \'yy',
                                day: 'dddd, dd/MMM',
                            }
                        }
                    },
                    chart: {
                        locales: [pt],
                        defaultLocale: "pt",
                        toolbar: { show: false }
                    },
                }}
                series={series}
                type="line"
                height={250}
                width="100%" />
        </div>
    </div>)
}

export default Precipitation