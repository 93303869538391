import { Col, Row } from "antd";
import React from "react";
import "./style.css";

const Widget = ({ icon, description, value }) => {

    return (
        <div className="wasteFleetsWidget">
            <Row>
                <Col xs={24} md={5} style={{ textAlign: "center" }}>
                    <img src={require(`../../../../assets/icons/waste/${icon}`)} />
                </Col>
                <Col xs={24} md={13}>
                    <span className="wasteFleetsDescription">
                        {description}
                    </span>
                </Col>
                <Col xs={24} md={6}>
                    <span className="wasteFleetsValue">
                        {value}
                    </span>
                </Col>
            </Row>
        </div>
    );
}

export default Widget;