import { Descriptions } from "antd"
import React from "react"

const DetailsStreetLight = ({ data }) => {
    return (
        <Descriptions title="Luminária">
            <Descriptions.Item key="id" span={3} label="id">{data.id}</Descriptions.Item>
            <Descriptions.Item key="Grupo" span={3} label="Grupo">{data.group ? data.group.name : ""}</Descriptions.Item>
            <Descriptions.Item key="Estado" span={3} label="Estado">{data.status.description}</Descriptions.Item>
            <Descriptions.Item key="Potência" span={3} label="Potência">{data.power}</Descriptions.Item>
            <Descriptions.Item key="Quant. Lâmpadas" span={3} label="Quant. Lâmpadas">{data.quantityOfLamps}</Descriptions.Item>
        </Descriptions>
    )
}
export default DetailsStreetLight