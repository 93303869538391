import { DatePicker, Modal, Space } from "antd";
import moment from "moment-timezone";
import React, { useContext, useEffect } from "react";
import { MenuContext } from "../../context/menu";
import "./style.css";

export const defaultStart = MenuContext.defaultStart;

export const defaultStop = MenuContext.defaultStop;

const Menu = (props) => {

  const { valueSelected, setValueSelected, data, setData } = useContext(MenuContext);

  let { onClicked } = props;

  function onClickHandler(_value) {
    let newData = {};
    switch (_value) {
      case "now":
        newData.start = moment().set({ h: 0, m: 0, s: 0 });
        newData.end = moment().set({ h: 23, m: 59, s: 59 });
        break;
      case "yesterday":
        newData.start = moment().subtract(1, "days").set({ h: 0, m: 0, s: 0 });
        newData.end = moment().subtract(1, "days").set({ h: 23, m: 59, s: 59 });
        break;
      case "7days":
        newData.start = moment().subtract(7, "d");
        newData.end = moment();
        break;
      case "lastlmonth":
        newData.start = moment().subtract(1, "M");
        newData.end = moment();
        break;
      case "all":
        newData.start = undefined;
        newData.end = undefined;
        break;
      default:
    }
    setData(newData);
    setValueSelected(_value);
  }

  useEffect(() => {
    if (onClicked) {
      onClicked(data);
    }
  }, [data]);

  const { RangePicker } = DatePicker;

  const selectDate = () => {
    Modal.warn({
      title: 'Seleccionar data',
      content: (
        <div>
          <RangePicker
            onChange={(newValue) => {
              setData({
                start: newValue[0],
                end: newValue[1]
              });
            }}
            defaultValue={[data.start, data.end]}
            showTime={true}
            format={'DD/MM/YYYY HH:mm'} />
        </div>
      ),
      onOk() { },
    });
  }

  return (
    <>
      <div id="menu" style={{ textAlign: "right" }}>
        <Space align="start" direction="horizontal" size={12} wrap>
          <a
            className={valueSelected === "now" ? "selected" : ""}
            onClick={() => onClickHandler("now")}
          >
            Hoje
          </a>

          <a
            className={valueSelected === "yesterday" ? "selected" : ""}
            onClick={() => onClickHandler("yesterday")}
          >
            Ontem
          </a>

          <a
            className={valueSelected === "7days" ? "selected" : ""}
            onClick={() => onClickHandler("7days")}
          >
            Últimos 7 dias
          </a>

          <a
            className={valueSelected === "lastlmonth" ? "selected" : ""}
            onClick={() => onClickHandler("lastlmonth")}
          >
            Último mês
          </a>

          <a
            className={valueSelected === "all" ? "selected" : ""}
            onClick={() => onClickHandler("all")}
          >
            Todos
          </a>

          <a
            className={valueSelected === "selection" ? "selected" : ""}
            onClick={() => {
              onClickHandler("selection");
              selectDate();
            }}
          >
            {valueSelected === "selection" && data.start && data.end ?
              <>
                {moment(data?.start).format('DD/MM/YY HH:mm')} - {moment(data?.end).format('DD/MM/YY HH:mm')}
              </>
              : 'Seleccionar data'
            }
          </a>
        </Space>
      </div>
    </>
  );
};

export default Menu;
