import { Col, Row } from "antd";
import React, { useContext, useState } from "react";
import Menu from "../../components/menu";
import { MenuContext } from "../../context/menu";
import Co2 from "./co2";
import ListShippers from "./listShippers";
import LoadAverage from './loadAverage';
import Map from "./map";
import "./style.css";
import SuppliedEnergy from "./suppliedEnergy";
import TaxRate from "./taxRate";

const ElectricMobility = () => {

    const { data } = useContext(MenuContext);
    const [dateStart, setDateStart] = useState(data.start);
    const [dateEnd, setDateEnd] = useState(data.stop);
  
    const onClickedMenu = (data) => {
      let { start, end } = data;
      setDateStart(start);
      setDateEnd(end);
    };

    return (
        <>
            <Row gutter={16}>
                <Col xs={24}>
                    <Menu onClicked={onClickedMenu} />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24} lg={12}>
                    <Map />
                    <TaxRate dateStart={dateStart} dateEnd={dateEnd} />
                    <Co2 dateStart={dateStart} dateEnd={dateEnd}  />
                </Col>
                <Col xs={24} lg={12}>
                    <Row gutter={24} style={{ marginTop: "0.8em" }}>
                        <Col xs={24} lg={12} style={{ marginTop: "1em" }}>
                            <SuppliedEnergy dateStart={dateStart} dateEnd={dateEnd} />
                        </Col>
                        <Col xs={24} lg={12} style={{ marginTop: "1em" }}>
                            <LoadAverage dateStart={dateStart} dateEnd={dateEnd} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <ListShippers dateStart={dateStart} dateEnd={dateEnd} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default ElectricMobility;