import React from "react"
import "../../style.css";
import { Link } from "react-router-dom";

const MobilityDemand = () => {

    const partner = <Link to="/ctec/contacts?partner=CEIIA">CEiiA</Link>

    return (
        <div>
            <iframe title="Report Section" className="frame-content" src="https://app.powerbi.com/view?r=eyJrIjoiZGNjOWU2ZGUtYTlhNS00ZDk2LTkwMTktZGNmNDExMWEyMDZkIiwidCI6IjUxZjJkOGViLWQxNzEtNGE2OC05YzM3LTBkNzhiMzk2ZWYzZCIsImMiOjh9" frameborder="0" allowFullScreen="true"></iframe>
            <div>
                <p>Built BY {partner}</p>
            </div>
        </div>
    )
}
export default MobilityDemand