import React from "react";
import "../style.css";
import icon from "../../../../assets/icons/folder.svg";
import { Col, Row } from "antd";

const Deaths = ({ deaths }) => {
  return (
    <div className="card_covid">
      <Row className="card_covid_row">
        <Col xs={24} className="icon-deaths">
          <img src={icon} alt="" style={{ width: "40px" }} />
        </Col>
        <Col xs={24} style={{ paddingTop: "1em" }}>
          <span>{deaths}</span>
        </Col>
        <Col xs={24}>
          <span>total mortes</span>
        </Col>
      </Row>
    </div>
  );
};

export default Deaths;
