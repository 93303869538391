
import React, { useEffect, useState } from "react"
import api from "../../../services/api"
import Chart from "react-apexcharts"
import dataChart from "../data.json"
import { Col, Empty, Row } from "antd"
import { capitalize } from "../../../util/StringUtil"

const Schooling = ({ localSelected, polygon, identifier }) => {

  const [series, setSeries] = useState([])
  const [categories, setCategories] = useState([])

  const properties = [
    "n_ind_resident_fensino_sup",
    "n_ind_resident_fensino_sec",
    "n_ind_resident_fensino_possec",
    "n_ind_resident_fensino_2bas",
    "n_ind_resident_fensino_1bas",
    "n_ind_resident_ensincomp_sup",
    "n_ind_resident_ensincomp_sec",
    "n_ind_resident_ensincomp_posec",
    "n_ind_resident_ensincomp_3bas",
    "n_ind_resident_ensincomp_2bas",
    "n_ind_resident_ensincomp_1bas",
    "n_indiv_resident_n_ler_escrv",
  ]

  function fetchData(localSelected) {
    api.get("geoservices/features/group/count/properties", {
      params: {
        local: localSelected.local ? localSelected.local : undefined,
        bgri: localSelected.bgri ? localSelected.bgri : undefined,
        search: localSelected.search ? localSelected.search : undefined,
        properties: properties.toString()
      }
    }).then((response) => { handleSeries(response.data || []) })
  }

  function handleSeries(_data) {
    let _series = []
    _data.forEach(i => {
      switch (i.name) {
        case "n_ind_resident_fensino_sup":
          _series.push({ value: i.value, index: 1 })
          break
        case "n_ind_resident_fensino_sec":
          _series.push({ value: i.value, index: 2 })
          break
        case "n_ind_resident_fensino_possec":
          _series.push({ value: i.value, index: 3 })
          break
        case "n_ind_resident_fensino_2bas":
          _series.push({ value: i.value, index: 4 })
          break
        case "n_ind_resident_fensino_1bas":
          _series.push({ value: i.value, index: 5 })
          break
        case "n_ind_resident_ensincomp_sup":
          _series.push({ value: i.value, index: 6 })
          break
        case "n_ind_resident_ensincomp_sec":
          _series.push({ value: i.value, index: 7 })
          break
        case "n_ind_resident_ensincomp_posec":
          _series.push({ value: i.value, index: 8 })
          break
        case "n_ind_resident_ensincomp_3bas":
          _series.push({ value: i.value, index: 9 })
          break
        case "n_ind_resident_ensincomp_2bas":
          _series.push({ value: i.value, index: 10 })
          break
        case "n_ind_resident_ensincomp_1bas":
          _series.push({ value: i.value, index: 11 })
          break
        case "n_indiv_resident_n_ler_escrv":
          _series.push({ value: i.value, index: 12 })
          break
        default:
      }
    })
    setSeries([{ data: _series.sort(function (a, b) { return a.index - b.index }).map(i => i.value) }])
  }

  function fetchDesc(name) {
    let indent = identifier.find(i => i.codigo === name)
    return (indent && indent.desc ? indent.desc.substring(22, indent.desc.length) : name).trim()
  }

  useEffect(() => {
    if (identifier) {
      let _categories = [
        capitalize(fetchDesc("n_ind_resident_fensino_sup")),
        capitalize(fetchDesc("n_ind_resident_fensino_sec")),
        capitalize(fetchDesc("n_ind_resident_fensino_possec")),
        capitalize(fetchDesc("n_ind_resident_fensino_2bas")),
        capitalize(fetchDesc("n_ind_resident_fensino_1bas")),
        capitalize(fetchDesc("n_ind_resident_ensincomp_sup")),
        capitalize(fetchDesc("n_ind_resident_ensincomp_sec")),
        capitalize(fetchDesc("n_ind_resident_ensincomp_posec")),
        capitalize(fetchDesc("n_ind_resident_ensincomp_3bas")),
        capitalize(fetchDesc("n_ind_resident_ensincomp_2bas")),
        capitalize(fetchDesc("n_ind_resident_ensincomp_1bas")),
        capitalize(fetchDesc("n_indiv_resident_n_ler_escrv")),
      ]
      setCategories(_categories)
    }
  }, [identifier])


  useEffect(() => {
    if (localSelected && (localSelected.local || localSelected.search))
      fetchData(localSelected)
  }, [localSelected])

  useEffect(() => {
    if (polygon && polygon.bgri)
      fetchData(polygon)
  }, [polygon])

  return (
    <>
      <span>
        Número de Indivíduos Residentes por Escolaridade
      </span>
      <div className="properties-content" style={{ minHeight: "25em" }}>
        {series.length > 0 ? <>
          <Chart
            options={{
              ...dataChart.options,
              plotOptions: {
                bar: {
                  barHeight: '80%',
                  distributed: true,
                  horizontal: true,
                }
              },
              colors: ['#7DA3EB', '#7DA3EB', '#7DA3EB', '#7DA3EB', '#7DA3EB', '#ED7D31', '#ED7D31', '#ED7D31', '#ED7D31', '#ED7D31', '#ED7D31', '#33CCCC'],
              xaxis: {
                type: "category",
                categories: categories,
              },
              chart: {
                toolbar: { show: false },
              },
            }}
            series={series}
            type="bar"
            height="90%"
            width="100%"
          />
          <Row >
            <Col xs={24} sm={8} >
              <div style={{ textAlign: "center", margin: "auto" }}>
                <table>
                  <tr>
                    <td><div style={{ width: "20px", height: "20px", backgroundColor: "#7DA3EB" }}> </div></td>
                    <td>A frequentar ensino</td>
                  </tr>
                </table>
              </div>
            </Col>
            <Col xs={24} sm={8} >
              <div style={{ textAlign: "center", margin: "auto" }}>
                <table>
                  <tr>
                    <td><div style={{ width: "20px", height: "20px", backgroundColor: "#ED7D31" }}> </div></td>
                    <td>Ensino completo</td>
                  </tr>
                </table>
              </div>
            </Col>
            <Col xs={24} sm={8} >
              <div style={{ textAlign: "center", margin: "auto" }}>
                <table>
                  <tr>
                    <td><div style={{ width: "20px", height: "20px", backgroundColor: "#33CCCC" }}> </div></td>
                    <td>Sem saber ler nem escrever</td>
                  </tr>
                </table>
              </div>
            </Col>
          </Row>
        </> : <Empty description="Selecione uma opção no filtro"></Empty >}
      </div>
    </>
  )
}

export default Schooling
