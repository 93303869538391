import { Col, Row } from "antd";
import React from "react";
import "./style.css";

const Widget = ({ icon, title, value }) => {

    return (
        <div className="widget_info_parking">
            <Row>
                <Col xs={3} md={2}>
                    <img
                        className="icon"
                        src={require(`../../../../assets/icons/parking/widget/${icon}`)} />
                </Col>
                <Col xs={21} md={13}>
                    <span className="title">
                        {title}
                    </span>
                </Col>
                <Col xs={24} md={9} style={{ textAlign: "right" }}>
                    <span className="value">
                        {value}
                    </span>
                </Col>
            </Row>
        </div>
    )
}

export default Widget;