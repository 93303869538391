import { Col, Row } from "antd";
import React from "react";
import icon from "../../../../assets/icons/buildings/consumo_termino.svg";
import "./style.css";

const ThermalConsumption = () => {


    return (
        <>
            <span>
                Consumo térmica
            </span>
            <div className="buildingsThermalConsumptionContent">
                <Row>
                    <Col xs={24} md={4}>
                        <img src={icon} />
                    </Col>
                    <Col xs={24} md={10} className="title">
                        Térmica Global do Edificio
                    </Col>
                    <Col xs={24} md={10} className="value">
                        6 kWh
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ThermalConsumption;