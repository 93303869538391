import { Col, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import Carousel from "react-elastic-carousel";
import "./style.css";

const Week = () => {

    const [forecastWeather, setForecastWeather] = useState([])

    const fetchsForecastWeather = () => api.get('/meteorology/forecast/weather').then((response) => setForecastWeather(response.data));

    function getDayName(dateStr) {
        var date = new Date(dateStr);
        return capitalizeFirstLetter(date.toLocaleDateString('Pt-pt', { weekday: 'long' }));
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => { fetchsForecastWeather() }, []);

    const breakPoints = [
        { width: 250, itemsToShow: 1 },
        { width: 450, itemsToShow: 2, itemsToScroll: 2 },
        { width: 700, itemsToShow: 3, itemsToScroll: 3 },
    ];

    const carouselRef = React.useRef(null);
    let resetTimeout;

    return (
        <Carousel
            ref={carouselRef}
            style={{ marginLeft: "-5px" }}
            showArrows={false}
            breakPoints={breakPoints}
            enableAutoPlay={true}
            autoPlaySpeed={4000}
            onNextEnd={({ index }) => {
                if (
                    carouselRef?.current.state.activePage ===
                    carouselRef?.current.state.pages.length - 1
                ) {
                    const itemsPerPage = Math.floor(
                        carouselRef?.current.props.children.length /
                        carouselRef?.current.getNumOfPages()
                    );
                    if (itemsPerPage === carouselRef?.current.state.activeIndex) {
                        clearTimeout(resetTimeout);
                        resetTimeout = setTimeout(() => {
                            carouselRef?.current?.goTo(0);
                        }, 4000); // same time
                    }
                }
            }}
        >
            {(forecastWeather || []).map(i => (
                <div style={{ padding: "10px" }}>
                    <div className="solarWidget" >
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={12} lg={6}>
                                <img width={75} alt="" style={{ marginLeft: "-15px" }} src={require(`../../../assets/icons_ipma_weather/w_ic_d_${i.idWeatherType < 10 ? '0' + i.idWeatherType : i.idWeatherType}anim.svg`)} />
                            </Col>
                            <Col xs={24} md={12} lg={18} style={{ paddingLeft: "10px" }}>
                                <Col xs={24}>
                                    <span className="wasteFleetsDescription">
                                        <span>{getDayName(i.forecastDate)},&nbsp;</span>
                                        <span>{moment(i.forecastDate).format('DD/MM')}</span>
                                    </span>
                                </Col>
                                <Col xs={24}>
                                    <span className="wasteFleetsDescription">
                                        <b>Min:</b> {i.tMin}°&nbsp;&nbsp;&nbsp;&nbsp;
                                        <b>Max:</b> {i.tMax}°
                                    </span>
                                </Col>

                                <Col xs={24}>
                                    <span className="wasteFleetsDescription">
                                        <div><b>Direção do vento:</b> {i.predWindDir}</div>
                                        <div><b>Precipitação:</b> {i.precipitaProb}%</div>
                                    </span>
                                </Col>
                                <span style={{ visibility: 'hidden' }}>{i.dataUpdate}</span>
                            </Col>
                        </Row>
                    </div>
                </div>
            ))}
        </Carousel>
    )
}

export default Week