import React from "react";
import "./style.css";

const Info = ({ value, description }) => {
    return (
        <div className="parkingContentInfo">
            <span className="title">{value}</span> <br/>
            <span>{description}</span>
        </div>
    );
}

export default Info;