
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import api from "../../../../../services/api";
import "../style.css";

const Day = ({ camera, dateStart, dateEnd, enter, exit }) => {

    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    const fetchData = () => {
        try {
            api
                .post("/monitoring/hikvision/group/date", {
                    id: camera,
                    event: "linedetection",
                    target: "human",
                    start: dateStart ? moment(dateStart).utc() : undefined,
                    end: dateEnd ? moment(dateEnd).utc() : undefined,
                    timezone:moment.tz.guess()
                })
                .then((response) => {
                    setSeries(generateSeries(response.data));
                })
        } catch (error) {
            setSeries([]);
        }
    };

    const handleDay = (day, date) => {
        return day ? date + ':00' : date
    }

    const generateSeries = (_data = []) => {
        let dates = [];
        let _enter = [];
        let _exits = [];
        let day = _data.length > 0 ? _data[0].date.length > 11 : false;
        _data.forEach((element) => {
            if (!dates.includes(handleDay(day, element.date)))
                dates.push(handleDay(day, element.date));
            if (element.key === enter)
                _enter.push(element);
            if (element.key === exit)
                _exits.push(element);
        });

        setCategories(dates);
        return [
            {
                name: "Entradas",
                data: dates.map(
                    (_date) =>
                        _enter.find((item) => handleDay(day, item.date) === _date)?.value || 0
                ),
            }
            ,
            {
                name: "Saídas",
                data: dates.map(
                    (_date) =>
                        _exits.find((item) => handleDay(day, item.date) === _date)?.value || 0
                ),
            }
        ]

    }

    useEffect(() => { if (camera) fetchData() }, [camera, dateStart, dateEnd])

    return (
        <>
            <span>Grafico de entrada e saída por dia</span>
            <div className="graphic_content">
                <ReactApexChart options={{
                    chart: {
                        height: 200,
                        type: 'area',
                        toolbar: {
                            show: true
                        }
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    legend: {
                        show: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: categories
                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yy HH:mm'
                        },
                    },
                    colors: ['#26E7A5', '#FEB019']
                }}
                    series={series}
                    type="area"
                    height={270} />
            </div>
        </>
    );
}

export default Day;