import { Col, Row } from "antd";
import "./style.css";

const Widget = ({ icon, title, value }) => {

    return (
        <div className="widget_count_people">
            <Row>
                <Col xs={3} md={2}>
                    <img alt=""
                        className="icon"
                        src={require(`../../../../../assets/icons/buildings/occupation/${icon}`)} />
                </Col>
                <Col xs={21} md={13}>
                    <span className="title">
                        {title}
                    </span>
                </Col>
                <Col xs={24} md={9} style={{ textAlign: "right" }}>
                    <span className="value" style={{ fontWeight: "bold", fontSize: "110%" }}>
                        {value}
                    </span>
                </Col>
            </Row>
        </div>
    )
}

export default Widget;