import React from "react"
import "../../style.css";
import { Link } from "react-router-dom";

const Buildingenergyconsumptiontool = () => {
    const partner = <Link to="/ctec/contacts?partner=IST">IST</Link>
    
    return (<div><iframe className="frame-content" src="//goncalo-araujo-energy-app-adene-policy-j4nrn8.streamlit.app/~/+/" title="Bike Sharing Occupation"></iframe>
            <div><p>Built BY {partner}</p></div></div>)
}

export default Buildingenergyconsumptiontool