import { Col, Row } from "antd";
import React from "react";
import Loading from "../../../../../components/loading";
import WidgetError from "../../../../../components/error";
import "./style.css";

const WidgetInfo = ({ error, loading, icon, title, value, subValue }) => {
  return (
    error ?
      <WidgetError style={{ fontSize: ".9em", marginBottom: '1em' }} />
      :
      <div className="streetLightingWidgetInfo">
        <Row>
          {loading ? (
            <Loading style={{ height: "100px" }} />
          ) :
            <>
              <Col xs={24} md={3} lg={2} className="center">
                <img
                  src={`${require(`../../../../../assets/icons/solar/${icon}`)}`}
                  alt=""
                />
              </Col>
              <Col xs={24} md={15} lg={14}>
                <span className="streetLightingWidgetTitle">{title}</span>
              </Col>
              <Col xs={24} md={6} lg={8} style={{ textAlign: "right" }}>
                <span className="streetLightingWidgetValue">{(value || 0).toLocaleString()}</span>
                <span>&nbsp;{subValue}</span>
              </Col>
            </>
          }
        </Row>
      </div>
  );
};

export default WidgetInfo;
