import { Descriptions } from "antd"
import moment from "moment-timezone"
import React from "react"

const DetailsOccurrences = ({ data }) => {
    return (
        <Descriptions title="Ocorrência">
            <Descriptions.Item key="ID" span={3} label="ID">{data.id}</Descriptions.Item>
            <Descriptions.Item key="Estado" span={3} label="Estado">{data.status.description}</Descriptions.Item>            
            <Descriptions.Item key="Categoria" span={3} label="Categoria">{data.category ? data.category.name : ''}</Descriptions.Item>
            <Descriptions.Item key="Descrição" span={3} label="Descrição">{data.description}</Descriptions.Item>
            <Descriptions.Item key="Data de abertura" span={3} label="Data de abertura">{moment.utc(data.creationDate).local().format("DD/MM/yyyy HH:mm")}</Descriptions.Item>
            <Descriptions.Item key="Tempo de resolução" span={3} label="Tempo de resolução">{data.timeResolution ? data.timeResolution.description : ''}</Descriptions.Item>
        </Descriptions>
    )
}
export default DetailsOccurrences
