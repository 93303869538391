import { Col, Row } from "antd";
import React, { useContext, useState } from "react";
import Menu from "../../components/menu";
import { MenuContext } from "../../context/menu.js";
import GeneralInfo from "./generalInfo";
import GraphicDistributionConsumption from "./graphicDistributionConsumption";
import GraphicDistributionCost from "./graphicDistributionCost";
import GraphicDistributionPrice from "./graphicDistributionPrice";

const StreetLightingDetail = () => {

  const { data } = useContext(MenuContext);
  const [dateStart, setDateStart] = useState(data.start);
  const [dateEnd, setDateEnd] = useState(data.stop);

  const onClickedMenu = (data) => {
    let { start, end } = data;
    setDateStart(start);
    setDateEnd(end);
  };

  return (
    <>
      <Menu onClicked={onClickedMenu} />
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col xs={24} lg={12}>
          <GeneralInfo dateStart={dateStart} dateEnd={dateEnd} />
        </Col>
        <Col xs={24} lg={12}>
          <GraphicDistributionCost dateStart={dateStart} dateEnd={dateEnd} />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col xs={24} md={12}>
          <GraphicDistributionConsumption
            dateStart={dateStart}
            dateEnd={dateEnd}
          />
        </Col>
        <Col xs={24} md={12}>
          <GraphicDistributionPrice dateStart={dateStart} dateEnd={dateEnd} />
        </Col>
      </Row>
    </>
  );
};

export default StreetLightingDetail;
