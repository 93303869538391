import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import ErrorWidget from "../../../components/error";
import api from "../../../services/api";
import data from "./data.json";
import "./style.css";

const ChartHour = ({ sections, hours, range }) => {

    const [registers, setRegisters] = useState([]);
    const [error, setError] = useState();

    const fetchData = () => {
        if (range) {
            setError(undefined);
            api.post(`/mobility/statifyRegister/statistic/hours`, {
                sections: sections || [],
                hours: hours || [],
                range: range.id
            }).then((response) => {
                setRegisters(response.data);
            }).catch((error) => {
                setError(error?.response?.data?.message || "unknown error");
            });
        }
    }

    useEffect(fetchData, [sections, hours]);

    return (
        <>
            <div style={{ marginTop: "2.5em" }}>
                <span>
                    Gráfico de passagens por horário
                </span>
            </div>
            {
                error ? <ErrorWidget message={error} refresh={fetchData} /> :
                    <div className="charHour">
                        <Chart options={{
                            ...data.options,
                            yaxis: {
                                labels: {
                                    formatter: function (value) {
                                        return (
                                            (value)?.toLocaleString(undefined, {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 4,
                                            })
                                        );
                                    },
                                },
                            }
                        }}
                            series={[
                                function () {
                                    return {
                                        name: "Passagens",
                                        data: data.options.xaxis.categories.map((ele) => registers.find((reg) => `${reg.hour}h` === ele)?.value || 0)
                                    };
                                }()
                            ]} type="area" height={300} />
                    </div>
            }
        </>
    );
}

export default ChartHour;