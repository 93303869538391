import { ContactsOutlined, MailOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Col, Row, Upload } from "antd";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/auth";
import api, { getToken } from "../../services/api";
import "./style.css";
import UpdatePassword from "./updatePassword";

const UserInfo = () => {
  const { user } = useContext(AuthContext);
  const [visible, setVisible] = useState("none");

  const requestUpload = (request) => {
    if (request.file.type.startsWith("image")) {
      let formData = new FormData();
      formData.append("file", request.file);
      api
        .post(`/storage/upload?policy=authenticated`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          request.onSuccess();
          api
            .put("/oauth/users/profile", null, {
              params: {
                avatar: response.data,
              },
            })
            .then((response) => {
              let _user = localStorage.getItem(
                process.env.REACT_APP_KEY_STORAGE_USER
              );
              if (_user) {
                _user = JSON.parse(_user);
                localStorage.setItem(
                  process.env.REACT_APP_KEY_STORAGE_USER,
                  JSON.stringify({ ..._user, ...response.data })
                );
              }
              document.location.reload(true);
            });
        })
        .catch((e) => {
          request.onError(e);
        });
    } else {
    }
  };

  const onMouseOver = () => {
    setVisible("");
  };

  const onMouseLeave = () => {
    setVisible("none");
  };

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col
          xs={24}
          lg={8}
          className="user-info-container"
          style={{ marginTop: 22, padding: 20 }}
        >
          <Badge onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
            <Avatar
              size={88}
              className="gx-size-90"
              src={
                user?.profile
                  ? `${process.env.REACT_APP_API_URL}/storage/render/${user?.profile
                  }/${btoa(getToken())}`
                  : undefined
              }
              icon="user"
            />
            <Upload
              name="file"
              accept="image/*"
              multiple={false}
              customRequest={requestUpload}
            >
              <Button type="link" className="link" style={{ display: visible }}>
                Alterar
              </Button>
            </Upload>
          </Badge>
          <span className="user-info-name">{user?.name}</span>
          <br />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col
          xs={24}
          lg={8}
          className="user-info-container"
          style={{ marginTop: 16, padding: 20 }}
        >
          <spam>DADOS PESSOAIS</spam>
          <div className="user-info-container-dados">
            <div className="user-info-icon">
              <MailOutlined size={88} />
            </div>
            <div className="user-info-dados">
              <span>E-mail</span>
              <span className="text">{user?.email}</span>
            </div>
          </div>
          <div className="user-info-container-dados">
            <div className="user-info-icon">
              <ContactsOutlined size={88} />
            </div>
            <div className="user-info-dados">
              <span>Utilizador</span>
              <span className="text">{user?.username}</span>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col
          xs={24}
          lg={8}
          className="user-info-container"
          style={{ marginTop: 16, padding: 20 }}
        >
          <spam>ALTERAR PALAVRA-PASSE</spam>
          <UpdatePassword user={user} />
        </Col>
      </Row>
    </>
  );
};

export default UserInfo;
