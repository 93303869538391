import { Col, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import WidgetError from "../../../components/error";
import api from "../../../services/api";
import data from "./data.json";
import "./style.css";

const Graphic = ({ dateStart, dateEnd }) => {
  const [series, setSeries] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const fetchData = () => {
    setLoading(true);
    setError(false);
    api
      .post("/helpdesk/ticket/count/item/group/situation/date/chart", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        today: dateEnd ? moment(dateEnd).utc() : undefined,
        type: "light_fixture",
      })
      .then((response) => {
        setSeries(generateSeries(response.data));
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateSeries = (_data = []) => {
    let dates = [];
    let closed = [];
    let open = [];
    let running = [];
    _data.forEach((element) => {
      if (!dates.includes(element.date)) dates.push(element.date);
      if (element.situation === "closed") {
        closed.push(element);
      } else if (element.situation === "running") {
        running.push(element);
      } else if (element.situation === "open") {
        open.push(element);
      }
    });
    setCategorys(dates);
    return [
      {
        name: "Ocorrências fechadas",
        data: dates.map(
          (_date) =>
            closed.find((_closed) => _closed.date === _date)?.count || 0
        ),
      },
      {
        name: "Ocorrências em aberto",
        data: dates.map(
          (_date) => open.find((_open) => _open.date === _date)?.count || 0
        ),
      },
      {
        name: "Ocorrências em resolução",
        data: dates.map(
          (_date) =>
            running.find((_running) => _running.date === _date)?.count || 0
        ),
      },
    ];
  };

  useEffect(fetchData, [dateStart, dateEnd]);

  return (
    <>
      <Col xs={24} style={{ marginTop: "1em" }}>
        <span>Gráfico de Ocorrências</span>
      </Col>
      {error && !loading && <WidgetError message={error} refresh={fetchData} />}
      {!error && (
        <div className="graphic_content">
          <Row>
            <Col xs={24} md={16}>
              {!loading && (
                <div id="chart-ticket-stats" className="graphic_chart">
                  <Chart
                    options={{
                      ...data.options,
                      xaxis: {
                        type: "datetime",
                        labels: {
                          format: "dd/MM/yyyy",
                        },
                        categories: categorys,
                      },
                    }}
                    series={series}
                    type="area"
                    height="220"
                  />
                </div>
              )}
            </Col>
            <Col xs={24} md={8} style={{ margin: "auto" }}>
              <div className="graphic_states">
                <div className="graphic_circle_group">
                  <div
                    className="graphic_circle"
                    style={{ backgroundColor: "#7fc7af" }}
                  ></div>
                  <span>Ocorrências fechadas</span>
                </div>
                <div className="graphic_circle_group">
                  <div
                    className="graphic_circle"
                    style={{ backgroundColor: "#ffb01a" }}
                  ></div>
                  <span>Ocorrências em aberto</span>
                </div>
                <div className="graphic_circle_group">
                  <div
                    className="graphic_circle"
                    style={{ backgroundColor: "#008ffb" }}
                  ></div>
                  <span>Ocorrências em resolução</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Graphic;
