import React from "react";

function Icon({station, temperatura, time,weatherTypePT }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            viewBox="0 0 349 135"
        >
            <defs>
                <filter
                    id="t94kzop89a"
                    width="222.5%"
                    height="322.7%"
                    x="-61.2%"
                    y="-106.8%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="t2xybtu4cc"
                    width="140%"
                    height="304.2%"
                    x="-20%"
                    y="-97.9%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="o1qij8i6pe"
                    width="162.8%"
                    height="202.1%"
                    x="-31.4%"
                    y="-49%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <filter
                    id="sgmvymtcvg"
                    width="140.6%"
                    height="322.7%"
                    x="-20.3%"
                    y="-106.8%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="8"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
                    ></feColorMatrix>
                </filter>
                <text
                    id="yuez3z27ab"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="18"
                    fontWeight="bold"
                >
                    <tspan x="286" y="104">
                        {time}
                    </tspan>
                </text>
                <text
                    id="7l5ihhms3d"
                    fill="#FFF"
                    fontFamily="JosefinSans-Light, Josefin Sans"
                    fontSize="20"
                    fontWeight="300"
                >
                    <tspan x="10" y="71">
                    {weatherTypePT}
                    </tspan>
                </text>
                <text
                    id="hhjf6xxqqf"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="40"
                    fontWeight="bold"
                >
                    <tspan x="10" y="44">
                        {temperatura}
                    </tspan>
                </text>
                <text
                    id="2seyb591wh"
                    fill="#FFF"
                    fontFamily="JosefinSans-Bold, Josefin Sans"
                    fontSize="18"
                    fontWeight="bold"
                >
                    <tspan x="9.882" y="104">
                        {station}
                    </tspan>
                </text>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#B2DBF1"
                    d="M27.511 9H321.49c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 19.793 342 22.38 342 29.511v73.846c0 7.132-.743 9.718-2.137 12.325a14.538 14.538 0 01-6.048 6.049c-2.608 1.394-5.194 2.137-12.326 2.137H27.51c-7.132 0-9.718-.743-12.326-2.137a14.538 14.538 0 01-6.048-6.049C7.743 113.075 7 110.49 7 103.357V29.51c0-7.132.743-9.718 2.137-12.326a14.538 14.538 0 016.048-6.048C17.793 9.743 20.38 9 27.511 9z"
                ></path>
                <path
                    fill="#C5EAFE"
                    d="M321.489 9c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 19.793 342 22.38 342 29.511V42H144.997C131.192 42 120 30.809 120 16.993V9h201.489zm3.088 80.022c.937 0 1.844.052 2.72.153H342v17.27c0 3.562-.749 6.685-2.142 9.266a14.674 14.674 0 01-6.014 6.014c-2.582 1.394-5.705 2.143-9.267 2.143H24.423c-3.562 0-6.685-.749-9.267-2.143a14.674 14.674 0 01-6.014-6.014C7.75 113.131 7 110.007 7 106.445l.004-.424H7V89.177h14.704a23.524 23.524 0 012.719-.154h300.154z"
                ></path>
                <path
                    stroke="#FFF"
                    strokeDasharray="20,10"
                    strokeLinecap="round"
                    strokeWidth="3"
                    d="M151.5 55.5h173m-173 11h173m-173 10h173"
                ></path>
                <g fill="#FFF" transform="translate(7 9)">
                    <use filter="url(#t94kzop89a)" xlinkHref="#yuez3z27ab"></use>
                    <use xlinkHref="#yuez3z27ab"></use>
                </g>
                <g fill="#FFF" transform="translate(7 9)">
                    <use filter="url(#t2xybtu4cc)" xlinkHref="#7l5ihhms3d"></use>
                    <use xlinkHref="#7l5ihhms3d"></use>
                </g>
                <g fill="#FFF" transform="translate(7 9)">
                    <use filter="url(#o1qij8i6pe)" xlinkHref="#hhjf6xxqqf"></use>
                    <use xlinkHref="#hhjf6xxqqf"></use>
                </g>
                <g fill="#FFF" transform="translate(7 9)">
                    <use filter="url(#sgmvymtcvg)" xlinkHref="#2seyb591wh"></use>
                    <use xlinkHref="#2seyb591wh"></use>
                </g>
            </g>
        </svg>
    );
}

export default Icon;
