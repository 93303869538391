import { GoogleApiWrapper, Map, Marker, InfoWindow } from 'google-maps-react';
import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import pinIcon from "../../../../assets/icons/pin.svg";
import api from "../../../../services/api";
import { AuthContext } from '../../../../context/auth';
import DetailsAssets from '../details';

const _Map = ({ google, title }) => {

    const [assets, setAssets] = useState();
    const { environment } = useContext(AuthContext);
    const _center = { lat: 41.23540438414401, lng: -8.637679925138647 }
    const [activeMarker, setActiveMarker] = useState(null);
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);

    const onClickMarker = (props, marker, a) => {
        marker._data = a;
        setActiveMarker(marker);
        setShowingInfoWindow(true);
    }

    useEffect(() => {
        api.get(`/solar/assets/find/asset`).then((response) => {
            setAssets(response.data);
        }).catch();
    }, [environment]);

    return (
        <>
            <span>
                <img src={pinIcon} style={{ width: "1.5em" }} />
                {title || environment?.name}
            </span>
            <div className='occurrences_map'>
                <Map
                    initialCenter={_center}
                    center={_center}
                    google={google}
                    zoom={16}
                    disableDefaultUI={true}
                    className="occurrences_map"
                    style={{ borderRadius: "16px" }}
                    fullscreenControl={false}
                    zoomControl={false}
                    streetViewControl={false}
                    mapTypeControl={false}>

                    {assets && assets.map((a) => <Marker onClick={(props, marker) => onClickMarker(props, marker, a)} title={`${a.name}`} position={{ lat: a.lat, lng: a.lng }}>
                    
                    </Marker>)}

                    {activeMarker && activeMarker._data && <InfoWindow
                            marker={activeMarker}
                            visible={showingInfoWindow}
                        >
                            <DetailsAssets data={activeMarker._data}/>
                    </InfoWindow>}
                </Map>
                
            </div>
        </>
    )
}

export default GoogleApiWrapper({
    apiKey: (`${process.env.REACT_APP_GOOGLE_MAPS_TOKEN}`)
})(_Map)