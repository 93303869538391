import { Select } from "antd";
import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import "./style.css";
import Widget from "./widget";

const LightFixture = () => {

  const [lights, setLights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [inputsModels, setInputModels] = useState();

  const fetchInputModels = () => {
    api.get('/lighting/inputmodel').then((response) => {
      if (response.data.length > 0)
        setInputModels(response.data.filter(i => i.component === 'SELECT_OPTIONS'))
      else
        setInputModels([])
    })
  };

  const fetchLightingModels = (model) => {
    setLoading(true);
    setError(false);
    api
      .post("/lighting/lightFixture/count/models", {
        model: model ? model : 'model'
      })
      .then((response) => {
        setLights(response.data);
      })
      .catch((error) => {
        setError(
          error?.response?.data?.message || "unknown error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchLightingModels('model');
    fetchInputModels()
  }, []);

  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 250, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 350, itemsToShow: 3, itemsToScroll: 3 },
    { width: 450, itemsToShow: 4, itemsToScroll: 4 },
    { width: 550, itemsToShow: 5, itemsToScroll: 5 },
    { width: 700, itemsToShow: 6, itemsToScroll: 6 },
  ];



  return (
    <div style={{ marginTop: "1em" }}>
      <span>Luminárias</span>
      <div className="select-carrocel">
        <Select
          mode="single"
          allowClear
          showSearch
          style={{ width: '100%' }}
          placeholder="Selecione um atributo"
          defaultValue='model'
          onChange={(value) => fetchLightingModels(value)}
        >
          {(inputsModels || []).map((i, index) => <Select.Option key={i.name + index} value={i.name}>{i.label}</Select.Option>)}
        </Select>
      </div>


      {error ? (
        <WidgetError message={`${error}`} refresh={() => fetchLightingModels()} />
      ) : (
        <div className="homeLightFixtureGadgetRoot">
          {loading ? (
            <Loading />
          ) : lights.length > 0 ? (
            <Carousel
              showArrows={false}
              breakPoints={breakPoints}
              enableAutoPlay={true}
              autoPlaySpeed={4000}
            >
              {lights?.map((light, index) => (
                <Widget key={light.model + index} count={light?.count} model={light?.model}></Widget>
              ))}
            </Carousel>
          ) : (
            <span className="no-data">Sem dados</span>
          )}
        </div>
      )}
    </div>
  );
};

export default LightFixture;
