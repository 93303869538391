import React from "react";
import Chart from "react-apexcharts";
import data from "./data.json";
import "./style.css";

var pt = require("apexcharts/dist/locales/pt.json");

const ChartWidget = () => {
  return (
    <>
      <span>Custo água e electricidade</span>
      <div className="building_chart">
        <Chart
          options={{
            ...data.options,
            chart: {
              locales: [pt],
              defaultLocale: "pt",
              toolbar: { show: false },
            },
          }}
          series={data.series}
          type="area"
          height="230"
        />
      </div>
    </>
  );
};

export default ChartWidget;
