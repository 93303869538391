import { Col, Row, Select } from "antd";
import React, { useEffect, useState } from "react";

import "../style.css";

import moment from "moment-timezone";
import filterIcon from "../../../assets/icons/filter.svg";
import api from "../../../services/api";
import ChartData from "../chart/index.js";
import Indicators from "./indicators";

const Storange = () => {

    const [racks, setRacks] = useState([])
    const [names,setNames] = useState([])
    const [rackSelected, setRackSelectd] = useState()
    const [hourSeries, sethourSeries] = useState([]);
    const [categoriesHours, setCategoriesHours] = useState([]);
    const [monthSeries, setMonthSeries] = useState([]);
    const [categoriesMonth, setCategoriesMonth] = useState([]);
    const [lastTime, setLastTime] = useState(moment());

    const [chartAcumulated, setChartAcululated] = useState({
        serie: [],
        categorys: []
    });

    const handleRacks = () => {
        api.get("/hydrogen/indicators/names").then((response) => {
            if (response.data.length > 0) {
                setNames(response.data)
                let names = response.data.filter(i => i.includes("cp")).map(i => ({ label: i, value: i }))
                names.unshift({ label: "Todos", value: "all" })
                setRacks(names)
                setRackSelectd('all')
            }
        })
    }

    const fetchHourSeries = async () => {
        let response = await api.get('/hydrogen/indicators/aggregate', {
            params: {
                names: rackSelected==='all'?[names.filter(i => i.includes("cp"))].toString():rackSelected,
                aggregationType: "HOUR",
                timestart: moment().subtract(10, 'year').set({ h: 0, m: 0, s: 0 }).toISOString(),
                timeend: moment().set({ h: 23, m: 59, s: 59 }).toISOString(),
            }
        });
        sethourSeries(generateSeriesHours((response.data)))
    };

    const fetchMonthSeries = async () => {
        let response = await api.get('/hydrogen/indicators/aggregate', {
            params: {
                names:  rackSelected==='all'?[names.filter(i => i.includes("cp"))].toString():rackSelected,
                aggregationType: "MONTH_OF_YEAR",
                timestart: moment().subtract(10, 'year').set({ h: 0, m: 0, s: 0 }).toISOString(),
                timeend: moment().set({ h: 23, m: 59, s: 59 }).toISOString(),
            }
        });
        setMonthSeries(generateSeriesMonther((response.data)))
    };

    const fetchLastProduction = async () => {
        let response = await api.get("/hydrogen/indicators/last", {
            params: {
                names:  rackSelected==='all'?[names.filter(i => i.includes("cp"))].toString():rackSelected,
            }
        });

        let _serie = [
            {
                name: "Hidrogénio produzido",
                data: [...((chartAcumulated.serie[0] || {}).data || []), response.data.map(_ele => _ele.value).reduce((partialSum, a) => partialSum + a, 0)]
            }
        ]
        let _newChartAcumulated = {
            serie: _serie,
            categorys: [...chartAcumulated.categorys, moment().toLocaleString()]
        };
        let _maxLenght = 200;
        if (_newChartAcumulated.serie[0].data.length > _maxLenght) {
            _newChartAcumulated.serie[0].data = _newChartAcumulated.serie[0].data.slice(_newChartAcumulated.serie[0].data.length - _maxLenght - 1, _newChartAcumulated.serie[0].data.length)
        }
        if (_newChartAcumulated.categorys.length > _maxLenght) {
            _newChartAcumulated.categorys = _newChartAcumulated.categorys.slice(_newChartAcumulated.categorys.length - _maxLenght - 1, _newChartAcumulated.categorys.length)
        }
        setChartAcululated(_newChartAcumulated);
    }

    function resetChart(){
        let _serie = [
            {
                name: "Hidrogénio produzido",
                data: []
            }
        ]
        let _newChartAcumulated = {
            serie: _serie,
            categorys: []
        };
        setChartAcululated(_newChartAcumulated);
    }

    const generateSeriesHours = (_data = []) => {
        let series = []
        let dates = Array.from(Array(24).keys())
        _data.forEach((element) => {
            series.push(element);
        })
        setCategoriesHours(dates)
        return [
            {
                name: "Caudal",
                data: dates.map((_date) => parseInt(series.find((i) => parseInt(i.aggregateValue) === _date)?.avg || 0)),
            }
        ]

    };

    const generateSeriesMonther = (_data = []) => {
        let series = []
        let dates = []
        _data.forEach((element) => {
            if (!dates.includes(element.aggregateValue)) dates.push(element.aggregateValue)
            series.push(element);
        })
        setCategoriesMonth(dates)
        return [
            {
                name: rackSelected,
                data: dates.map((_date) => parseInt(series.find((i) => i.aggregateValue === _date)?.avg || 0)),
            }
        ]

    };

    useEffect(() => {
        handleRacks()
    }, [])

    useEffect(() => {
        resetChart()
        fetchHourSeries()
        fetchMonthSeries()
    }, [rackSelected])

    useEffect(async () => {
        try {
            await fetchLastProduction()
        } finally {
            startTimeout();
        }
    }, [lastTime])

    const startTimeout = () => setTimeout(() => setLastTime(moment()), 4000);

    return (
        <Row gutter={24}>
            <Col xs={24} lg={12}>
                <div className="filters">
                    <img src={filterIcon} /> Filtros
                </div>

                <div className="filters-energy">
                    <Row>
                        <Col xs={12}>
                            <div>
                                <span>
                                    Caudal em análise:
                                </span>

                                <Select className="select_mobility"
                                    showSearch
                                    defaultActiveFirstOption
                                    value={rackSelected}
                                    onChange={(i) => { setRackSelectd(i) }}
                                    bordered={false}
                                    placeholder="Selecione um caudal"
                                    options={(racks || [])}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Indicators names={names} rackSelected={rackSelected} />
                </Row>
            </Col>
            <Col xs={24} lg={12}>
                <ChartData
                    title="Produção hoje"
                    categories={chartAcumulated.categorys}
                    xaxis={{
                        type: 'datetime',
                        labels: {
                            formatter: function (val, index) {
                                return moment(val).format("HH:mm:ss");
                            }
                        }
                    }}
                    series={chartAcumulated.serie}
                    symbol="Nm3"
                    type="line" >
                </ChartData>
               

            </Col>
            <Col xs={24}>
            <ChartData title="Média de produção por hora" categories={categoriesHours} xSymbol={'h'} series={hourSeries} symbol="Nm3" type="bar"></ChartData>
            </Col>
        </Row>
    );
};

export default Storange;
