import React from "react"
import "../../style.css";
import { Link } from "react-router-dom";

const Dadosurbanos = () => {

    const partner = <Link to="/ctec/contacts?partner=IST">IST</Link>

    return (<div><iframe className="frame-content" src="https://dadosurbanos.perfectorange.pt/energy" title="Dados urbanos"></iframe>
    <div><p>Built BY {partner}</p></div></div>)
}

export default Dadosurbanos