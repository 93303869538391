import React from "react";
import "../style.css";
import icon from "../../../../assets/icons/surgical-mask.svg";
import { Col, Row } from "antd";

const ConfirmedCases = ({ confirmed }) => {
  return (
    <div className="card_covid">
      <Row>
        <Col xs={24}>
          <img src={icon} alt="" style={{ width: "40px" }} />
        </Col>
        <Col xs={24} style={{ paddingTop: "1em" }}>
          <span>{new Intl.NumberFormat("portugal").format(confirmed)}</span>
        </Col>
        <Col xs={24}>
          <span>casos confirmados</span>
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmedCases;
