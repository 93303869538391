import React from "react";

function Icon({ station, temperatura, time, weatherTypePT}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      viewBox="0 0 349 136"
    >
      <defs>
        <filter
          id="gzx9k3l0na"
          width="222.5%"
          height="322.7%"
          x="-61.2%"
          y="-106.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="4n2gdw89rc"
          width="140%"
          height="304.2%"
          x="-20%"
          y="-97.9%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="pg766zdkoe"
          width="162.8%"
          height="202.1%"
          x="-31.4%"
          y="-49%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="ino6cwjorg"
          width="140.6%"
          height="322.7%"
          x="-20.3%"
          y="-106.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="8"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0 0.31372549 0 0 0 0.5 0"
          ></feColorMatrix>
        </filter>
        <text
          id="q9bzqyuv6b"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="18"
          fontWeight="bold"
        >
          <tspan x="286" y="104">
            {time}
          </tspan>
        </text>
        <text
          id="cmmca7qlkd"
          fill="#FFF"
          fontFamily="JosefinSans-Light, Josefin Sans"
          fontSize="20"
          fontWeight="300"
        >
          <tspan x="11" y="70">
            {weatherTypePT}
          </tspan>
        </text>
        <text
          id="j0w4tbcfvf"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="40"
          fontWeight="bold"
        >
          <tspan x="11" y="43">
            {temperatura}
          </tspan>
        </text>
        <text
          id="p9rhyr6eth"
          fill="#FFF"
          fontFamily="JosefinSans-Bold, Josefin Sans"
          fontSize="18"
          fontWeight="bold"
        >
          <tspan x="9.882" y="104">
            {station}
          </tspan>
        </text>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#8F94FB"
          d="M27.511 10H321.49c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 20.793 342 23.38 342 30.511v73.846c0 7.132-.743 9.718-2.137 12.325a14.538 14.538 0 01-6.048 6.049c-2.608 1.394-5.194 2.137-12.326 2.137H27.51c-7.132 0-9.718-.743-12.326-2.137a14.538 14.538 0 01-6.048-6.049C7.743 114.075 7 111.49 7 104.357V30.51c0-7.132.743-9.718 2.137-12.326a14.538 14.538 0 016.048-6.048C17.793 10.743 20.38 10 27.511 10z"
        ></path>
        <path
          fill="#6555C4"
          d="M321.489 10c7.132 0 9.718.743 12.326 2.137a14.538 14.538 0 016.048 6.048C341.257 20.793 342 23.38 342 30.511V43H144.997C131.192 43 120 31.809 120 17.993V10h201.489z"
        ></path>
        <path
          fill="#4E54C8"
          d="M324.577 90.022c.937 0 1.844.052 2.72.153H342v17.27c0 3.562-.749 6.685-2.142 9.266a14.674 14.674 0 01-6.014 6.014c-2.582 1.394-5.705 2.143-9.267 2.143H24.423c-3.562 0-6.685-.749-9.267-2.143a14.674 14.674 0 01-6.014-6.014C7.75 114.131 7 111.007 7 107.445l.004-.424H7V90.177h14.704a23.524 23.524 0 012.719-.154h300.154z"
        ></path>
        <g fill="#FFF">
          <path d="M199.558 75.7l-23.04-.219-.712-.718c-1.424-1.463-1.068-4.17.712-5.08 2.191-1.133 4.656.441 4.656 2.982 0 1.905 2.794 2.844 4.136 1.408.548-.58.657-.966.602-2.181-.054-1.933-.93-3.7-2.547-5.22-1.752-1.656-2.985-2.153-5.368-2.18-2.3 0-3.998.69-5.669 2.374-4.163 4.197-2.465 11.239 3.177 13.2.37.134.751.232 1.414.305a2 2 0 001.102.329h147.974A2.002 2.002 0 00328 78.695v-.99a2.003 2.003 0 00-2.005-2.005H199.558z"></path>
          <path d="M209.34 68.464h99.62a2 2 0 011.998 2.005v.99a2.004 2.004 0 01-1.998 2.005H194.957c-.407 0-.785-.121-1.1-.33h-.276l-1.67-.828c-3.04-1.547-4.739-4.087-4.93-7.456-.165-2.844.575-4.75 2.547-6.765 4.217-4.225 11.092-3.203 13.94 2.07 1.068 1.989 1.096 3.425.082 4.309-.986.856-2.382.883-3.094.082-.274-.331-.767-1.104-1.069-1.712-1.451-3.037-5.696-3.258-7.148-.414-.986 1.933-.493 3.921 1.37 5.302.712.552 1.177.58 8.38.663l7.352.08zm-30.007-6.436c-1.167-1.61-1.254-4.769-.192-7.398 1.288-3.188 3.92-5.346 7.059-5.802 3.174-.475 5.103 1.216 4.154 3.565-.565 1.399-1.683 1.988-3.212 1.696-1.312-.27-2.635.334-3.397 1.588-.634 1.078-.757 1.874-.431 3.177.376 1.388.208 2.296-.554 3.127-.596.703-1.985 1.117-2.644.851-.16-.097-.532-.442-.783-.804z"></path>
        </g>
        <g fill="#FFF" transform="translate(7 10)">
          <use filter="url(#gzx9k3l0na)" xlinkHref="#q9bzqyuv6b"></use>
          <use xlinkHref="#q9bzqyuv6b"></use>
        </g>
        <g fill="#FFF" transform="translate(7 10)">
          <use filter="url(#4n2gdw89rc)" xlinkHref="#cmmca7qlkd"></use>
          <use xlinkHref="#cmmca7qlkd"></use>
        </g>
        <g fill="#FFF" transform="translate(7 10)">
          <use filter="url(#pg766zdkoe)" xlinkHref="#j0w4tbcfvf"></use>
          <use xlinkHref="#j0w4tbcfvf"></use>
        </g>
        <g fill="#FFF" transform="translate(7 10)">
          <use filter="url(#ino6cwjorg)" xlinkHref="#p9rhyr6eth"></use>
          <use xlinkHref="#p9rhyr6eth"></use>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
