import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Loading from "../../../../components/loading";
import WidgetError from "../../../../components/error";
import api from "../../../../services/api";
import data from "./data.json";
import "./style.css";

const TotalConsumed = ({ dateStart, dateEnd }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const fetchGroupConsumed = () => {
    setLoading(true);
    setError(undefined);
    api
      .post("/hydrogen/supply/group/consumed", {
        dateStart: dateStart ? moment(dateStart).utc() : undefined,
        dateEnd: dateEnd ? moment(dateEnd).utc() : undefined,
      })
      .then((response) => {
        setSeries(generateSeries(response.data));
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateSeries = (_data = []) => {
    let _categories = [];
    _data.forEach((element) => {
      _categories.push(`${element.date} ${element.date > 0 && element.date < 24 ? 'h' : ''}`);
    });
    setCategories(_categories);
    return [
      {
        name: "Total de hidrogénio consumido",
        data: _data?.map((item) => {
          return item.value.toFixed(3);
        }),
      },
    ];
  };

  useEffect(fetchGroupConsumed, [dateStart, dateEnd]);

  return (
    <div style={{ marginTop: "1em" }}>
      <span>Gráfico do total consumido</span>
      {loading ? (
        <Loading />
      ) : error ? (
        <WidgetError
          message={`${error}`}
          refresh={() => fetchGroupConsumed()}
        />
      ) : (
        <div className="hydrogenTotalConsumed">
          <Chart
            options={{
              ...data.options,
              yaxis: {
                show: false,
                labels: {
                  formatter: function (value) {
                    return (
                      (value)?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 4,
                      }) + " Kg"
                    );
                  },
                },
              },
              xaxis: {
                type: "string",
                categories: categories,
              },
            }}
            series={series}
            type="bar"
            height="230"
          />
        </div>
      )}
    </div>
  );
};

export default TotalConsumed;
