import { Col } from "antd";
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import React, { useEffect, useState } from "react";
import WidgetError from "../../../components/error";
import api from "../../../services/api";
import "./style.css";
import DetailsStreetLight from "../details";

const _Map = ({ google, environment, dateStart, dateEnd }) => {
  const [center, setCenter] = useState();
  const [error, setError] = useState();
  const [params, setParams] = useState();
  const [points, setPoints] = useState([]);
  const [activeMarker, setActiveMarker] = useState();
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);

  const showDetails = async (props, marker) => {
    let response = await api.get(`/lighting/lightFixture/${marker.name}`)
    marker._data = response.data
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };

  const refreshPoints = (_params) => {
    let data = {
      ...params,
      ..._params,
    };
    setParams(data);
    api
      .post(`/lighting/lightFixture/gps/date`, data)
      .then((response) => {
        setPoints(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      });
  };

  const handlerBounds = (mapProps, map) => {
    if (!map || !map.getBounds()) return;
    let bounds = map.getBounds();
    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();
    let cnt = map.getCenter();
    if (map.zoom >= 14) {
      refreshPoints({
        ne: {
          lat: ne.lat(),
          lng: ne.lng(),
        },
        sw: {
          lat: sw.lat(),
          lng: sw.lng(),
        },
        center: {
          lat: cnt.lat(),
          lng: cnt.lng(),
        },
        start: dateStart,
        end: dateEnd,
      });
    } else {
      setPoints([]);
    }
  };

  const setMyLocationInCenter = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      let { latitude, longitude } = position.coords;
      setCenter({ lat: latitude, lng: longitude });
    });
  };

  const handleCenterLocale = () => {
    api
      .get(`/lighting/lightFixture/one`)
      .then((response) => {
        let { lat, lng } = response.data;
        if (!lat || !lng) {
          setMyLocationInCenter();
          setError(false);
        } else {
          setError(false);
          setCenter({ lat: lat, lng: lng });
        }
      })
      .catch(setMyLocationInCenter);
  };

  useEffect(() => {
    handleCenterLocale();
  }, [dateStart, dateEnd]);

  return (
    <>
      <Col xs={24} style={{ marginTop: "1em" }}>
        Localização das luminárias
      </Col>
      {error ? (
        <WidgetError message={error} refresh={() => handleCenterLocale()} />
      ) : (
        <div className="street_lighting">
          <Map
            initialCenter={center}
            center={center}
            google={google}
            zoom={17}
            disableDefaultUI={true}
            className="street_lighting"
            style={{ borderRadius: "16px" }}
            fullscreenControl={false}
            zoomControl={true}
            streetViewControl={false}
            mapTypeControl={false}
            onIdle={handlerBounds}
          >
            {points?.map((item) => (
              <Marker
                id
                position={{
                  lat: item?.lat,
                  lng: item?.lng,
                }}
                name={item.id}
                title={`Luminária: ${item.id}`}
                onClick={(props, marker) => showDetails(props, marker)}
              />
            ))}
            {activeMarker && activeMarker._data && <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
              <DetailsStreetLight data={activeMarker._data} />
            </InfoWindow>}
          </Map>
          )
        </div>
      )}
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_GOOGLE_MAPS_TOKEN}`,
})(_Map);
