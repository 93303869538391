import { Col, Row } from "antd";
import React from "react";
import Loading from "../../../../components/loading";
import "./style.css";
import WidgetError from "../../../../components/error";

const Widget = ({ error, loading, icon, title, value }) => {
  return (
    <Row style={{ marginBottom: "1.9em" }}>
      {loading ? (
        <Loading />
      ) : error ? (
        <WidgetError    style={{ padding:"5px"}}/>
      ) : (
        <>
          <Col xs={24} md={3} lg={2}>
            <img
              src={`${require(`../../../../assets/icons/lightFixture/generalInfo/${icon}`)}`}
              alt=""
            />
          </Col>
          <Col xs={24} md={15} lg={14}>
            <span className="streetLightingDetailWidgetTitle">{title}</span>
          </Col>
          <Col xs={24} md={6} lg={8} style={{ textAlign: "right" }}>
            <span className="streetLightingDetailWidgetValue">{value}</span>
          </Col>
        </>
      )}
    </Row>
  );
};

export default Widget;
