import { useKeycloak } from "@react-keycloak/web";
import React, { createContext, useEffect, useState } from "react";
import Loading from "../components/loading";
import { setToken } from "../services/api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const { initialized, keycloak } = useKeycloak();
  const [user, setUser] = useState(null);
  const [environment, setEnvironment] = useState();
  const [loading, setLoading] = useState(false);
  const [authority, setAuthority] = useState(false);
  const [invalidLoginMsg, setInvalidLoginMsg] = useState(null);

  const hasAccess = (...args) => {
    let { authorities } = keycloak.tokenParsed;
    if (authorities) {
      if (authorities.includes("admin"))
        return true;
      for (var i = 0; i < args.length; i++) {
        let arg = (args[i]||'').split(',');
        for (var j = 0; j < arg.length; j++) {
          let role = arg[j];
          if(authorities.includes(role))
            return true;
        }        
      }
      return 
    }
    return false;
  }

  const handleUpdate = () => {
    if (initialized && keycloak.authenticated) {
      keycloak.updateToken(300).then(function (refreshed) {
        if (refreshed) {
          handleToken()
        }
      }).catch(function () {
        keycloak.logout()
      });
    }
  }


  const logout = () => {
    sessionStorage.removeItem(`credentials-${process.env.PUBLIC_URL}`);
    sessionStorage.removeItem(`credentials-refresh-${process.env.PUBLIC_URL}`);
    document.getElementById("post-logout").submit();
  };

  const handleToken = () => {
    let environment = keycloak.tokenParsed.environments?.find((e) => e.id === process.env.REACT_APP_ENVIRONMENT)
    if (environment) {
      let _user = { name: keycloak.tokenParsed.name, id: keycloak.tokenParsed.sub, authorities: keycloak.tokenParsed.authorities, environment: environment.id }
      setUser(_user)
      setAuthority(keycloak.tokenParsed.authorities?.includes("SONAE"))
      setEnvironment(environment)
      setToken(keycloak)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleUpdate()
    }, 1000*60);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    if (initialized) {
      if (keycloak.authenticated) {
        handleToken();
        setLoading(false)
      }
    } else {
      setLoading(true)
    }
  }, [initialized])

  if (!initialized) {
    return <Loading />
  }



  return (
    <AuthContext.Provider
      value={{
        authenticated: !!keycloak.authenticated,
        user,
        loading,
        environment,
        logout,
        invalidLoginMsg,
        authority,
        hasAccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
