import React from "react";
import Chart from "react-apexcharts";
import data from "./data.json";
import "./style.css";

var pt = require("apexcharts/dist/locales/pt.json");

const ChartData = ({ title, series, categories, symbol }) => {
    return (
        <>
            <span>
                {title}
            </span>
            <div className="financial_chart">
                <Chart
                    options={{
                        ...data.options,
                        yaxis: {
                            labels: {
                                formatter: function (val, index) {
                                    return `${val.toLocaleString()} ${symbol ? symbol : ''}`;
                                }
                            }
                        },
                        xaxis: {
                            ...data.options.xaxis,
                            categories: categories
                        },
                        chart: {
                            locales: [pt],
                            defaultLocale: "pt",
                            toolbar: { show: false },
                        },
                    }}
                    series={series}
                    type="line"
                    height={250}
                    width="100%" />

            </div>
        </>
    );
}

export default ChartData;