import { useKeycloak } from "@react-keycloak/web";
import React, { useContext } from "react";
import {
  BrowserRouter, Route,
  Routes
} from "react-router-dom";
import Loading from "./components/loading";
import { AuthContext, AuthProvider } from "./context/auth";
import { MenuProvider } from "./context/menu";
import Templeate from "./templeate";

const AppRoutes = () => {

  const { keycloak } = useKeycloak()

  const Private = ({ children }) => {
    const { hasAccess, loading, authenticated } = useContext(AuthContext);
    if (loading) return <Loading />;
    if (!authenticated) {
      return keycloak.login();
    }else if(!hasAccess('horizontal-static')){
      return <div style={{textAlign: "center", marginTop: "45vh"}}><h2>Tu não tem permissão para aceder esta página. <a onClick={()=>window.location.reload(true)}>Tentar novamente</a></h2></div>
    }
    return children;
  };

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <AuthProvider>
        <Routes>
          <Route
            path="*"
            element={
              <Private>
                <MenuProvider>
                  <Templeate />
                </MenuProvider>
              </Private>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
