import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import WidgetError from "../../../components/error";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import ConfirmedCases from "./confirmedCases";
import Deaths from "./deaths";
import Vaccination from "./vaccination";

const Covid = () => {

  const [covidData, setCovidData] = useState({
    confirmed: 0,
    deaths: 0,
    vaccinated: 0,
  });

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  const searchCovidData = () => {
    setLoading(true);
    setError(false);
    api
      .get("/dashboard/covid/search", {})
      .then((response) => {
        setCovidData(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "unknown error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    searchCovidData();
  }, []);

  return (
    <Row gutter={16} className="nopadding" style={{ width: "100%", marginLeft: "0", marginTop: "1em" }}>

      <Col xs={24}>
        <div className="covid_status" />
        <span className="covid_status_span">COVID-19 Portugal</span>
      </Col>

      {loading ? (
        <Loading />
      ) : error ? (
        <WidgetError message={`${error}`} refresh={() => searchCovidData()} />
      ) : (
        <>
          <Col xs={24} md={8}>
            <ConfirmedCases confirmed={covidData.confirmed}></ConfirmedCases>
          </Col>

          <Col xs={24} md={8}>
            <Deaths
              deaths={new Intl.NumberFormat("portugal").format(
                covidData.deaths
              )}
            ></Deaths>
          </Col>

          <Col xs={24} md={8}>
            <Vaccination vaccinated={covidData.vaccinated}></Vaccination>
          </Col>
        </>
      )}
    </Row>
  );
};

export default Covid;
