import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Menu, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import data from "./data";
import { Footer } from "antd/lib/layout/layout";

const _Menu = ({ collapsed, setCollapsed }) => {

    const {hasAccess, authority } = useContext(AuthContext);
    const {rerender, setrerender} = useState(true);
    let cteck = hasAccess("ctec");

    const keysSelected = useLocation().pathname;
    const openedKeys = cteck? ["/"] : [];

    if(keysSelected.includes("/ctec/building"))
        openedKeys.push("/ctec/building");
    else if(keysSelected.includes("/ctec/urbanhumanconfort"))
        openedKeys.push("/ctec/urbanhumanconfort")
    else if(keysSelected.includes("/ctec/mobility"))
        openedKeys.push("/ctec/mobility")
    else if(keysSelected.includes("/ctec/agriculture"))
        openedKeys.push("/ctec/agriculture")


    function generateItems(_menu) {
        return _menu.submenus ?
            <Menu.SubMenu
                mode="inline"
                key={_menu.id}
                id={_menu.id}
                icon={_menu.icon ? <img src={require(`../../assets/icons/menu/${_menu.icon}`)} alt="" /> : <></>}
                title={_menu.id === "/" || _menu.id === "/ctec/building" || _menu.id === "/ctec/urbanhumanconfort" || _menu.id === "/ctec/mobility" || _menu.id === "/ctec/agriculture" ? <Tooltip placement="right" title={_menu.title} className="tooltip"><Link style={{display:"flex", all:"inherit", }}to={_menu.url}>{_menu.title}</Link></Tooltip>:_menu.title}>
                {_menu.submenus.filter((_sub) => hasAccess(_sub.resources)).map((_submenu) => generateItems(_submenu))}
            </Menu.SubMenu> :
            <Menu.Item
                icon={_menu.icon ? <img src={require(`../../assets/icons/menu/${_menu.icon}`)} alt="" /> : <></>}
                key={_menu.id}
                id={_menu.id}
                title={_menu.title}>
                <Tooltip placement="right" title={_menu.title} className="tooltip"><Link to={_menu.url}>
                    {_menu.title}
                </Link></Tooltip>
            </Menu.Item>;
    }

    useEffect(()=>{
        if (window.screen.width < 600) {
            setCollapsed(true)
          }
    },[])

    useEffect(()=>{
        document.getElementById("/")?.children[0].children[2]?.remove()
    },[rerender])

    return (<>
        <Link to="/" >
            <img
                title="Home"
                style={{ maxHeight: "90%" }}
                src={`${process.env.REACT_APP_API_URL}/storage/logo/${process.env.REACT_APP_ENVIRONMENT}`}
                alt="Imagem logo"
                className="logo">
            </img>
        </Link>

        {window.screen.width > 600 &&
            <a
                className="navOnClose"
                onClick={() => setCollapsed(!collapsed)}>
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </a>
        }
        {!collapsed && 
        <Menu
            style={{ marginTop: "10px" }}
            mode="inline"
            inlineCollapsed={collapsed}
            selectedKeys={cteck?keysSelected:undefined}
            openKeys={cteck?openedKeys:undefined}
            defaultSelectedKeys={cteck?['/']:undefined}
            defaultOpenKeys={cteck?['/']:undefined}>
            {data.filter((_menu) => (hasAccess(_menu.resources) && ((authority && _menu.title !== "Dashboard") || !authority)) || _menu.title === "Meteorology").map((_menu) => generateItems(_menu))}
            {rerender && setrerender(false)}
        </Menu>}
        {collapsed &&
        <Menu
            style={{ marginTop: "10px" }}
            mode="inline"
            inlineCollapsed={collapsed}
            defaultSelectedKeys={cteck?['/']:undefined}
            defaultOpenKeys={cteck?['/']:undefined}>
            {data.filter((_menu) => (hasAccess(_menu.resources) && ((authority && _menu.title !== "Dashboard") || !authority)) || _menu.title === "Meteorology").map((_menu) => generateItems(_menu))}
            {rerender && setrerender(false)}
        </Menu>
        }
    </>)
}

export default _Menu;